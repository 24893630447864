import React from "react";
import { withRouter, Link } from 'react-router-dom';
import Dropzone from "react-dropzone";
import DropDown from "../../components/DropDown";
import Modal from "../../components/CustomModal";
import Dragplaceholder from '../../assets/Dragfile.png';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import MyConstant from "../../config/MyConstant";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { getGrpcClient, getPageText, getValidLabels, getUuidv4, getValidTooltip, capitalize, checkMobileView, searchDropdownOptionConvert, generateShades } from "../../config/Common";
import FileView from "../../components/FileView";
import CustomEditor from "../../components/CustomEditor";
import { setProjectDiagramData, setMultipleProjectDiagramData } from '../../redux/actions';
import { connect } from 'react-redux';
import GlobalMsgCons from "../../config/GlobalMsgCons";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from "../../config/GlobalEventCons";
import ReactTooltip from 'react-tooltip';
import CaptureAITaskBlock from "./component/CaptureAITaskBlock";
import CaptureAITaskBlockView from "./component/CaptureAITaskBlockView";
import SearchDropdown from "../../components/SearchDropdown";
import GlobalSearchDataTypes from "../../config/GlobalSearchDataTypes";
import DropdownButton from "../../components/DropdownButton";
import FlowDiagram from "./component/FlowDiagram";
import MyConfig from "../../config/MyConfig";

const diagramOptions = [
    { label: 'Agent System - Swimlanes', value: "swimlane" },
    { label: 'Agent System - Flows', value: "flowDiagram" },
]
class ProjectDiagram extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: null,
            projectName: "",
            clientName: "",
            aiAgentFlow: {},
            deployToUserModal: false,
            deployLoader: false,
            selectedUser: [],
            apiListModal: false
        }
    }


    componentDidMount() {
        this.getPageText()
        ReactTooltip.rebuild();

        let { clientId, projectId, designType } = this.props.match.params;
        if (designType === undefined) {
            let pinTabInfo = this.props.pinTabInfo

            clientId = pinTabInfo.clientId;
            projectId = pinTabInfo.projectId;
            designType = pinTabInfo.designType;
        }

        this.props.setMultipleProjectDiagramData({
            selectedClient: clientId,
            selectedProject: projectId,
            diagramData: []
        })
        this.getDiagramData(designType, projectId)
    }

    componentDidUpdate(prevProps) {
        let { editSwimlaneId, swimlaneName, swimlaneColor, editBlockId, selectedLane, blockName, blockDetails, blockRules, blockType, blockParentFlow, blockApprovedFlow, blockRejectedFlow, documentFiles } = this.props;

        if (editBlockId && (selectedLane !== prevProps.selectedLane || blockName !== prevProps.blockName || blockDetails !== prevProps.blockDetails || blockType !== prevProps.blockType || blockParentFlow !== prevProps.blockParentFlow || blockApprovedFlow !== prevProps.blockApprovedFlow || blockRejectedFlow !== prevProps.blockRejectedFlow || documentFiles !== prevProps.documentFiles)) {
            this.textCompareEdit()
        }

        if (editSwimlaneId && (swimlaneName !== prevProps.swimlaneName || swimlaneColor !== prevProps.swimlaneColor)) {
            this.textCompareSwimlaneEdit();
        }
        if (prevProps.languagePreference !== this.props.languagePreference) {
            this.getPageText()
        }
    }

    setAiAgentFlow(aiAgentFlow) {
        this.setState({
            aiAgentFlow
        })
    }

    getElement(id) {
        const el = document.getElementById(id);
        return el ? el : "";
    }

    getOffset(el) {
        const left = el.offsetLeft;
        const top = el.offsetTop;
        const width = el.offsetWidth;
        const height = el.offsetHeight;
        const right = left + width;
        const bottom = top + height;
        const mid = top + height / 2;
        const center = left + width / 2;
        return {
            left,
            right,
            top,
            bottom,
            mid,
            center,
            width,
            height
        };
    }

    drawL(props) {
        const { startingElement, endingElement, color, thickness, shape = "normal", setDegree } = props;
        const {
            id: startingElementId,
            x: horizontal1,
            y: vertical1,
        } = startingElement;
        const { id: endingElementId, x: horizontal2, y: vertical2 } = endingElement;

        const code = () => {
            const firstElement = this.getElement(startingElementId);
            const secondElement = this.getElement(endingElementId);
            if (firstElement && secondElement) {
                const off1 = this.getOffset(firstElement);
                const off2 = this.getOffset(secondElement);

                let x1, x2, y1, y2, a1, a2, b1, b2, p1, p2;

                x1 = off1[horizontal1];
                y1 = off1[vertical1];
                x2 = off2[horizontal2];
                y2 = off2[vertical2];

                switch (shape) {
                    case "normal":
                        p1 = x1;
                        p2 = y2;
                        break
                    case "upsidedownL":
                        p1 = x2;
                        p2 = y1;
                        break
                    default: throw new Error("shape is not correct")
                }

                // line settings for the first div
                const length = Math.sqrt((p1 - x1) * (p1 - x1) + (p2 - y1) * (p2 - y1));
                const cx = (x1 + p1) / 2 - length / 2;
                const cy = (y1 + p2) / 2 - thickness / 2;
                const angle = Math.atan2(y1 - p2, x1 - p1) * (180 / Math.PI);

                //line settings for the second div
                const length2 = Math.sqrt((x2 - p1) * (x2 - p1) + (y2 - p2) * (y2 - p2));
                const cx2 = (p1 + x2) / 2 - length2 / 2;
                const cy2 = (p2 + y2) / 2 - thickness / 2;
                const angle2 = Math.atan2(p2 - y2, p1 - x2) * (180 / Math.PI);

                //creating a line for each element
                const line1 = document.createElement("div")
                const line2 = document.createElement("div")

                line1.className = "flowDiagramLine"
                line2.className = "flowDiagramLine flowDiagramEnd " + setDegree;
                line1.style = "padding:0px; margin:0px; height:" +
                    thickness +
                    "px; background-color:" +
                    color +
                    "; line-height:1px; position:absolute; left:" +
                    cx +
                    "px; top:" +
                    cy +
                    "px; width:" +
                    length +
                    "px; -moz-transform:rotate(" +
                    angle +
                    "deg); -webkit-transform:rotate(" +
                    angle +
                    "deg); -o-transform:rotate(" +
                    angle +
                    "deg); -ms-transform:rotate(" +
                    angle +
                    "deg); transform:rotate(" +
                    angle +
                    "deg);";
                line2.style = "padding:0px; margin:0px; height:" +
                    thickness +
                    "px; background-color:" +
                    color +
                    "; line-height:1px; position:absolute; left:" +
                    cx2 +
                    "px; top:" +
                    cy2 +
                    "px; width:" +
                    length2 +
                    "px; -moz-transform:rotate(" +
                    angle2 +
                    "deg); -webkit-transform:rotate(" +
                    angle2 +
                    "deg); -o-transform:rotate(" +
                    angle2 +
                    "deg); -ms-transform:rotate(" +
                    angle2 +
                    "deg); transform:rotate(" +
                    angle2 +
                    "deg);";
                document.getElementById("graph").appendChild(line1)
                document.getElementById("graph").appendChild(line2)
            }
        };

        code();
    };


    swimlaneModalSetup() {
        this.props.setMultipleProjectDiagramData({
            swimlaneModal: true,
            swimlaneName: "",
            editSwimlaneId: "",
            blockModal: false,
            viewBlockModal: false
        })

        this.setState({
            apiListModal: false,
            deployToUserModal: false
        })
    }

    blockModalSetup() {
        let { diagramData } = this.props;
        let laneOptions = [],
            blockParentFlowOptions = [];

        diagramData.map((lane) => {
            laneOptions.push({ value: lane.id, label: lane.name });
            lane.blocks.map((block) => {
                if (Object.keys(block).length > 0) {
                    let { title, multiTaskDetails } = block;
                    let optionTitle = title;
                    if (multiTaskDetails && multiTaskDetails.length > 0) {
                        for (let j = 0; j < multiTaskDetails.length; j++) {
                            let { title } = multiTaskDetails[j];
                            title = title ? title : "-";
                            optionTitle += title + ", ";
                        }
                        optionTitle = optionTitle.replace(/,\s*$/, "");
                    }
                    blockParentFlowOptions.push({ value: block.id, label: lane.name + " - " + optionTitle })
                }
            })
        })

        this.props.setMultipleProjectDiagramData({
            blockModal: true,
            swimlaneModal: false,
            viewBlockModal: false,
            blockName: "",
            blockType: "taskBlock",
            editBlockId: "",
            laneOptions,
            blockParentFlowOptions
        })
        this.setState({
            apiListModal: false,
            deployToUserModal: false
        })
    }

    getDiagramData(designType, selectedProject) {
        let { companyId } = this.props;
        let postJson = { reqId: getUuidv4(), companyId, projectId: selectedProject, designType }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.teqreqSetDomain);
        request.setEvent(GlobalEventCons.teqReqDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;

                let diagramData = []
                if (response && response.teqReqData) {
                    diagramData = JSON.parse(response.teqReqData.jsonData);
                    console.log("diagramData", diagramData)
                }
                this.setState({
                    clientName: response.clientName,
                    projectName: response.projectName,
                })
                this.props.setMultipleProjectDiagramData({
                    diagramData
                })
                this.drawLine(diagramData)
            }
        });
    }

    drawLine(diagramData) {
        setTimeout(() => {

            document.querySelectorAll('.flowDiagramLine').forEach(e => e.remove());

            let blocksId = {};
            let laneId = {};
            diagramData.map((lane, laneIndex) => {
                lane.blocks.map((block, blockIndex) => {
                    if (Object.keys(block).length > 0) {
                        blocksId[block.id] = blockIndex
                        laneId[block.id] = laneIndex
                    }
                })
            })
            diagramData.map((lane) => {
                lane.blocks.map((block) => {
                    if (Object.keys(block).length > 0) {
                        if (block.parentFlow !== "") {
                            this.drawLineRender(laneId, blocksId, block.parentFlow, block.id)
                        }

                        if (block.type === "conditionalBlock" && (block.approvedFlow !== "" || block.rejectedFlow !== "")) {
                            if (block.approvedFlow !== "") {
                                this.drawLineRender(laneId, blocksId, block.id, block.approvedFlow, "approved")
                            }
                            if (block.rejectedFlow !== "") {
                                this.drawLineRender(laneId, blocksId, block.id, block.rejectedFlow, "rejected")
                            }
                        }
                    }
                })
            })
        }, 50)
    }


    drawLineRender(laneId, blocksId, startId, endId, condition) {
        let startX = "center";
        let startY = "bottom";

        let endX = "left";
        let endY = "mid";

        let setDegree = "";
        if (laneId[startId] === laneId[endId]) {
            startX = "right";
            startY = "mid";

            if (blocksId[startId] > blocksId[endId]) {
                endX = "right";
                endY = "mid";

                startX = "left";
                startY = "mid";
            }
        } else if (blocksId[startId] === blocksId[endId]) {
            endX = "center";
            endY = "top";
            setDegree = "rightMid";

            if (laneId[startId] > laneId[endId]) {
                startX = "center";
                startY = "top";

                endX = "center";
                endY = "bottom";

                setDegree = "bottomMid";
            } else if (laneId[startId] < laneId[endId]) {
                setDegree = "bottomMid";
            }

        } else if (laneId[startId] > laneId[endId] && blocksId[startId] > blocksId[endId]) {
            startX = "center";
            startY = "top";

            endX = "right";
            endY = "mid";
        } else if (blocksId[startId] > blocksId[endId]) {
            endX = "right";
            endY = "mid";
        } else if (laneId[startId] > laneId[endId]) {
            startX = "center";
            startY = "top";
        }

        if (condition !== undefined) {
            // startId += "-"+condition;
        }

        this.drawL({ startingElement: { id: startId, x: startX, y: startY }, endingElement: { id: endId, x: endX, y: endY }, color: "#00bcd4", thickness: "2", setDegree: setDegree })
    }

    save(isNewVersion, displayAlert) {
        displayAlert = displayAlert !== undefined ? displayAlert : true
        let { userId, companyId, selectedProject, diagramData } = this.props;
        let { pageText } = this.state;
        let { designType } = this.props.match.params;
        if (designType === undefined) {
            let pinTabInfo = this.props.pinTabInfo
            designType = pinTabInfo.designType;
        }
        let postJson = { reqId: getUuidv4(), userId, companyId, projectId: selectedProject, json: JSON.stringify(diagramData), updatedBy: userId, isNewVersion, designType }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.teqreqSetDomain);
        request.setEvent(GlobalEventCons.setUpTeqReqSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;
                if (response === GlobalMsgCons.success && displayAlert) {
                    toast.success(getValidLabels(pageText, 'updatedSuccess_msg'), {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    saveSwimlane() {
        let { diagramData, swimlaneName, swimlaneColor, editSwimlaneId, pageText } = this.props;

        let errors = {};
        if (swimlaneName === "") {
            errors["swimlaneName"] = getValidLabels(pageText, 'pleaseEnterSwimlaneErr_msg')
        }

        this.props.setMultipleProjectDiagramData({
            errors
        })

        if (Object.keys(errors).length === 0) {
            let lane = {
                id: editSwimlaneId ? editSwimlaneId : uuidv4(),
                name: swimlaneName,
                color: swimlaneColor,
                blocks: []
            }

            if (editSwimlaneId) {
                let laneIndex = diagramData.findIndex(x => x.id === editSwimlaneId);
                let blocks = diagramData[laneIndex].blocks;
                diagramData[laneIndex] = lane;
                diagramData[laneIndex].blocks = blocks;
            } else {
                diagramData.push(lane)

                let blockCount = 0;
                diagramData.map((lane) => {
                    lane.blocks.map((block, blockIndex) => {
                        if (blockCount <= blockIndex) {
                            blockCount = blockIndex + 1
                        }
                    })
                })
                diagramData.map((lane, laneIndex) => {
                    for (var i = 0; i < blockCount; i++) {
                        if (diagramData[laneIndex].blocks[i] !== undefined) {
                            diagramData[laneIndex].blocks[i] = diagramData[laneIndex].blocks[i]
                        } else {
                            diagramData[laneIndex].blocks[i] = {};
                        }
                    }
                });
            }

            this.props.setMultipleProjectDiagramData({
                swimlaneName: "",
                swimlaneColor: "#14213e",
                swimlaneModal: false,
                diagramData: [...diagramData],
                editSwimlaneId: ""
            })
            this.save(false);
        }
    }

    async saveBlock() {
        let { userId, diagramData, selectedLane, blockName, blockDetails, blockType, blockApprovedFlow, blockRejectedFlow, blockRules, blockParentFlow, document_Json, editBlockId, exisSelectedLane, isMultiple, multiTaskDetails } = this.props;
        let { pageText, aiAgentFlow } = this.state
        let errors = {};
        if (selectedLane === "") {
            errors["selectedLane"] = getValidLabels(pageText, 'pleaseselectlaneErr_msg');
        }
        if (isMultiple) {
            for (let j = 0; j < multiTaskDetails.length; j++) {
                let { blockName, blockRules } = multiTaskDetails[j];

                if (blockName === "") {
                    if (errors["blockName"] === undefined) {
                        errors["blockName"] = []
                    }
                    errors["blockName"][j] = getValidLabels(pageText, 'pleaseEnterBlockNameErr_msg');
                }
                if (blockRules === "") {
                    if (errors["blockRules"] === undefined) {
                        errors["blockRules"] = []
                    }
                    errors["blockRules"][j] = getValidLabels(pageText, 'pleaseEnterBlockRulesErr_msg');
                }
            }
        } else {
            if (blockName === "") {
                errors["blockName"] = getValidLabels(pageText, 'pleaseEnterBlockNameErr_msg');
            }
            if (blockDetails === "") {
                // errors["blockDetails"] = getValidLabels(pageText, 'pleaseEnterBlockDetailsErr_msg');
            }
            if (blockRules === "") {
                errors["blockRules"] = getValidLabels(pageText, 'pleaseEnterBlockRulesErr_msg');
            }
        }

        this.props.setMultipleProjectDiagramData({
            errors
        })

        if (Object.keys(errors).length === 0) {
            this.props.setMultipleProjectDiagramData({
                submitLoader: true
            });

            let fileList = [];
            let finalMultiTaskDetails = [];

            if (isMultiple) {
                for (let j = 0; j < multiTaskDetails.length; j++) {
                    let fileList = [];
                    let { blockName, blockDetails, document_Json, blockRules } = multiTaskDetails[j];

                    for (var i = 0; i < document_Json.length; i++) {
                        this.sendPostJson(document_Json[i], i);
                        let fileDetails = document_Json[i];
                        fileList.push(JSON.stringify({
                            origFileName: fileDetails.origFileName,
                            fileName: fileDetails.fileName,
                            docsId: fileDetails.docsId,
                            processType: fileDetails.processType,
                            fileType: fileDetails.fileType,
                            userId
                        }));
                    }

                    finalMultiTaskDetails.push({
                        title: blockName,
                        details: blockDetails,
                        attachment: fileList,
                        rules: blockRules
                    })
                }
            } else {
                for (var i = 0; i < document_Json.length; i++) {
                    this.sendPostJson(document_Json[i], i);
                    let fileDetails = document_Json[i];
                    fileList.push(JSON.stringify({
                        origFileName: fileDetails.origFileName,
                        fileName: fileDetails.fileName,
                        docsId: fileDetails.docsId,
                        processType: fileDetails.processType,
                        fileType: fileDetails.fileType,
                        userId
                    }));
                }
            }




            let blockOption = {
                id: editBlockId ? editBlockId : uuidv4(),
                type: blockType,
                title: blockName,
                details: blockDetails,
                attachment: fileList,
                rules: blockRules,
                parentFlow: blockParentFlow,
                multiTaskDetails: finalMultiTaskDetails,
                approvedFlow: blockType === "conditionalBlock" ? blockApprovedFlow : "",
                rejectedFlow: blockType === "conditionalBlock" ? blockRejectedFlow : "",
                aiAgentFlow: blockType === "aiAgent" ? aiAgentFlow : ""
            }


            let laneIndex = diagramData.findIndex(x => x.id === selectedLane);
            if (editBlockId && exisSelectedLane === selectedLane) {
                let existBlocks = diagramData[laneIndex].blocks;
                let blockIndex = existBlocks.findIndex(x => x.id === editBlockId);
                diagramData[laneIndex].blocks[blockIndex] = blockOption;
            } else if (editBlockId) {
                let existLaneIndex = diagramData.findIndex(x => x.id === exisSelectedLane);
                let existBlocks = diagramData[existLaneIndex].blocks;
                let blockIndex = existBlocks.findIndex(x => x.id === editBlockId);
                diagramData[existLaneIndex].blocks[blockIndex] = {};
                diagramData[laneIndex].blocks[blockIndex] = blockOption;
            } else {
                diagramData[laneIndex].blocks.push({});

                let blockCount = 0;
                diagramData.map((lane) => {
                    lane.blocks.map((block, blockIndex) => {
                        if (blockCount <= blockIndex) {
                            blockCount = blockIndex + 1
                        }
                    })
                })

                let notEmptyIndex = 0;
                for (var i = 0; i < blockCount; i++) {
                    if (diagramData[laneIndex].blocks[i] !== undefined && Object.keys(diagramData[laneIndex].blocks[i]).length > 0) {
                        notEmptyIndex = i + 1;
                    }
                }

                diagramData[laneIndex].blocks[notEmptyIndex] = blockOption

                diagramData.map((lane, laneIndex) => {
                    for (var i = 0; i < blockCount; i++) {
                        if (diagramData[laneIndex].blocks[i] !== undefined) {
                            diagramData[laneIndex].blocks[i] = diagramData[laneIndex].blocks[i]
                        } else {
                            diagramData[laneIndex].blocks[i] = {};
                        }
                    }
                });
            }

            this.setState({
                aiAgentFlow: {}
            })
            this.props.setMultipleProjectDiagramData({
                diagramData: [...diagramData],
                selectedLane: "",
                blockName: "",
                blockDetails: "",
                blockRules: "",
                blockParentFlow: "",
                documentFiles: [],
                document_Json: [],
                blockModal: false,
                isMultiple: false,
                multiTaskDetails: [
                    {
                        blockName: "",
                        blockRules: "",
                        blockDetails: "",
                        documentFiles: [],
                        document_Json: []
                    }
                ]
            })

            setTimeout(() => {
                ReactTooltip.rebuild();
            }, 100)
            this.save(false);
            this.drawLine(diagramData)
        }
    }

    async sendPostJson(documentArray, i) {
        await this.uploadDocumentFiles(documentArray, i);
    }

    async uploadDocumentFiles(documentArray, i) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);
        let { userId } = this.props;
        let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/" + userId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }

    documentOnDrop(newFiles, index) {
        var documentFiles = [];
        var document_Json = [];

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: "",
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "ProjectDiagram",
                fileType: ext
            })
        }

        let { isMultiple, multiTaskDetails } = this.props;

        if (isMultiple) {
            multiTaskDetails[index].documentFiles = documentFiles;
            multiTaskDetails[index].document_Json = document_Json;
            this.props.setMultipleProjectDiagramData({
                multiTaskDetails
            })
        } else {
            this.props.setMultipleProjectDiagramData({
                documentFiles: documentFiles,
                document_Json: document_Json,
                editTextChanges: true
            });
        }
    }

    view(block, laneName) {
        let { diagramData } = this.props;
        let laneOptions = [],
            blockParentFlowOptions = [];

        diagramData.map((lane) => {
            laneOptions.push({ value: lane.id, label: lane.name });
            lane.blocks.map((block) => {
                if (Object.keys(block).length > 0) {
                    let { title, multiTaskDetails } = block;
                    let optionTitle = title;
                    if (multiTaskDetails && multiTaskDetails.length > 0) {
                        for (let j = 0; j < multiTaskDetails.length; j++) {
                            let { title } = multiTaskDetails[j];
                            title = title ? title : "-";
                            optionTitle += title + ", ";
                        }
                        optionTitle = optionTitle.replace(/,\s*$/, "");

                    }
                    blockParentFlowOptions.push({ value: block.id, label: lane.name + " - " + optionTitle })
                }
            })
        })

        this.props.setMultipleProjectDiagramData({
            viewLaneName: laneName,
            viewBlockModal: true,
            swimlaneModal: false,
            blockModal: false,
            viewBlockDetails: block,
            laneOptions,
            blockParentFlowOptions
        })

        this.setState({
            apiListModal: false,
            deployToUserModal: false
        })
    }

    blockActions(block, laneName, actionType) {
        let { diagramData } = this.props;
        let laneOptions = [],
            blockParentFlowOptions = [];

        diagramData.map((lane) => {
            laneOptions.push({ value: lane.id, label: lane.name });
            lane.blocks.map((block) => {
                if (Object.keys(block).length > 0) {
                    let { title, multiTaskDetails } = block;
                    let optionTitle = title;
                    if (multiTaskDetails && multiTaskDetails.length > 0) {
                        for (let j = 0; j < multiTaskDetails.length; j++) {
                            let { title } = multiTaskDetails[j];
                            title = title ? title : "-";
                            optionTitle += title + ", ";
                        }
                        optionTitle = optionTitle.replace(/,\s*$/, "");

                    }
                    blockParentFlowOptions.push({ value: block.id, label: lane.name + " - " + optionTitle })
                }
            })
        })

        this.props.setMultipleProjectDiagramData({
            viewLaneName: laneName,
            viewBlockModal: true,
            blockModal: false,
            swimlaneModal: false,
            viewBlockDetails: block,
            laneOptions,
            blockParentFlowOptions
        })

        this.setState({
            apiListModal: false,
            deployToUserModal: false
        })

        if (actionType === "edit") {
            this.edit(block)
        } else if (actionType === "delete") {
            this.delete(block)
        }
    }

    delete(viewBlockDetails) {
        let { diagramData } = this.props;

        if (viewBlockDetails === undefined) {
            viewBlockDetails = this.props.viewBlockDetails
        }
        let { id } = viewBlockDetails;

        let selectedLane = 0,
            selectedBlock = 0;
        diagramData.map((lane, laneIndex) => {
            lane.blocks.map((block, blockIndex) => {
                if (Object.keys(block).length > 0) {
                    if (block.id === id) {
                        selectedLane = laneIndex;
                        selectedBlock = blockIndex;
                    }
                }
            })
        })

        diagramData[selectedLane].blocks[selectedBlock] = {};

        this.props.setMultipleProjectDiagramData({
            diagramData: [...diagramData],
            viewBlockModal: false
        })
        this.save(false);
        this.drawLine(diagramData)
    }

    edit(viewBlockDetails) {
        let { diagramData } = this.props;

        if (viewBlockDetails === undefined) {
            viewBlockDetails = this.props.viewBlockDetails
        }
        let { id, title, details, attachment, rules, parentFlow, type, approvedFlow, rejectedFlow, multiTaskDetails, aiAgentFlow } = viewBlockDetails;
        let isMultiple = multiTaskDetails && multiTaskDetails.length > 0;

        let laneOptions = [],
            blockParentFlowOptions = [];

        let selectedLane = "";
        diagramData.map((lane) => {
            laneOptions.push({ value: lane.id, label: lane.name });
            lane.blocks.map((block) => {
                if (Object.keys(block).length > 0) {

                    if (block.id === id) {
                        selectedLane = lane.id
                    } else {
                        let { title, multiTaskDetails } = block;
                        let optionTitle = title;
                        if (multiTaskDetails && multiTaskDetails.length > 0) {
                            for (let j = 0; j < multiTaskDetails.length; j++) {
                                let { title } = multiTaskDetails[j];
                                title = title ? title : "-";
                                optionTitle += title + ", ";
                            }
                            optionTitle = optionTitle.replace(/,\s*$/, "");

                        }
                        blockParentFlowOptions.push({ value: block.id, label: lane.name + " - " + optionTitle })
                    }
                }
            })
        })

        let documentFiles = [];
        let document_Json = [];
        let editMultiTaskDetails = []
        if (isMultiple) {
            for (let i = 0; i < multiTaskDetails.length; i++) {
                let { title, details, attachment, rules } = multiTaskDetails[i];

                let documentFiles = [];
                let document_Json = [];
                attachment.map(kval => {
                    let parseImg = JSON.parse(kval)
                    document_Json.push({ ...parseImg, progress: 100, status: 'active', processType: "ProjectDiagram", })
                    documentFiles.push(parseImg)
                })

                editMultiTaskDetails.push({
                    blockName: title,
                    blockDetails: details,
                    blockRules: rules,
                    document_Json: document_Json,
                    documentFiles: documentFiles
                })
            }
        } else {
            attachment.map(kval => {
                let parseImg = JSON.parse(kval)
                document_Json.push({ ...parseImg, progress: 100, status: 'active', processType: "ProjectDiagram", })
                documentFiles.push(parseImg)
            })
        }

        this.props.setMultipleProjectDiagramData({
            isMultiple,
            multiTaskDetails: editMultiTaskDetails,
            editBlockId: id,
            blockName: title,
            blockDetails: details,
            blockRules: rules,
            blockParentFlow: parentFlow,
            blockType: type,
            blockApprovedFlow: approvedFlow,
            blockRejectedFlow: rejectedFlow,
            blockModal: true,
            swimlaneModal: false,
            viewBlockModal: false,
            selectedLane,
            exisSelectedLane: selectedLane,
            laneOptions,
            blockParentFlowOptions,
            documentFiles,
            document_Json
        })

        this.setState({
            aiAgentFlow,
            apiListModal: false,
            deployToUserModal: false
        })
    }

    textCompareEdit() {
        let editTextChanges = false

        let { exisSelectedLane, selectedLane, blockName, blockDetails, blockRules, blockType, blockParentFlow, viewBlockDetails, blockApprovedFlow, blockRejectedFlow, documentFiles } = this.props;
        let { title, details, attachment, rules, parentFlow, type, approvedFlow, rejectedFlow } = viewBlockDetails;

        let documentFilesExist = []
        attachment.map(kval => {
            let parseImg = JSON.parse(kval)
            documentFilesExist.push(parseImg)
        })

        if (exisSelectedLane !== selectedLane || blockName !== title || blockDetails !== details || blockRules !== rules || parentFlow !== blockParentFlow || type !== blockType || approvedFlow !== blockApprovedFlow || rejectedFlow !== blockRejectedFlow || JSON.stringify(documentFilesExist) !== documentFiles) {
            editTextChanges = true
        }

        this.props.setMultipleProjectDiagramData({
            editTextChanges
        })
    }

    textCompareSwimlaneEdit() {
        let editTextChanges = false

        let { swimlaneName, swimlaneColor, editLaneDetails } = this.props;
        let { name, color } = editLaneDetails;

        if (swimlaneName !== name || swimlaneColor !== color) {
            editTextChanges = true
        }

        this.props.setMultipleProjectDiagramData({
            editTextChanges
        })
    }

    documentOnRemove(loopIndex) {
        var document_Json = [...this.props.document_Json];
        var documentFiles = [...this.props.documentFiles];
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.props.setMultipleProjectDiagramData({
            document_Json: document_Json,
            documentFiles: documentFiles,
            editTextChanges: true
        });
    }

    multiTaskDocumentOnRemove(index, loopIndex) {
        let { multiTaskDetails } = this.props;

        let { document_Json, documentFiles } = multiTaskDetails[index]
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);

        multiTaskDetails[index].document_Json = document_Json
        multiTaskDetails[index].documentFiles = documentFiles
        this.props.setMultipleProjectDiagramData({
            multiTaskDetails,
            editTextChanges: true
        });
    }

    editLane(lane) {
        let { id, name, color } = lane;

        this.props.setMultipleProjectDiagramData({
            editLaneDetails: lane,
            swimlaneColor: color,
            swimlaneName: name,
            editSwimlaneId: id,
            swimlaneModal: true,
            blockModal: false,
            viewBlockModal: false
        })

        this.setState({
            apiListModal: false,
            deployToUserModal: false
        })
    }

    deleteLane(index) {
        if (window.confirm("Are you sure to delete this swimlane?") === true) {
            let { diagramData } = this.props;
            diagramData.splice(index, 1);

            this.props.setMultipleProjectDiagramData({
                diagramData,
            })
            this.save(false);
            this.drawLine(diagramData)
        }
    }


    onDragStart(dragLaneIndex, dragBlockIndex) {
        this.props.setMultipleProjectDiagramData({
            dragLaneIndex,
            dragBlockIndex
        })
    }

    onDrop(dragEndLaneIndex, dragEndBlockIndex) {
        let { dragLaneIndex, dragBlockIndex, diagramData } = this.props
        if (dragLaneIndex !== dragEndLaneIndex || dragBlockIndex !== dragEndBlockIndex) {
            const removed = diagramData[dragLaneIndex].blocks[dragBlockIndex];
            diagramData[dragLaneIndex].blocks[dragBlockIndex] = {};
            diagramData[dragEndLaneIndex].blocks[dragEndBlockIndex] = removed;


            this.props.setMultipleProjectDiagramData({
                diagramData
            })
            this.save(false);
            this.drawLine(diagramData)
        }
    }

    renderBlockClass(type) {
        let str = "box";

        switch (type) {
            case "startEvent":
            case "endEvent":
                str = "circle " + type;
                break;
        }

        return str;
    }
    async getPageText() {
        let pageText = await getPageText('projectDiagram');
        this.setState({ pageText: pageText })
    }


    getBlockTooltip(block) {
        let { blockTypeOptions } = this.props;
        let typeValue = blockTypeOptions.filter(option => option.value === block.type)
        let type = typeValue.length > 0 ? " -  " + typeValue[0].label : "";

        return "Block" + type
    }

    sendWorkFlowDef() {
        let { companyId, diagramData } = this.props;
        let { projectName } = this.state
        let { clientId, projectId, designType } = this.props.match.params;
        if (designType === undefined) {
            let pinTabInfo = this.props.pinTabInfo

            clientId = pinTabInfo.clientId;
            projectId = pinTabInfo.projectId;
            designType = pinTabInfo.designType;
        }

        let workFlowData = []
        let modDiagramData = []
        for (let i = 0; i < diagramData.length; i++) {
            let cloneDiagramData = { ...diagramData[i] }
            let { blocks } = cloneDiagramData

            let blocksArr = []
            for (let j = 0; j < blocks.length; j++) {
                if (Object.keys(blocks[j]).length > 0) {
                    let { type, aiAgentFlow } = blocks[j]
                    if (type === "aiAgent") {
                        workFlowData.push(aiAgentFlow)
                    }
                    blocksArr.push(blocks[j])
                }
            }

            if (blocksArr.length > 0) {
                cloneDiagramData.blocks = blocksArr
                modDiagramData.push(cloneDiagramData)
            }
        }


        let postJson = {
            reqId: getUuidv4(),
            orgId: companyId,
            projectId,
            designType,
            workFlowDef: modDiagramData,
            projectName
        }
        console.log("postJson", postJson)

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.teqreqSetDomain);
        request.setEvent(GlobalEventCons.sendWorkFlowDef);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Updated Successfully.", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    deployWorkFlowForUser() {
        this.setState({
            deployLoader: true
        }, () => {

            let { companyId } = this.props;
            let { selectedUser } = this.state;

            let { clientId, projectId, designType } = this.props.match.params;
            if (designType === undefined) {
                let pinTabInfo = this.props.pinTabInfo

                clientId = pinTabInfo.clientId;
                projectId = pinTabInfo.projectId;
                designType = pinTabInfo.designType;
            }

            let postJson = {
                reqId: getUuidv4(),
                orgId: companyId,
                projectId,
                designType,
                userId: selectedUser.value
            }
            console.log("postJson", postJson)

            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.teqreqSetDomain);
            request.setEvent(GlobalEventCons.deployWorkFlowForUser);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        this.setState({
                            deployToUserModal: false,
                            deployLoader: false
                        })
                        toast.success("Updated Successfully.", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        })
    }

    renderDiagram() {
        let { diagramData, diagramType } = this.props
        let { pageText } = this.state;
        let str = <React.Fragment>
            {
                diagramData.map((lane, laneIndex) => {
                    return <div className="lane" key={laneIndex}>
                        <div className="lane-name" style={{ backgroundColor: lane.color }}>
                            <div className="lane-options">
                                <div className="lane-edit" onClick={() => this.editLane(lane)}><i className="fa fa-edit" data-tip={getValidTooltip(pageText, 'editIconTxt')}></i></div>
                                <div className="lane-delete" onClick={() => this.deleteLane(laneIndex)} style={{ color: '#E91E63' }}><i className="fa fa-trash-alt" data-tip={"Click to Delete"}></i></div>
                            </div>
                            <span className="name">{lane.name}</span>
                        </div>
                        {
                            lane.blocks.map((block, blockIndex) => {
                                let shades = generateShades(lane.color, 5)
                                return Object.keys(block).length > 0 ? <div className="block" key={blockIndex} onClick={() => this.view(block, lane.name)}>
                                    <div id={block.id} data-tip={this.getBlockTooltip(block)} style={{ backgroundColor: shades[3] }} className={this.renderBlockClass(block.type)} draggable onDragStart={() => this.onDragStart(laneIndex, blockIndex)} >
                                        {
                                            block.type === "conditionalBlock" && <div className="rejected" id={block.id + "-rejected"}>{getValidLabels(pageText, 'rejectedStatusTxt')}</div>
                                        }
                                        {
                                            (block.multiTaskDetails && block.multiTaskDetails.length > 0) ? <div className="multi-task">
                                                {
                                                    block.multiTaskDetails.map((task, index) => {
                                                        return <div className="title" key={index}>{task.title ? task.title : "-"}</div>
                                                    })
                                                }
                                            </div> : <div className="title">{block.title}</div>
                                        }

                                        {
                                            block.type === "conditionalBlock" && <div className="approved" id={block.id + "-approved"}>{getValidLabels(pageText, 'approvedStatusTxt')}</div>
                                        }
                                    </div>
                                </div> : <div className="block" key={blockIndex} onDrop={() => this.onDrop(laneIndex, blockIndex)} onDragOver={(e) => e.preventDefault()}></div>
                            })
                        }
                    </div>
                })
            }
        </React.Fragment>

        switch (diagramType) {
            case "flowDiagram":
                str = <FlowDiagram
                    diagramData={diagramData}
                    editLane={(lane) => this.editLane(lane)}
                    deleteLane={(laneIndex) => this.deleteLane(laneIndex)}
                    viewBlock={(block, laneName) => this.view(block, laneName)}
                    editBlock={(block, laneName) => this.blockActions(block, laneName, "edit")}
                    deleteBlock={(block, laneName) => {
                        if (window.confirm("Are you sure to delete this block?") === true) {
                            this.blockActions(block, laneName, "delete")
                        }
                    }}
                    updateDiagramData={(diagramData, clone) => this.updateDiagramData(diagramData, clone)}
                />
                break;
        }

        return str
    }

    updateDiagramData(diagramData, clone) {
        let data = clone ? [...diagramData] : diagramData
        this.props.setMultipleProjectDiagramData({
            diagramData: data
        })
        this.save(false, false)
    }

    getApiData(block) {
        let { aiAgentFlow } = block
        let { http, toolCall } = aiAgentFlow
        return <React.Fragment>
            <div className="d-flex url-block">
                <div className="px-2 method-block">{http.method}</div>
                <div className="flex-1">{http.apiURL}</div>
            </div>
            <div className="row m-2">
                <div className="col-sm-12">
                    <div className='form-group'>
                        <label className="form-label custom">Authorization</label>
                        <div>{http.authorization}</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="form-label custom">Parameters</label>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Required</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(toolCall.function.parameters.properties).map((name, index) => {
                                    return <tr>
                                        <td>{name}</td>
                                        <td>{toolCall.function.parameters.properties[name].description}</td>
                                        <td>{toolCall.function.parameters.required.includes(name) ? "Yes" : "No"}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    }
    render() {
        let { diagramType, companyId, fromMobileView, fromMobileAccess, pinTabInfo, path, isMultiple, multiTaskDetails, viewLaneName, blockTypeOptions, blockType, blockApprovedFlow, blockRejectedFlow, diagramData, swimlaneColor, swimlaneModal, editSwimlaneId, errors, swimlaneName, editTextChanges, blockModal, blockName, editBlockId, blockDetails, blockRules, blockParentFlow, blockParentFlowOptions, documentFiles, selectedLane, laneOptions, viewBlockModal, viewBlockDetails } = this.props;
        let { pageText, clientName, projectName, aiAgentFlow, deployToUserModal, deployLoader, selectedUser, apiListModal } = this.state;
        let { clientId, projectId, designType } = this.props.match.params;
        if (designType === undefined) {
            let pinTabInfo = this.props.pinTabInfo

            clientId = pinTabInfo.clientId;
            projectId = pinTabInfo.projectId;
            designType = pinTabInfo.designType;
        }

        let parentLabel = "-"
        if (viewBlockDetails.parentFlow) {
            let parentLabelArr = blockParentFlowOptions.filter(option => option.value === viewBlockDetails.parentFlow);
            if (parentLabelArr.length > 0) {
                parentLabel = parentLabelArr[0].label
            }
        }

        return <div className="flow-diagram">
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            <div className="docs-write teqreq-write">
                <div className={`header ${fromMobileView ? "flex-row d-flex-wrap" : ""}`}>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    <div className="icon" onClick={() => {
                        if (this.props.setPinTabInfo) {
                            this.props.setPinTabInfo({
                                id: "",
                                type: ""
                            })
                        }
                        if (!fromMobileAccess) {
                            this.props.history.push(path + "/project-diagram-list/" + designType);
                        }
                    }} data-tip={capitalize(designType) + " Design Home"}>
                        <i className="fa fa-project-diagram" />
                    </div>
                    <div className="input text-capitalize my-auto" style={{ fontSize: 14 }}>
                        <b>{designType}</b>{": " + clientName + " - " + projectName}
                    </div>
                    <div className="extra">
                        <button className="btn btn-sm btn-success ml-2" onClick={() => this.swimlaneModalSetup()} data-tip={getValidTooltip(pageText, 'addSwimlaneBtnTxt')}>{getValidLabels(pageText, 'addSwimlaneBtnTxt')}</button>
                        <button className="btn btn-sm btn-primary ml-2" onClick={() => this.blockModalSetup()} data-tip={getValidTooltip(pageText, 'addBlockBtnTxt')}>{getValidLabels(pageText, 'addBlockBtnTxt')}</button>
                        <button className="btn btn-sm btn-info ml-2" onClick={() => this.save(true)} data-tip={getValidTooltip(pageText, 'backupBtnTxt')}>{getValidLabels(pageText, 'backupBtnTxt')}</button>
                        <div className="ml-2 project-diagram-ui ontology">
                            <DropdownButton
                                label={<span>View Ontology <i className="fas fa-caret-down ml-3"></i> </span>}
                                options={MyConfig.ontologyView}
                                selected={""}
                                onSelect={(e) => {
                                    let url = "project-diagram-ontology"
                                    if(e.value === "domain") {
                                        url = "project-diagram-domain-ontology"
                                    }
                                    
                                    let redirectUrl = path + "/"+url+"/" + designType + "/" + clientId + "/" + projectId
                                    window.open(redirectUrl, '_blank').focus();
                                }}
                            />
                        </div>
                        <div className="ml-2 project-diagram-ui">
                            <DropdownButton
                                label={<span>
                                    {diagramOptions.filter(option => option.value === diagramType)[0].label}
                                    <i className="fas fa-caret-down ml-3"></i>
                                </span>}
                                options={diagramOptions}
                                selected={""}
                                onSelect={(e) => {
                                    this.props.setMultipleProjectDiagramData({
                                        diagramType: e.value
                                    })
                                }}
                            />
                        </div>
                        <div className="ml-2 project-diagram-more">
                            <DropdownButton
                                label={<i className="fas fa-ellipsis-v mx-1 my-2"></i>}
                                options={[
                                    { label: 'Copy JSON', value: "copyJSON" },
                                    { label: 'View API', value: "viewAPI" },
                                    { label: 'Send Def', value: "sendDef" },
                                    { label: 'Deploy To User', value: "deployToUser" },
                                ]}
                                selected={""}
                                onSelect={(e) => {
                                    switch (e.value) {
                                        case "copyJSON":
                                            navigator.clipboard.writeText(JSON.stringify(diagramData));
                                            toast(`Copied Successfully.`, {
                                                position: "bottom-center",
                                                autoClose: 1000,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "dark",
                                            });
                                            break;
                                        case "viewAPI":
                                            this.setState({
                                                apiListModal: true,
                                                deployToUserModal: false,
                                            })
                                            this.props.setMultipleProjectDiagramData({
                                                swimlaneModal: false,
                                                blockModal: false,
                                                viewBlockModal: false,
                                            })
                                            break;
                                        case "sendDef":
                                            this.sendWorkFlowDef()
                                            break;
                                        case "deployToUser":
                                            this.setState({
                                                deployToUserModal: true,
                                                apiListModal: false
                                            })

                                            this.props.setMultipleProjectDiagramData({
                                                swimlaneModal: false,
                                                blockModal: false,
                                                viewBlockModal: false,
                                            })
                                            break;
                                    }
                                }}
                            />
                        </div>

                        {
                            !checkMobileView() && <button className={`btn btn-sm btn-${pinTabInfo.clientId === clientId && pinTabInfo.projectId === projectId && pinTabInfo.designType === designType && pinTabInfo.type === "project-diagram" ? "success" : "secondary"} pin-button ml-2`} onClick={() => {
                                if (pinTabInfo.clientId === clientId && pinTabInfo.projectId === projectId && pinTabInfo.designType === designType && pinTabInfo.type === "project-diagram") {
                                    this.props.setPinTabInfo({
                                        id: "",
                                        type: "",
                                        clientId: "",
                                        projectId: "",
                                        designType: ""
                                    })
                                } else {
                                    this.props.setPinTabInfo({
                                        id: "",
                                        type: "project-diagram",
                                        clientId,
                                        projectId,
                                        designType
                                    })
                                }
                            }} data-tip={"Pin your TeqReq"}><i className="fas fa-thumbtack" /></button>
                        }

                        <button className='btn btn-primary btn-sm ml-2' onClick={() => this.props.setContextHelp("projectdiagram")} data-tip={getValidTooltip(pageText, 'helpContextTxt')}>
                            <i className="fas fa-question"></i>
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-1">
                    <div className="graph custom-scrollbar" id="graph">
                        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                        {this.renderDiagram()}
                    </div>
                    {swimlaneModal && <Modal
                        visible={swimlaneModal}
                        parentClass={"add-category"}
                        closeModal={() => this.props.setMultipleProjectDiagramData({
                            swimlaneModal: false,
                            swimlaneName: "",
                            editSwimlaneId: ""
                        })}
                        heading={`${editSwimlaneId === "" ? getValidLabels(pageText, 'addSwimlaneHeaderTxt') : getValidLabels(pageText, 'editSwimlaneHeaderTxt')}`}
                        body={<React.Fragment>
                            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                            <div className="form-group">
                                <label className="custom" data-tip={getValidTooltip(pageText, 'nameLabelTxt')}>{getValidLabels(pageText, 'nameLabelTxt')}</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors['swimlaneName'] ? "is-invalid" : ""}`}
                                    placeholder={getValidLabels(pageText, 'enterName_PHTxt')}
                                    value={swimlaneName}
                                    onChange={(e) => this.props.setMultipleProjectDiagramData({
                                        swimlaneName: e.target.value
                                    })
                                    }
                                />
                                {errors['swimlaneName'] && <div className="invalid-feedback">{errors['swimlaneName']}</div>}
                            </div>
                            <div className="form-group">
                                <label className="custom" data-tip={getValidTooltip(pageText, 'colorLabelTxt')}>{getValidLabels(pageText, 'colorLabelTxt')}</label>
                                <input
                                    type="color"
                                    className={`form-control ${errors['swimlaneColor'] ? "is-invalid" : ""}`}
                                    placeholder={getValidLabels(pageText, 'colorLabeltxt')}
                                    value={swimlaneColor}
                                    onChange={(e) => this.props.setMultipleProjectDiagramData({
                                        swimlaneColor: e.target.value
                                    })}
                                />
                                {errors['swimlaneColor'] && <div className="invalid-feedback">{errors['swimlaneColor']}</div>}
                            </div>

                        </React.Fragment>}
                        footer={<React.Fragment>
                            <button type="button" disabled={!editSwimlaneId ? false : editTextChanges ? false : true}
                                className={"btn btn-primary " + (!editSwimlaneId ? "" : editTextChanges ? "" : "btn-disable-color")} onClick={() => this.saveSwimlane()} data-tip={getValidTooltip(pageText, 'saveBtnTxt')}>{getValidLabels(pageText, 'saveBtnTxt')}</button>
                        </React.Fragment>}
                    />
                    }
                    {blockModal && <Modal
                        visible={blockModal}
                        parentClass={"add-category"}
                        closeModal={() => this.props.setMultipleProjectDiagramData({
                            blockModal: false,
                            blockName: "",
                            editBlockId: "",
                            errors: {}
                        })}
                        size={"modal-xl"}
                        heading={`${editBlockId === "" ? getValidLabels(pageText, 'addBlockHeaderTxt') : getValidLabels(pageText, 'editBlockHeaderTxt')}`}
                        body={<React.Fragment>
                            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="custom" data-tip={getValidTooltip(pageText, 'laneLabelTxt')}>{getValidLabels(pageText, 'laneLabelTxt')}</label>
                                        <DropDown
                                            selectedOption={laneOptions.filter(option => option.value === selectedLane)}
                                            onChange={(e) => {
                                                this.props.setMultipleProjectDiagramData({
                                                    selectedLane: e.value
                                                })
                                            }}
                                            options={laneOptions}
                                            isDisabled={false}
                                            isMulti={false}
                                            placeholder={getValidLabels(pageText, 'selectLane_PHTxt')}
                                            error={errors['selectedLane'] ? true : false}
                                        />
                                        {errors['selectedLane'] && <div className="d-block invalid-feedback">{errors['selectedLane']}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="custom" data-tip={getValidTooltip(pageText, 'blockTypeLabelTxt')}>{getValidLabels(pageText, 'blockTypeLabelTxt')}</label>
                                        <DropDown
                                            selectedOption={blockTypeOptions.filter(option => option.value === blockType)}
                                            onChange={(e) => {
                                                this.props.setMultipleProjectDiagramData({
                                                    blockType: e.value
                                                })
                                            }}
                                            options={blockTypeOptions}
                                            isDisabled={false}
                                            isMulti={false}
                                            placeholder={"Select Sender Task"}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="custom" data-tip={"Sender Task"}>{"Sender Task"}</label>
                                        <DropDown
                                            selectedOption={blockParentFlowOptions.filter(option => option.value === blockParentFlow)}
                                            onChange={(e) => {
                                                this.props.setMultipleProjectDiagramData({
                                                    blockParentFlow: e ? e.value : ""
                                                })
                                            }}
                                            options={blockParentFlowOptions}
                                            isDisabled={false}
                                            isMulti={false}
                                            placeholder={"Select Sender Task"}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                                {
                                    blockType === "conditionalBlock" && <React.Fragment>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'approvedFlowLabelTxt')}>{getValidLabels(pageText, 'approvedFlowLabelTxt')}</label>
                                                <DropDown
                                                    selectedOption={blockParentFlowOptions.filter(option => option.value === blockApprovedFlow)}
                                                    onChange={(e) => {
                                                        this.props.setMultipleProjectDiagramData({
                                                            blockApprovedFlow: e.value
                                                        })
                                                    }}
                                                    options={blockParentFlowOptions}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    placeholder={"Select Sender Task"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'rejectedFlowLabelTxt')}>{getValidLabels(pageText, 'rejectedFlowLabelTxt')}</label>
                                                <DropDown
                                                    selectedOption={blockParentFlowOptions.filter(option => option.value === blockRejectedFlow)}
                                                    onChange={(e) => {
                                                        this.props.setMultipleProjectDiagramData({
                                                            blockRejectedFlow: e.value
                                                        })
                                                    }}
                                                    options={blockParentFlowOptions}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    placeholder={"Select Sender Task"}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            {
                                blockType === "aiAgent" && <CaptureAITaskBlock aiAgentFlow={aiAgentFlow} setAiAgentFlow={(e) => this.setAiAgentFlow(e)} />
                            }
                            <div className="d-flex flex-row-reverse">
                                <div className="form-check mx-2">
                                    <input className="form-check-input" type="checkbox" checked={isMultiple} value="" id="ismultiple" onChange={() => {
                                        this.props.setMultipleProjectDiagramData({
                                            isMultiple: !isMultiple
                                        })
                                    }} />
                                    <label className="form-check-label" htmlFor="ismultiple">
                                        Is Multiple Task
                                    </label>
                                </div>
                            </div>
                            {
                                isMultiple ? <React.Fragment>
                                    {
                                        multiTaskDetails.map((task, index) => {
                                            let { blockName, blockRules, blockDetails, documentFiles, document_Json } = task;
                                            return <div className="block-task-details" key={index}>
                                                {
                                                    index > 0 && <div className="text-right">
                                                        <button className="btn btn-sm btn-danger" onClick={() => {
                                                            multiTaskDetails.splice(index, 1)
                                                            this.props.setMultipleProjectDiagramData({
                                                                multiTaskDetails
                                                            })
                                                        }}>Delete Task</button>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'nameLabelTxt')}>{getValidLabels(pageText, 'nameLabelTxt')}</label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${(errors['blockName'] && errors['blockName'][index]) ? "is-invalid" : ""}`}
                                                                placeholder={getValidLabels(pageText, 'enterName_PHTxt')}
                                                                value={blockName}
                                                                onChange={(e) => {
                                                                    multiTaskDetails[index].blockName = e.target.value
                                                                    this.props.setMultipleProjectDiagramData({
                                                                        multiTaskDetails
                                                                    })
                                                                }}
                                                            />
                                                            {(errors['blockName'] && errors['blockName'][index]) && <div className="invalid-feedback">{errors['blockName'][index]}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'rulesLabelTxt')}>{getValidLabels(pageText, 'rulesLabelTxt')}</label>
                                                            <CustomEditor
                                                                title={"Rules"}
                                                                text={blockRules}
                                                                textOnchange={(e) => {
                                                                    multiTaskDetails[index].blockRules = e
                                                                    this.props.setMultipleProjectDiagramData({
                                                                        multiTaskDetails
                                                                    })
                                                                }}
                                                            />
                                                            {(errors['blockRules'] && errors['blockRules'][index]) && <div className="d-block invalid-feedback">{errors['blockRules'][index]}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'detailsLabelTxt')}>{getValidLabels(pageText, 'detailsLabelTxt')}</label>

                                                            <CustomEditor
                                                                title={"Details"}
                                                                text={blockDetails}
                                                                textOnchange={(e) => {
                                                                    multiTaskDetails[index].blockDetails = e
                                                                    this.props.setMultipleProjectDiagramData({
                                                                        multiTaskDetails
                                                                    })
                                                                }}
                                                            />
                                                            {errors['blockDetails'] && <div className="d-block invalid-feedback">{errors['blockDetails']}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label className="custom" data-tip={getValidTooltip(pageText, 'attachmentLabelTxt')}>{getValidLabels(pageText, 'attachmentLabelTxt')}</label>
                                                        {
                                                            <div className="dropback">
                                                                <Dropzone accept=".png, .jpg, .jpeg, .txt, .xlsx, .xls, .csv, .doc, .docx" multiple={false}
                                                                    onDrop={(acceptedFiles) => {
                                                                        this.documentOnDrop(acceptedFiles, index)
                                                                    }} onDropRejected={(reject) => {
                                                                        toast.error(getValidLabels(pageText, 'pleaseChooseImageErr_msg'), {
                                                                            position: "top-right",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    }}  >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section>
                                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                                <input {...getInputProps()} />
                                                                                <div className="row">
                                                                                    <div className="col-sm-3">
                                                                                        <img src={Dragplaceholder} className='dragimgph' />
                                                                                    </div>
                                                                                    <div className="col-sm-9">
                                                                                        <p className='dragtext' data-tip={getValidTooltip(pageText, 'dragAndPlaceFilesTxt')}>
                                                                                            {getValidLabels(pageText, 'dragAndPlaceFilesTxt')}
                                                                                            <br />{getValidLabels(pageText, 'supportFileFormatsTxt')}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                                {
                                                                    documentFiles.map((f, loopIndex) => {
                                                                        return (<div className="my-2" key={loopIndex}>
                                                                            <div className="row rowstructure">
                                                                                <div className="col-sm-8">
                                                                                    {f.name ? f.name : f.origFileName}
                                                                                </div>
                                                                                <div className="col-sm-4" style={{ margin: 'auto' }}>
                                                                                    <button className="btn btn-sm btn-danger" onClick={() => this.multiTaskDocumentOnRemove(index, loopIndex)}><i className="fa fa-trash"></i></button>
                                                                                </div>

                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    <div className="text-center my-2">
                                        <button className="btn btn-sm btn-primary" onClick={() => {
                                            multiTaskDetails.push({
                                                blockName: "",
                                                blockRules: "",
                                                blockDetails: "",
                                                documentFiles: [],
                                                document_Json: []
                                            })
                                            this.props.setMultipleProjectDiagramData({
                                                multiTaskDetails
                                            })
                                        }}>Add Task</button>
                                    </div>
                                </React.Fragment> : <React.Fragment>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'nameLabelTxt')}>{getValidLabels(pageText, 'nameLabelTxt')}</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors['blockName'] ? "is-invalid" : ""}`}
                                                    placeholder={getValidLabels(pageText, 'enterName_PHTxt')}
                                                    value={blockName}
                                                    onChange={(e) => {
                                                        let bRules = blockRules.replace(/<[^>]+>/g, '').replace(/\&nbsp;/g, '').replace(/&gt;/g, ">")
                                                        let bDetails = blockDetails.replace(/<[^>]+>/g, '').replace(/\&nbsp;/g, '').replace(/&gt;/g, ">")
                                                        this.props.setMultipleProjectDiagramData({
                                                            blockName: e.target.value,
                                                            blockRules: blockName === bRules ? e.target.value : blockRules,
                                                            blockDetails: blockName === bDetails ? e.target.value : blockDetails
                                                        })
                                                    }}
                                                />
                                                {errors['blockName'] && <div className="invalid-feedback">{errors['blockName']}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'rulesLabelTxt')}>{getValidLabels(pageText, 'rulesLabelTxt')}</label>
                                                <CustomEditor
                                                    title={"Rules"}
                                                    text={blockRules}
                                                    changeText={blockRules}
                                                    textOnchange={(e) => {
                                                        this.props.setMultipleProjectDiagramData({
                                                            blockRules: e
                                                        })
                                                    }}
                                                />
                                                {errors['blockRules'] && <div className="d-block invalid-feedback">{errors['blockRules']}</div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'detailsLabelTxt')}>{getValidLabels(pageText, 'detailsLabelTxt')}</label>

                                                <CustomEditor
                                                    title={"Details"}
                                                    text={blockDetails}
                                                    changeText={blockDetails}
                                                    textOnchange={(e) => {
                                                        this.props.setMultipleProjectDiagramData({
                                                            blockDetails: e
                                                        })
                                                    }}
                                                />
                                                {errors['blockDetails'] && <div className="d-block invalid-feedback">{errors['blockDetails']}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label className="custom" data-tip={getValidTooltip(pageText, 'attachmentLabelTxt')}>{getValidLabels(pageText, 'attachmentLabelTxt')}</label>
                                            {
                                                <div className="dropback">
                                                    <Dropzone accept=".png, .jpg, .jpeg, .txt, .xlsx, .xls, .csv, .doc, .docx" multiple={false}
                                                        onDrop={(acceptedFiles) => {
                                                            this.documentOnDrop(acceptedFiles)
                                                        }} onDropRejected={(reject) => {
                                                            toast.error(getValidLabels(pageText, 'pleaseChooseImageErr_msg'), {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true
                                                            });

                                                        }}  >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                                    <input {...getInputProps()} />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <img src={Dragplaceholder} className='dragimgph' />
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className='dragtext' data-tip={getValidTooltip(pageText, 'dragAndPlaceFilesTxt')}>
                                                                                {getValidLabels(pageText, 'dragAndPlaceFilesTxt')}
                                                                                <br />{getValidLabels(pageText, 'supportFileFormatsTxt')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    {
                                                        documentFiles.map((f, loopIndex) => {
                                                            return (<div className="my-2" key={loopIndex}>
                                                                <div className="row rowstructure">
                                                                    <div className="col-sm-8">
                                                                        {f.name ? f.name : f.origFileName}
                                                                    </div>
                                                                    <div className="col-sm-4" style={{ margin: 'auto' }}>
                                                                        <button className="btn btn-sm btn-danger" onClick={() => this.documentOnRemove(loopIndex)}><i className="fa fa-trash"></i></button>
                                                                    </div>

                                                                </div>
                                                            </div>)
                                                        }
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>}
                        footer={<React.Fragment>
                            <button type="button" disabled={!editBlockId ? false : editTextChanges ? false : true}
                                className={"btn btn-primary " + (!editBlockId ? "" : editTextChanges ? "" : "btn-disable-color")} onClick={() => this.saveBlock()} data-tip={getValidTooltip(pageText, 'saveBtnTxt')}>{getValidLabels(pageText, 'saveBtnTxt')}</button>
                        </React.Fragment>}
                    />
                    }
                    {
                        apiListModal && <Modal
                            visible={apiListModal}
                            closeModal={() => {
                                this.setState({
                                    apiListModal: false
                                })
                            }}
                            size={"modal-xl"}
                            heading={"All API's"}
                            body={<React.Fragment>
                                {
                                    diagramData.map((lane) => {
                                        return <React.Fragment>
                                            {
                                                lane.blocks.map((block) => {
                                                    let { typeOfSource } = block
                                                    return (Object.keys(block).length > 0 && block.type === "aiAgent") && <div className="api-block">
                                                        {
                                                            this.getApiData(block)
                                                        }
                                                    </div>
                                                })
                                            }
                                        </React.Fragment>

                                    })
                                }
                            </React.Fragment>
                            }
                        />
                    }
                    {
                        deployToUserModal && <Modal
                            visible={deployToUserModal}
                            parentClass={"add-category"}
                            closeModal={() => {
                                this.setState({
                                    deployToUserModal: false
                                })
                            }}
                            heading={"Deploy to User"}
                            body={<React.Fragment>
                                <div className="form-group">
                                    <label>{"User"}</label>
                                    <SearchDropdown
                                        selectedOption={selectedUser}
                                        onChange={(e) => {
                                            this.setState({
                                                selectedUser: e
                                            })
                                        }}
                                        isDisabled={false}
                                        isMulti={false}
                                        placeholder={"Select User"}
                                        error={errors['guests'] ? true : false}
                                        buildOptions={(e) => {
                                            let options = [];
                                            e.forEach((option) => {
                                                option = searchDropdownOptionConvert(option)
                                                let { email, firstName, lastName, id } = option
                                                options.push({ value: id, label: firstName[0].toUpperCase() + firstName.slice(1) + " " + lastName[0].toUpperCase() + lastName.slice(1) + " - " + email })
                                            })
                                            return options
                                        }}
                                        grpcClient={this.props.grpcClient}
                                        searchGrpcClient={this.props.searchGrpcClient}
                                        parentId={companyId}
                                        cacheDataType={GlobalSearchDataTypes.GuestUser}
                                    />
                                </div>
                            </React.Fragment>}
                            footer={<React.Fragment>
                                <button onClick={() => {
                                    this.deployWorkFlowForUser()
                                }} disabled={selectedUser.length === 0} className='btn btn-sm btn-primary'>
                                    {deployLoader && <i className="fa fa-spinner fa-spin"></i>}  Deploy User
                                </button>
                            </React.Fragment>}
                        />
                    }
                    {
                        viewBlockModal && <Modal
                            visible={viewBlockModal}
                            parentClass={"add-category"}
                            closeModal={() => this.props.setMultipleProjectDiagramData({
                                viewBlockModal: false
                            })}
                            size={"modal-lg"}
                            heading={getValidLabels(pageText, 'viewBlockHeaderTxt')}
                            body={<React.Fragment>
                                <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                                <div className="row mb-2">
                                    <div className="col-4">
                                        <label className="custom" data-tip={getValidTooltip(pageText, 'laneLabelTxt')}>{getValidLabels(pageText, 'laneLabelTxt')}</label>
                                        <div>{viewLaneName}</div>
                                    </div>
                                    <div className="col-4">
                                        <label className="custom" data-tip={getValidTooltip(pageText, 'blockTypeLabelTxt')}>{getValidLabels(pageText, 'blockTypeLabelTxt')}</label>
                                        <div>
                                            {viewBlockDetails.type ? blockTypeOptions.filter(option => option.value === viewBlockDetails.type)[0].label : "-"}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="custom" data-tip={"Sender Task"}>{"Sender Task"}</label>
                                        <div>
                                            {
                                                parentLabel
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    viewBlockDetails.type === "aiAgent" && <CaptureAITaskBlockView viewBlockDetails={viewBlockDetails} />
                                }
                                {
                                    (viewBlockDetails.multiTaskDetails && viewBlockDetails.multiTaskDetails.length > 0) ? <div className="multi-task-view">
                                        {
                                            viewBlockDetails.multiTaskDetails.map((task) => {
                                                let { title, rules, details, attachment } = task
                                                return <div className="block">
                                                    <div className="row mb-2">
                                                        <div className="col-4">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'nameLabelTxt')}>{getValidLabels(pageText, 'nameLabelTxt')}</label>
                                                            <div>{title}</div>
                                                        </div>
                                                        <div className="col-4">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'rulesLabelTxt')}>{getValidLabels(pageText, 'rulesLabelTxt')}</label>
                                                            <div dangerouslySetInnerHTML={{ __html: rules }}></div>
                                                        </div>
                                                        <div className="col-4">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'detailsLabelTxt')}>{getValidLabels(pageText, 'detailsLabelTxt')}</label>
                                                            <div dangerouslySetInnerHTML={{ __html: details }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="custom" data-tip={getValidTooltip(pageText, 'attachmentLabelTxt')}>{getValidLabels(pageText, 'attachmentLabelTxt')}</label>
                                                            {
                                                                attachment.length > 0 ? attachment.map((file, fileIndex) => {
                                                                    file = JSON.parse(file);
                                                                    let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + file.userId
                                                                    return <div key={fileIndex} className="col-sm-4">
                                                                        <FileView bodyClassRemove={false} name={"View"} type={file.fileType} src={buildUrl} />
                                                                    </div>
                                                                }) : <div data-tip={getValidTooltip(pageText, 'noFilesfoundTxt')}>{getValidLabels(pageText, 'noFilesfoundTxt')}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div> : <React.Fragment>
                                        <div className="row mb-2">
                                            <div className="col-4">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'nameLabelTxt')}>{getValidLabels(pageText, 'nameLabelTxt')}</label>
                                                <div>{viewBlockDetails.title}</div>
                                            </div>
                                            <div className="col-4">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'rulesLabelTxt')}>{getValidLabels(pageText, 'rulesLabelTxt')}</label>
                                                <div dangerouslySetInnerHTML={{ __html: viewBlockDetails.rules }}></div>
                                            </div>
                                            <div className="col-4">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'detailsLabelTxt')}>{getValidLabels(pageText, 'detailsLabelTxt')}</label>
                                                <div dangerouslySetInnerHTML={{ __html: viewBlockDetails.details }}></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label className="custom" data-tip={getValidTooltip(pageText, 'attachmentLabelTxt')}>{getValidLabels(pageText, 'attachmentLabelTxt')}</label>
                                                {
                                                    viewBlockDetails.attachment.length > 0 ? viewBlockDetails.attachment.map((file, fileIndex) => {
                                                        file = JSON.parse(file);
                                                        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + file.userId
                                                        let downloadUrl = MyConstant.keyList.apiURL + "download/" + file.origFileName + "?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + file.userId
                                                        return <div key={fileIndex} className="col-sm-4">
                                                            <FileView bodyClassRemove={false} name={"View"} type={file.fileType} src={buildUrl} downloadUrl={downloadUrl} />
                                                        </div>
                                                    }) : <div data-tip={getValidTooltip(pageText, 'noFilesfoundTxt')}>{getValidLabels(pageText, 'noFilesfoundTxt')}</div>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }

                            </React.Fragment>}
                            footer={<React.Fragment>
                                <button type="button" className={"btn btn-sm btn-primary"} onClick={() => this.edit()} data-tip={getValidTooltip(pageText, 'editBtnTxt')}>{getValidLabels(pageText, 'editBtnTxt')}</button>
                                <button type="button" className={"btn btn-sm btn-danger"} onClick={() => {
                                    if (window.confirm("Are you sure to delete this block?") === true) {
                                        this.delete()
                                    }
                                }} data-tip={"Delete"}>{"Delete"}</button>
                            </React.Fragment>}
                        />
                    }
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        activeUserType: state.authReducer.activeUserType,
        fromMobileAccess: state.authReducer.fromMobileAccess,
        selectedClient: state.projectDiagramReducer.selectedClient,
        selectedProject: state.projectDiagramReducer.selectedProject,
        projectOptions: state.projectDiagramReducer.projectOptions,
        diagramData: state.projectDiagramReducer.diagramData,
        swimlaneModal: state.projectDiagramReducer.swimlaneModal,
        editSwimlaneId: state.projectDiagramReducer.editSwimlaneId,
        errors: state.projectDiagramReducer.errors,
        swimlaneName: state.projectDiagramReducer.swimlaneName,
        swimlaneColor: state.projectDiagramReducer.swimlaneColor,
        editTextChanges: state.projectDiagramReducer.editTextChanges,
        blockModal: state.projectDiagramReducer.blockModal,
        blockName: state.projectDiagramReducer.blockName,
        editBlockId: state.projectDiagramReducer.editBlockId,
        blockDetails: state.projectDiagramReducer.blockDetails,
        blockRules: state.projectDiagramReducer.blockRules,
        blockParentFlow: state.projectDiagramReducer.blockParentFlow,
        blockParentFlowOptions: state.projectDiagramReducer.blockParentFlowOptions,
        documentFiles: state.projectDiagramReducer.documentFiles,
        document_Json: state.projectDiagramReducer.document_Json,
        selectedLane: state.projectDiagramReducer.selectedLane,
        laneOptions: state.projectDiagramReducer.laneOptions,
        submitLoader: state.projectDiagramReducer.submitLoader,
        viewBlockModal: state.projectDiagramReducer.viewBlockModal,
        viewBlockDetails: state.projectDiagramReducer.viewBlockDetails,
        dragLaneIndex: state.projectDiagramReducer.dragLaneIndex,
        dragBlockIndex: state.projectDiagramReducer.dragBlockIndex,
        blockType: state.projectDiagramReducer.blockType,
        blockTypeOptions: state.projectDiagramReducer.blockTypeOptions,
        blockApprovedFlow: state.projectDiagramReducer.blockApprovedFlow,
        blockRejectedFlow: state.projectDiagramReducer.blockRejectedFlow,
        viewLaneName: state.projectDiagramReducer.viewLaneName,
        editLaneDetails: state.projectDiagramReducer.editLaneDetails,
        exisSelectedLane: state.projectDiagramReducer.exisSelectedLane,
        isMultiple: state.projectDiagramReducer.isMultiple,
        multiTaskDetails: state.projectDiagramReducer.multiTaskDetails,
        diagramType: state.projectDiagramReducer.diagramType,
        state,
    };
};

const mapDispatchToProps = {
    setProjectDiagramData,
    setMultipleProjectDiagramData
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDiagram));
