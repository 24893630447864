import React from 'react';
import Dropzone from 'react-dropzone';
import MyConstant from '../../../config/MyConstant';
import Dragplaceholder from '../../../assets/Dragfile.png';
import { v4 as uuidv4 } from 'uuid';
import { ZiFetchRequest } from '../../../protobuf/generated/ZiFetchService_grpc_web_pb';
import { toast, } from "react-toastify";
import MyConfig from '../../../config/MyConfig';
import { Link } from 'react-router-dom';
import DropDown from '../../../components/DropDown';

import { withRouter } from 'react-router-dom';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import { PDFViewer } from 'react-view-pdf';
import { getGrpcClient, getPageText, getValidLabels, getUuidv4, checkMobileView, accumulation, commonEventList, getPageEventName } from '../../../config/Common';
import CustomEditor from '../../../components/CustomEditor'
import { connect } from 'react-redux';
import GlobalMsgCons from '../../../config/GlobalMsgCons';
import GlobalDomainCons from '../../../config/GlobalDomainCons';
import GlobalEventCons from '../../../config/GlobalEventCons';
const pageNameAcc = "feedbackEditPage"

class FeedbackEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            successMsg: false,
            viewMode: "upload",
            document_Json: [],
            deviceWidth: window.innerWidth,
            loader: false,
            documentFiles: [],
            title: "",
            description: "",
            deviceOs: "",
            feedbackType: "bug",
            feedbackTypeOption: MyConfig.feedbackType,
            deviceOsVersion: "",
            deviceModel: "",
            deviceOtherOsVersion: "",
            deviceOsVersionOption: [],
            completedStatus: "",
            reopenStatus: false,
            reOpenDescription: "",
            issueFrom: "Web",
            submitLoader: false,
            imagePreview: {},
            modalVisible: false,
            pageText: null,
            defaultDescription: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let { userId, companyId } = this.props;
        accumulation(pageNameAcc, commonEventList("pageInitiated"), companyId, userId, this.props.grpcClient)
        this.getPageText()
        this.setState({
            userId,
            companyId,
        })
        this.getIssueDetails()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languagePreference !== this.props.languagePreference) {
            this.getPageText()
        }

    }

    getEditId() {
        let editId = this.props.match.params['editId'];
        return editId ? editId : this.props.id
    }

    getIssueDetails() {
        let editId = this.getEditId();
        let { userId } = this.props;
        let postJson = { userId, issuesId: editId, reqId: getUuidv4(), }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.feedbackSetDomain);
        request.setEvent(GlobalEventCons.appIssuesReportDetailsSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                let parseData = JSON.parse(responseMsg.response)
                let { title, description, metaInfo, fileList, status, feedbackType } = parseData
                let metaParse = JSON.parse(metaInfo)
                let { deviceModel, deviceOs, deviceOsVersion, issueFrom } = metaParse
                // console.log(metaParse)+
                let documentFiles = []
                let document_Json = []
                fileList.map(kval => {
                    let parseImg = JSON.parse(kval)
                    // console.log(parseImg)
                    document_Json.push({ ...parseImg, progress: 100, status: 'active', processType: "issues", })
                    let { docsId, fileType, processType } = parseImg
                    documentFiles.push({ docsId, fileType, processType })
                })

                issueFrom = issueFrom === undefined ? "App" : issueFrom;

                let deviceOsVersionOption = []
                if (deviceOs === "android") {
                    deviceOsVersionOption = MyConfig.andriodOsVersion
                } else if (deviceOs === "ios") {
                    deviceOsVersionOption = MyConfig.iosOsVersion
                }
                let defaultDescription = description
                this.setState({
                    title,
                    description,
                    deviceModel,
                    deviceOs,
                    deviceOsVersion,
                    documentFiles,
                    document_Json,
                    completedStatus: status,
                    issueFrom,
                    deviceOs,
                    deviceOsVersionOption,
                    defaultDescription,
                    feedbackType: feedbackType ? feedbackType : "bug"
                })
            }
        });
    }

    documentOnDrop(newFiles) {
        var documentFiles = this.state.documentFiles;
        var document_Json = this.state.document_Json;

        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: this.state.selectTypeVlaue,
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "issues",
                fileType: ext
            })
        }
        this.setState({
            documentFiles: documentFiles, document_Json: document_Json
        });
    }
    documentOnRemove(loopIndex) {
        var document_Json = this.state.document_Json;
        var documentFiles = this.state.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.setState({
            document_Json: document_Json, documentFiles: documentFiles
        });

    }

    async Update_Documents(alertKey) {
        var that = this;
        this.setState({ viewMode: "progress", loader: true })
        let { feedbackType, userId, title, description, document_Json, deviceOsVersion, deviceOs, deviceModel, deviceOtherOsVersion, issueFrom, pageText } = this.state;
        let { path } = this.props

        if (title === "" || document_Json.length > 15 || (issueFrom === "App" && (deviceModel === "" || deviceOsVersion === "" || (deviceOsVersion === "Others" && deviceOtherOsVersion === "")))) {
            let message = "";
            if (title === "") {
                message = getValidLabels(pageText, 'enterTitleErrTxt')
            } else if (issueFrom === "App" && deviceOsVersion === "") {
                message = "Please select device OS Version";
            } else if (issueFrom === "App" && deviceOsVersion === "Others" && deviceOtherOsVersion === "") {
                message = "Please enter device OS Version";
            } else if (issueFrom === "App" && deviceModel === "") {
                message = "Please enter device model"
            }
            else if (document_Json.length > 15) {
                message = "Maximum upload file limit reached"
            }
            toast.error(message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            this.setState({
                submitLoader: true
            }, async () => {
                let fileList = [];
                for (var i = 0; i < document_Json.length; i++) {
                    await that.sendPostJson(document_Json[i], i);
                    let fileDetails = document_Json[i];
                    fileList.push(JSON.stringify({
                        origFileName: fileDetails.origFileName,
                        fileName: fileDetails.fileName,
                        docsId: fileDetails.docsId,
                        processType: fileDetails.processType,
                        fileType: fileDetails.fileType
                    }));

                }
                deviceOsVersion = deviceOsVersion === "Others" ? deviceOtherOsVersion : deviceOsVersion;

                let editId = this.getEditId();
                let postJson = { userId, fileList, title, description: description, metaInfo: JSON.stringify({ deviceOsVersion, deviceOs, deviceModel, issueFrom }), issuesId: editId, reqId: getUuidv4(), feedbackType }

                if (this.state.reopenStatus) {
                    this.updateStatus()
                }

                const request = new ZiFetchRequest();
                request.setDomain(GlobalDomainCons.feedbackSetDomain);
                request.setEvent(GlobalEventCons.editAppIssuesReportSetEvent);
                request.setMessage(JSON.stringify(postJson));
                request.setDatetime(new Date().toString());
                getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
                    if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                        console.warn("Invalid Request")
                    } else {
                        this.setState({
                            successMsg: true,
                            document_Json: [],
                            documentFiles: [],
                            loader: false,
                            title: "",
                            description: "",
                            deviceOsVersion: "",
                            deviceModel: "",
                            deviceOtherOsVersion: ""
                        }, () => {
                            let responseMsg = JSON.parse(response.getMessage());
                            if (responseMsg.response === GlobalMsgCons.success) {
                                toast.success(getValidLabels(pageText, 'feedbackCreatedSuccess_msg'), {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                });

                                setTimeout(() => {
                                    that.setState({ submitLoader: false, }, () => {
                                        that.props.history.push(path + "/feedback");
                                    })
                                }, 3000)
                            }
                        });
                    }
                });
            })
        }

    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.state.document_Json;
        document_Json[i].progress = 50;
        that.setState({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            await that.uploadDocumentFiles(documentArray, i);
            document_Json[i].progress = 100;
            that.setState({
                document_Json: document_Json
            })
        }
    }

    async uploadDocumentFiles(documentArray, i) {
        // console.log("****", documentArray.path)
        if (documentArray.path) {
            const postFileUpload = new FormData();
            postFileUpload.append('file' + (i + 1), documentArray.path);
            postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
            postFileUpload.append('processType' + (i + 1), documentArray.processType);
            postFileUpload.append('docsId' + (i + 1), documentArray.docsId);

            let { userId } = this.state;
            let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/" + userId, {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            }).then(
                function (response) {
                    return response.json();
                }
            ).then(json => {
                return json.response;
            }).catch(error => console.warn(error));
            return result
        }



    }

    handleChange(e) {
        this.setState({
            deviceOsVersion: e.value
        })
    }

    updateStatus() {
        let { reOpenDescription, completedStatus } = this.state
        let editId = this.getEditId();
        let { userId } = this.props;
        let postJson = {
            issuesId: editId, status: GlobalMsgCons.reopened, statusDescription: reOpenDescription, reqId: getUuidv4(),
            updatedBy: userId, updatedByUserType: GlobalMsgCons.type_user
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.feedbackSetDomain);
        request.setEvent(GlobalEventCons.updateAppIssuesReportStatusSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request")
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === GlobalMsgCons.success) {
                    this.setState({
                        reOpenDescription: "",
                        reopenStatus: false,
                    }, () => {

                    })
                }
            }
        });

    }

    issueFromChange(value) {
        this.setState({
            issueFrom: value
        })
    }
    async getPageText() {
        let pageText = await getPageText('feedbackedit');
        this.setState({ pageText: pageText })
    }

    getContent() {
        let { feedbackTypeOption, feedbackType, submitLoader, documentFiles, title, description, deviceOs, deviceOsVersion, deviceOsVersionOption, deviceModel, deviceOtherOsVersion, issueFrom, imagePreview, modalVisible, pageText, defaultDescription, } = this.state
        let { path, userId, companyId } = this.props
        var that = this
        let videos = ["mp4", "3gp", "ogg", "mov"]

        return <div>
            <div className="row mx-0 my-2">
                <div className="col-sm-6 col-md-3">
                    <label className='form-control lb1'>{getValidLabels(pageText, 'titleLabelTxt')} :</label>
                </div>
                <div className="col-sm-6">
                    <input type="text" className="form-control"
                        placeholder={getValidLabels(pageText, 'titlePlaceholderTxt')}
                        value={title}
                        onChange={(e) => this.setState({ title: e.target.value })} />
                </div>
            </div>
            <div className="row mx-0 my-2">
                <div className="col-sm-6 col-md-3">
                    <label className='form-control lb1'>{getValidLabels(pageText, 'descriptionLabelTxt')} :</label>
                </div>
                <div className="col-sm-6">
                    {/* <textarea
                    className="form-control"
                    placeholder={getValidLabels(pageText, 'descriptionPlaceholderTxt')}
                    value={description}
                    onChange={(e) => this.setState({ description: e.target.value })}></textarea> */}
                    <CustomEditor
                        title={getValidLabels(pageText, 'descriptionLabelTxt')}
                        changeText={defaultDescription}
                        text={description}
                        textOnchange={(e) => {
                            this.setState({
                                description: e
                            })
                        }}
                    />
                </div>
            </div>
            <div className="row mx-0 my-2">
                <div className="col-sm-6 col-md-3">
                    <label className='form-control lb1'>{getValidLabels(pageText, 'feedbackTypeLabelTxt')}</label>
                </div>
                <div className="col-sm-6">
                    <DropDown
                        options={feedbackTypeOption}
                        isSearchable={true}
                        placeholder="Select"
                        onChange={(e) => {
                            this.setState({
                                feedbackType: e.value
                            })
                        }}
                        value={feedbackTypeOption.filter(option => option.value === feedbackType)}
                    />
                </div>
            </div>
            <div className="row mx-0 my-2 d-none">
                <div className="col-sm-6 col-md-3">
                    <label className='form-control lb1'>Feedback from :</label>
                </div>
                <div className="col-sm-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="issueFrom" value="App" checked={issueFrom === "App"} onChange={(e) => this.issueFromChange(e.currentTarget.value)} />
                        <label className="form-check-label">App</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="issueFrom" value="Web" checked={issueFrom === "Web"} onChange={(e) => this.issueFromChange(e.currentTarget.value)} />
                        <label className="form-check-label">Web</label>
                    </div>
                </div>
            </div>
            {
                issueFrom === "App" && <React.Fragment>
                    <div className="row mx-0 my-2">
                        <div className="col-sm-6 col-md-3">
                            <label className='form-control lb1'>Device OS :</label>
                        </div>
                        <div className="col-sm-6">
                            <b style={{ textTransform: "uppercase" }}>{deviceOs}</b>
                        </div>
                    </div>
                    <div className="row mx-0 my-2">
                        <div className="col-sm-6 col-md-3">
                            <label className='form-control lb1'>Device OS Version:</label>
                        </div>
                        <div className="col-sm-6">
                            <DropDown
                                options={deviceOsVersionOption}
                                isSearchable={true}
                                placeholder="Select"
                                onChange={this.handleChange}
                                value={deviceOsVersionOption.filter(option => option.value === deviceOsVersion)}
                            />
                        </div>
                    </div>
                    {
                        deviceOsVersion === "Others" && <div className="row mx-0 my-2">
                            <div className="col-sm-6 col-md-3">
                                <label className='form-control lb1'>OS Version :</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control"
                                    placeholder="OS Version"
                                    value={deviceOtherOsVersion}
                                    onChange={(e) => this.setState({ deviceOtherOsVersion: e.target.value })} />
                            </div>
                        </div>
                    }

                    <div className="row mx-0 my-2">
                        <div className="col-sm-6 col-md-3">
                            <label className='form-control lb1'>Device Model :</label>
                        </div>
                        <div className="col-sm-6">
                            <input type="text" className="form-control"
                                placeholder="Device Model"
                                value={deviceModel}
                                onChange={(e) => this.setState({ deviceModel: e.target.value })} />
                        </div>
                    </div>
                </React.Fragment>
            }
            <div className="row mx-0 my-2">
                <div className="col-sm-6 col-md-3">
                    <label className='form-control lb1'>{getValidLabels(pageText, 'uploadImage/Video/PDFLabelTxt')} :</label>
                </div>
                <div className="col-sm-6">
                    <div className="dropback">
                        <Dropzone accept=".png, .jpg, .mp4, .3gp, .ogg, .mov, .pdf, .docx" multiple={true}
                            onDrop={acceptedFiles => that.documentOnDrop(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <div className="row">
                                            <div className="col-4">
                                                <img src={Dragplaceholder} className='dragimgph' />
                                            </div>
                                            <div className="col-8">
                                                <p className='dragtext'>
                                                    {getValidLabels(pageText, 'dragFilesTxt')}
                                                    <br />{getValidLabels(pageText, 'supportFileTxt')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
            </div>
            <div className='row mx-0 '>
                <div className='col-sm-6 col-md-3'></div>
                <div className='col-sm-6'>
                    <div className='row my-2'>
                        {
                            documentFiles.map((f, loopIndex) => {
                                let filePath = ""
                                let fileName = ""
                                let fileType = ""
                                if (f instanceof File) {
                                    filePath = URL.createObjectURL(f)
                                    fileName = f.name
                                    fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                }
                                else {
                                    let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=" + userId
                                    filePath = buildUrl
                                    fileType = f.fileType
                                }

                                // console.log(fileType)
                                let returnData = ""
                                if (videos.includes(fileType.toLowerCase())) {
                                    returnData = <i className="fa fa-video" aria-hidden="true"
                                        style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}

                                        onClick={() => {
                                            let imagePreview = { filePath, fileType }
                                            this.setState({ imagePreview, modalVisible: true })
                                        }}
                                    ></i>
                                }
                                else if (fileType === "pdf") {
                                    returnData = <i className="fa fa-file-pdf" aria-hidden="true"

                                        style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                                        onClick={() => {
                                            let imagePreview = { filePath, fileType }
                                            this.setState({ imagePreview, modalVisible: true })
                                        }}
                                    ></i>
                                } else if (fileType === "docx") {
                                    returnData = <i className="fa fa-file-alt" aria-hidden="true"

                                        style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                                        onClick={() => {
                                            let imagePreview = { filePath, fileType }
                                            this.setState({ imagePreview, modalVisible: true })
                                        }}
                                    ></i>
                                }
                                else {
                                    returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer" }}
                                        onClick={() => {
                                            let imagePreview = { filePath, fileType }
                                            this.setState({ imagePreview, modalVisible: true })
                                        }}
                                    />

                                }
                                return (<>
                                    <div className='col-sm-4 col-md-3 file-box' key={loopIndex}>
                                        {returnData}
                                        <i className="fa fa-trash delete-upload-file"
                                            onClick={() => that.documentOnRemove(loopIndex)}

                                        ></i>
                                    </div>
                                </>)
                            })
                        }
                    </div>
                </div>
            </div>

            {this.state.completedStatus && this.state.completedStatus === GlobalMsgCons.completed &&
                <div className="row mx-0 my-2">
                    <div className="col-sm-6 col-md-3">
                        <label className='form-control lb1'>{getValidLabels(pageText, 'reopenLabelTxt')}</label>
                    </div>
                    <div className="col-sm-6">
                        <input className="form-check-input" type="checkbox"
                            checked={this.state.reopenStatus}
                            style={{ width: 75, height: 30 }}
                            onChange={(e) => {
                                this.setState({ reopenStatus: e.target.checked })
                            }} />
                        <br />
                        {this.state.reopenStatus && <textarea
                            className="form-control mt-4"
                            placeholder="Reopen Description"
                            value={this.state.reOpenDescription}
                            onChange={(e) => this.setState({ reOpenDescription: e.target.value })}></textarea>}

                    </div>
                </div>}

            <div className='row mx-0 my-2'>
                <div className='col-12 text-center'>
                    <button type="button"
                        className="btn btn-success btntop "
                        onClick={() => {
                            accumulation(pageNameAcc, getPageEventName("feedbackEditPage", "updateBtnClicked"), companyId, userId, this.props.grpcClient)
                            that.Update_Documents(true)
                        }}
                        disabled={submitLoader}
                    >
                        {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                        {getValidLabels(pageText, 'updateBtnTxt')}
                    </button>
                </div>
            </div>
        </div>
    }
    render() {
        let { imagePreview, modalVisible, pageText, submitLoader } = this.state
        let { path, companyId, userId } = this.props
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let returnModelBody = ""
        if (imagePreview.fileType) {
            if (videos.includes(imagePreview.fileType.toLowerCase())) {
                returnModelBody = <>
                    <video width="400" controls>
                        <source src={imagePreview.filePath} />
                    </video>
                </>
            }
            else if (imagePreview.fileType === "pdf") {
                returnModelBody = <PDFViewer url={imagePreview.filePath} />

            } else if(imagePreview.fileType === "docx") {
                returnModelBody = "No Preview."
            }
            else {
                returnModelBody = <>
                    <img src={imagePreview.filePath} className={"img-thumbnail"} style={{ height: 400 }} />
                </>
            }
        }
        return <React.Fragment>
            {
                checkMobileView() ? <div className='mobile-container'>
                    <div className="header">
                        <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18, alignItems: "center" }}>
                            <div style={{ padding: "0 10px" }} onClick={() => {
                                this.props.history.push(path + "/feedback")
                            }}>
                                <i className='fas fa-chevron-left' />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div>{getValidLabels(pageText, 'editFeedbackHeaderTxt')}</div>
                            </div>
                            <div style={{ padding: "0 10px" }}>
                                <button type="button"
                                    className="btn btn-success btntop "
                                    onClick={() => {
                                        accumulation(pageNameAcc, getPageEventName("feedbackEditPage", "updateBtnClicked"), companyId, userId, this.props.grpcClient)
                                        this.Update_Documents(true)
                                    }}
                                    disabled={submitLoader}
                                >
                                    {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                                    {getValidLabels(pageText, 'updateBtnTxt')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='body'>
                        {this.getContent()}
                    </div>
                </div> : <div className={`container ${this.props.id ? "page-scroll" : ""}`}>
                    <Card
                        title={getValidLabels(pageText, 'editFeedbackHeaderTxt')}
                        options={<Link to={path + "/feedback"} className="btn btn-sm btn-primary" onClick={() => { accumulation(pageNameAcc, getPageEventName("feedbackEditPage", "backBtnClicked"), companyId, userId, this.props.grpcClient) }}>{getValidLabels(pageText, 'backBtnTxt')}</Link>}>
                        {this.getContent()}
                    </Card>
                </div>
            }

            {modalVisible &&
                <Modal
                    visible={modalVisible}
                    size={"modal-xl"}
                    closeModal={() => this.setState({ modalVisible: false, imagePreview: {} })}
                    heading={getValidLabels(pageText, 'fileViewHeaderTxt')}
                    parentClass={"text-center"}
                    body={<React.Fragment>
                        {returnModelBody}
                    </React.Fragment>}
                />
            }
        </React.Fragment>;
    }
}


const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        firstName: state.authReducer.firstName,
    }
}
export default withRouter(connect(mapStateToProps)(FeedbackEdit));
