export const SET_AUTH = 'SET_AUTH';
export const SET_MULTIPLE_AUTH = 'SET_MULTIPLE_AUTH';
export const RESET_AUTH = 'RESET_AUTH';

export const SET_GUEST_AUTH = 'SET_GUEST_AUTH';
export const SET_MULTIPLE_GUEST_AUTH = 'SET_MULTIPLE_GUEST_AUTH';
export const RESET_GUEST_AUTH = 'RESET_GUEST_AUTH';

//Login
export const SET_LOGIN = 'SET_LOGIN';
export const RESET_LOGIN = 'RESET_LOGIN';

//REGISTER
export const SET_REGISTER = 'SET_REGISTER';
export const SET_MULTIPLE_REGISTER = 'SET_MULTIPLE_REGISTER';
export const RESET_REGISTER = 'RESET_REGISTER';

//FORGOT_PASSWORD
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD';
export const SET_MULTIPLE_FORGOT_PASSWORD = 'SET_MULTIPLE_FORGOT_PASSWORD';
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD';

//SWITCH_ACCOUNT
export const SET_SWITCH_ACCOUNT = 'SET_SWITCH_ACCOUNT';
export const SET_MULTIPLE_SWITCH_ACCOUNT = 'SET_MULTIPLE_SWITCH_ACCOUNT';
export const RESET_SWITCH_ACCOUNT = 'RESET_SWITCH_ACCOUNT';

//WORKSPACE
export const SET_WORKSPACE = 'SET_WORKSPACE';
export const SET_MULTIPLE_WORKSPACE = 'SET_MULTIPLE_WORKSPACE';
export const RESET_WORKSPACE = 'RESET_WORKSPACE';
export const REMOVE_WORKSPACE = 'REMOVE_WORKSPACE';

//DAILY_UPDATE
export const SET_DAILY_UPDATE = 'SET_DAILY_UPDATE';
export const SET_MULTIPLE_DAILY_UPDATE = 'SET_MULTIPLE_DAILY_UPDATE';
export const RESET_DAILY_UPDATE = 'RESET_DAILY_UPDATE';
export const REMOVE_DAILY_REPORT = 'REMOVE_DAILY_REPORT';

//DAILY_REPORT_ORG
export const SET_DAILY_REPORT_ORG = 'SET_DAILY_REPORT_ORG';
export const SET_MULTIPLE_DAILY_REPORT_ORG = 'SET_MULTIPLE_DAILY_REPORT_ORG';
export const REMOVE_DAILY_REPORT_ORG = 'REMOVE_DAILY_REPORT_ORG';
export const REMOVE_DAILY_REPORT_ORG_DATA = 'REMOVE_DAILY_REPORT_ORG_DATA';
export const RESET_DAILY_REPORT_ORG = 'RESET_DAILY_REPORT_ORG';

//EXPENSES
export const SET_EXPENSES = 'SET_EXPENSES';
export const SET_MULTIPLE_EXPENSES = 'SET_MULTIPLE_EXPENSES';
export const REMOVE_EXPENSES = 'REMOVE_EXPENSES';
export const RESET_EXPENSES = 'RESET_EXPENSES';

//QAT_LIST
export const SET_QAT_LIST = 'SET_QAT_LIST';
export const SET_MULTIPLE_QAT_LIST = 'SET_MULTIPLE_QAT_LIST';
export const RESET_QAT_LIST = 'RESET_QAT_LIST';

//QAT_CREATE
export const SET_QAT_CREATE = 'SET_QAT_CREATE';
export const SET_MULTIPLE_QAT_CREATE = 'SET_MULTIPLE_QAT_CREATE';
export const RESET_QAT_CREATE = 'RESET_QAT_CREATE';

//QAT_EDIT
export const SET_QAT_EDIT = 'SET_QAT_EDIT';
export const SET_MULTIPLE_QAT_EDIT = 'SET_MULTIPLE_QAT_EDIT';
export const RESET_QAT_EDIT = 'RESET_QAT_EDIT';

//APPS
export const SET_APPS = 'SET_APPS';
export const SET_MULTIPLE_APPS = 'SET_MULTIPLE_APPS';
export const RESET_APPS = 'RESET_APPS';

//PROJECT_DIAGRAM
export const SET_PROJECT_DIAGRAM = 'SET_PROJECT_DIAGRAM';
export const SET_MULTIPLE_PROJECT_DIAGRAM = 'SET_MULTIPLE_PROJECT_DIAGRAM';
export const RESET_PROJECT_DIAGRAM = 'RESET_PROJECT_DIAGRAM';

//ORG_FEEDBACK_LIST
export const SET_FEEDBACK_LIST = 'SET_FEEDBACK_LIST';
export const SET_MULTIPLE_FEEDBACK_LIST = 'SET_MULTIPLE_FEEDBACK_LIST';
export const RESET_FEEDBACK_LIST = 'RESET_FEEDBACK_LIST';

//ORG_FEEDBACK_ADD
export const SET_FEEDBACK_ADD = 'SET_FEEDBACK_ADD';
export const SET_MULTIPLE_FEEDBACK_ADD = 'SET_MULTIPLE_FEEDBACK_ADD';
export const RESET_FEEDBACK_ADD = 'RESET_FEEDBACK_ADD';

//ORG_FEEDBACK_EDIT
export const SET_FEEDBACK_EDIT = 'SET_FEEDBACK_EDIT';
export const SET_MULTIPLE_FEEDBACK_EDIT = 'SET_MULTIPLE_FEEDBACK_EDIT';
export const RESET_FEEDBACK_EDIT = 'RESET_FEEDBACK_EDIT';

//USER_ORG_FEEDBACK_LIST
export const SET_USER_FEEDBACK_LIST = 'SET_USER_FEEDBACK_LIST';
export const SET_MULTIPLE_USER_FEEDBACK_LIST = 'SET_MULTIPLE_FUSER_EEDBACK_LIST';
export const RESET_USER_FEEDBACK_LIST = 'RESET_USER_FEEDBACK_LIST';

//REPORT
export const SET_REPORT = 'SET_REPORT';
export const SET_MULTIPLE_REPORT = 'SET_MULTIPLE_REPORT';
export const RESET_REPORT = 'RESET_REPORT';

//ENTRY REPORT
export const SET_ENTRY_REPORT = 'SET_ENTRY_REPORT';
export const SET_MULTIPLE_ENTRY_REPORT = 'SET_MULTIPLE_ENTRY_REPORT';
export const RESET_ENTRY_REPORT = 'RESET_ENTRY_REPORT';

//EXPENSE REPORT
export const SET_EXPENSE_REPORT = 'SET_EXPENSE_REPORT';
export const SET_MULTIPLE_EXPENSE_REPORT = 'SET_MULTIPLE_EXPENSE_REPORT';
export const RESET_EXPENSE_REPORT = 'RESET_EXPENSE_REPORT';

//CALENDAR
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_MULTIPLE_CALENDAR = 'SET_MULTIPLE_CALENDAR';
export const RESET_CALENDAR = 'RESET_CALENDAR';

//MEMBERS
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_MULTIPLE_MEMBERS = 'SET_MULTIPLE_MEMBERS';
export const RESET_MEMBERS = 'RESET_MEMBERS';

//MEMBERS
export const SET_CLIENT = 'SET_CLIENT';
export const SET_MULTIPLE_CLIENT = 'SET_MULTIPLE_CLIENT';
export const RESET_CLIENT = 'RESET_CLIENT';

//SERVICE_TYPE
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_MULTIPLE_SERVICE_TYPE = 'SET_MULTIPLE_SERVICE_TYPE';
export const RESET_SERVICE_TYPE = 'RESET_SERVICE_TYPE';

//DESIGNATION
export const SET_DESIGNATION = 'SET_DESIGNATION';
export const SET_MULTIPLE_DESIGNATION = 'SET_MULTIPLE_DESIGNATION';
export const RESET_DESIGNATION = 'RESET_DESIGNATION';

//EXPENSES_CATEGORY
export const SET_EXPENSES_CATEGORY = 'SET_EXPENSES_CATEGORY';
export const SET_MULTIPLE_EXPENSES_CATEGORY = 'SET_MULTIPLE_EXPENSES_CATEGORY';
export const RESET_EXPENSES_CATEGORY = 'RESET_EXPENSES_CATEGORY';

//BOARD_STATUS_TYPE
export const SET_BOARD_STATUS_TYPE = 'SET_BOARD_STATUS_TYPE';
export const SET_MULTIPLE_BOARD_STATUS_TYPE = 'SET_MULTIPLE_BOARD_STATUS_TYPE';
export const RESET_BOARD_STATUS_TYPE = 'RESET_BOARD_STATUS_TYPE';

//CLIENT_MASTER
export const SET_CLIENT_MASTER = 'SET_CLIENT_MASTER';
export const SET_MULTIPLE_CLIENT_MASTER = 'SET_MULTIPLE_CLIENT_MASTER';
export const RESET_CLIENT_MASTER = 'RESET_CLIENT_MASTER';

//PROJECT
export const SET_PROJECT = 'SET_PROJECT';
export const SET_MULTIPLE_PROJECT = 'SET_MULTIPLE_PROJECT';
export const RESET_PROJECT = 'RESET_PROJECT';

//TEAM
export const SET_TEAM = 'SET_TEAM';
export const SET_MULTIPLE_TEAM = 'SET_MULTIPLE_TEAM';
export const RESET_TEAM = 'RESET_TEAM';

//TASK
export const SET_TASK = 'SET_TASK';
export const SET_MULTIPLE_TASK = 'SET_MULTIPLE_TASK';
export const RESET_TASK = 'RESET_TASK';

//CLOUD_PROVIDER_USER_MAP
export const SET_CLOUD_PROVIDER_USER_MAP = 'SET_CLOUD_PROVIDER_USER_MAP';
export const SET_MULTIPLE_CLOUD_PROVIDER_USER_MAP = 'SET_MULTIPLE_CLOUD_PROVIDER_USER_MAP';
export const RESET_CLOUD_PROVIDER_USER_MAP = 'RESET_CLOUD_PROVIDER_USER_MAP';

//BOARD
export const SET_BOARD = 'SET_BOARD';
export const SET_MULTIPLE_BOARD = 'SET_MULTIPLE_BOARD';
export const RESET_BOARD = 'RESET_BOARD';

//MY_PROFILE
export const SET_MY_PROFILE = 'SET_MY_PROFILE';
export const SET_MULTIPLE_MY_PROFILE = 'SET_MULTIPLE_MY_PROFILE';
export const RESET_MY_PROFILE = 'RESET_MY_PROFILE';

//PREFERENCES
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_MULTIPLE_PREFERENCES = 'SET_MULTIPLE_PREFERENCES';
export const RESET_PREFERENCES = 'RESET_PREFERENCES';

//NOTIFICATION
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_MULTIPLE_NOTIFICATION = 'SET_MULTIPLE_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

//NOTIFICATION
export const SET_TIMING_WINDOW = 'SET_TIMING_WINDOW';
export const SET_MULTIPLE_TIMING_WINDOW = 'SET_MULTIPLE_TIMING_WINDOW';
export const RESET_TIMING_WINDOW = 'RESET_TIMING_WINDOW';

//TIMEZONE
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_MULTIPLE_TIMEZONE = 'SET_MULTIPLE_TIMEZONE';
export const RESET_TIMEZONE = 'RESET_TIMEZONE';

//CHAT
export const SET_CHAT = 'SET_CHAT';
export const SET_MULTIPLE_CHAT = 'SET_MULTIPLE_CHAT';
export const RESET_CHAT = 'RESET_CHAT';

//CHAT
export const SET_CHAT_CHANNEL = 'SET_CHAT_CHANNEL';
export const SET_MULTIPLE_CHAT_CHANNEL = 'SET_MULTIPLE_CHAT_CHANNEL';
export const RESET_CHAT_CHANNEL = 'RESET_CHAT_CHANNEL';

//CHAT
export const SET_THREAD_CHAT = 'SET_THREAD_CHAT';
export const SET_MULTIPLE_THREAD_CHAT = 'SET_MULTIPLE_THREAD_CHAT';
export const RESET_THREAD_CHAT = 'RESET_THREAD_CHAT';

//ANALYTICS
export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_MULTIPLE_ANALYTICS = 'SET_MULTIPLE_ANALYTICS';
export const RESET_ANALYTICS = 'RESET_ANALYTICS';

//EXPENSE_MANAGE
export const SET_EXPENSE_MANAGE = 'SET_EXPENSE_MANAGE';
export const SET_MULTIPLE_EXPENSE_MANAGE = 'SET_MULTIPLE_EXPENSE_MANAGE';
export const RESET_EXPENSE_MANAGE = 'RESET_EXPENSE_MANAGE';

//EXPENSE_MANAGE
export const SET_APP_MASTER = 'SET_APP_MASTER';
export const SET_MULTIPLE_APP_MASTER = 'SET_MULTIPLE_APP_MASTER';
export const RESET_APP_MASTER = 'RESET_APP_MASTER';


//SUBJECT
export const SET_SUBJECT = 'SET_SUBJECT';
export const SET_MULTIPLE_SUBJECT = 'SET_MULTIPLE_SUBJECT';
export const RESET_SUBJECT = 'RESET_SUBJECT';

//CLASSROOM
export const SET_CLASSROOM = 'SET_CLASSROOM';
export const SET_MULTIPLE_CLASSROOM = 'SET_MULTIPLE_CLASSROOM';
export const RESET_CLASSROOM = 'RESET_CLASSROOM';

//EDUCATIONTASK
export const SET_EDUCATION_TASK = 'SET_EDUCATION_TASK';
export const SET_EDUCATION_MULTIPLE_TASK = 'SET_EDUCATION_MULTIPLE_TASK';
export const RESET_EDUCATION_TASK = 'RESET_EDUCATION_TASK';


//EDUCATIONWORKSPACE
export const SET_EDUCATION_WORKSPACE = 'SET_EDUCATION_WORKSPACE';
export const SET_EDUCATION_MULTIPLE_WORKSPACE = 'SET_EDUCATION_MULTIPLE_WORKSPACE';
export const RESET_EDUCATION_WORKSPACE = 'RESET_EDUCATION_WORKSPACE';

//EDUCATION ENTRY REPORT
export const SET_EDUCATION_ENTRY_REPORT = 'SET_EDUCATION_ENTRY_REPORT';
export const SET_EDUCATION_MULTIPLE_ENTRY_REPORT = 'SET_EDUCATION_MULTIPLE_ENTRY_REPORT';
export const RESET_EDUCATION_ENTRY_REPORT = 'RESET_EDUCATION_ENTRY_REPORT';


//EDUCATION BOARD
export const SET_EDUCATION_BOARD = 'SET_EDUCATION_BOARD';
export const SET_EDUCATION_MULTIPLE_BOARD = 'SET_EDUCATION_MULTIPLE_BOARD';
export const RESET_EDUCATION_BOARD = 'RESET_EDUCATION_BOARD';

//EDUCATION BOARD_STATUS_TYPE
export const SET_EDUCATION_BOARD_STATUS_TYPE = 'SET_EDUCATION_BOARD_STATUS_TYPE';
export const SET_MULTIPLE_EDUCATION_BOARD_STATUS_TYPE = 'SET_MULTIPLE_EDUCATION_BOARD_STATUS_TYPE';
export const RESET_EDUCATION_BOARD_STATUS_TYPE = 'RESET_EDUCATION_BOARD_STATUS_TYPE';

//EDUCATION EXPENSES
export const SET_EDUCATION_EXPENSES = 'SET_EDUCATION_EXPENSES';
export const SET_MULTIPLE_EDUCATION_EXPENSES = 'SET_MULTIPLE_EDUCATION_EXPENSES';
export const RESET_EDUCATION_EXPENSES = 'RESET_EDUCATION_EXPENSES';

//EDUCATION EXPENSE_MANAGE
export const SET_EDUCATION_EXPENSE_MANAGE = 'SET_EDUCATION_EXPENSE_MANAGE';
export const SET_MULTIPLE_EDUCATION_EXPENSE_MANAGE = 'SET_MULTIPLE_EDUCATION_EXPENSE_MANAGE';
export const RESET_EDUCATION_EXPENSE_MANAGE = 'RESET_EDUCATION_EXPENSE_MANAGE';

//EDUCATION EXPENSES_CATEGORY
export const SET_EDUCATION_EXPENSES_CATEGORY = 'SET_EDUCATION_EXPENSES_CATEGORY';
export const SET_MULTIPLE_EDUCATION_EXPENSES_CATEGORY = 'SET_MULTIPLE_EDUCATION_EXPENSES_CATEGORY';
export const RESET_EDUCATION_EXPENSES_CATEGORY = 'RESET_EXPENSES_CATERESET_EDUCATION_EXPENSES_CATEGORYGORY';

//EDUCATION_CSV_FILE_HISTORY
export const SET_EDUCATION_CSV_FILE_HISTORY = 'SET_EDUCATION_CSV_FILE_HISTORY';
export const SET_MULTIPLE_EDUCATION_CSV_FILE_HISTORY = 'SET_MULTIPLE_EDUCATION_CSV_FILE_HISTORY';
export const RESET_EDUCATION_CSV_FILE_HISTORY = 'RESET_EDUCATION_CSV_FILE_HISTORY';

//EDUCATION EXPENSES REPORTS
export const SET_EDUCATION_EXPENSE_REPORTS = 'SET_EDUCATION_EXPENSE_REPORTS';
export const SET_MULTIPLE_EDUCATION_EXPENSE_REPORTS = 'SET_MULTIPLE_EDUCATION_EXPENSE_REPORTS';
export const RESET_EDUCATION_EXPENSE_REPORTS = 'RESET_EDUCATION_EXPENSE_REPORTS';

//EDUCATION REPORT
export const SET_EDUCATION_REPORT = 'SET_EDUCATION_REPORT';
export const SET_MULTIPLE_EDUCATION_REPORT = 'SET_MULTIPLE_EDUCATION_REPORT';
export const RESET_EDUCATION_REPORT = 'RESET_EDUCATION_REPORT';

//FAQ
export const SET_FAQ = 'SET_FAQ';
export const SET_MULTIPLE_FAQ = 'SET_MULTIPLE_FAQ';
export const RESET_FAQ = 'RESET_FAQ';


//EDUCATION APPS
export const SET_EDUCATION_APPS = 'SET_EDUCATION_APPS';
export const SET_MULTIPLE_EDUCATION_APPS = 'SET_MULTIPLE_EDUCATION_APPS';
export const RESET_EDUCATION_APPS = 'RESET_EDUCATION_APPS';

//NOTES
export const SET_NOTES = 'SET_NOTES';
export const SET_MULTIPLE_NOTES = 'SET_MULTIPLE_NOTES';
export const RESET_NOTES = 'RESET_NOTES';

//VERIFY_ACCCOUNT
export const SET_VERIFY_ACCCOUNT = 'SET_VERIFY_ACCCOUNT';
export const SET_MULTIPLE_VERIFY_ACCCOUNT = 'SET_MULTIPLE_VERIFY_ACCCOUNT';
export const RESET_VERIFY_ACCCOUNT = 'RESET_VERIFY_ACCCOUNT';

//HIDDEN_CHANNELS
export const SET_HIDDEN_CHANNELS = 'SET_HIDDEN_CHANNELS';
export const SET_MULTIPLE_HIDDEN_CHANNELS = 'SET_MULTIPLE_HIDDEN _CHANNELS';
export const RESET_HIDDEN_CHANNELS = 'RESET_HIDDEN _CHANNELS';

// USER_SESSION_REPORT
export const SET_USER_SESSION_REPORT = 'SET_USER_SESSION_REPORT'
export const SET_MULTIPLE_USER_SESSION_REPORT = 'SET_MULTIPLE__USER_SESSION_REPORT';
export const RESET_USER_SESSION_REPORT = 'RESET_USER_SESSION_REPORT'


//Calendly
//Guest
export const SET_MULTIPLE_CALENDLY_GUEST = 'SET_MULTIPLE_CALENDLY_GUEST';
export const RESET_CALENDLY_GUEST = 'RESET_CALENDLY_GUEST'
//Event
export const SET_MULTIPLE_CALENDLY_EVENT = 'SET_MULTIPLE_CALENDLY_EVENT';
export const RESET_CALENDLY_EVENT = 'RESET_CALENDLY_EVENT'

//USER LEAVE REQUEST
export const SET_USER_LEAVE_REQUEST = 'SET_USER_LEAVE_REQUEST';
export const SET_MULTIPLE_USER_LEAVE_REQUEST = 'SET_MULTIPLE_USER_LEAVE_REQUEST';
export const RESET_USER_LEAVE_REQUEST = 'RESET_USER_LEAVE_REQUEST';

//USER_IP_HISTORY
export const SET_USER_IP_HISTORY = 'SET_USER_IP_HISTORY';
export const SET_MULTIPLE_USER_IP_HISTORY = 'SET_MULTIPLE_USER_IP_HISTORY';
export const RESET_USER_IP_HISTORY = 'RESET_USER_IP_HISTORY';
export const REMOVE_USER_IP_HISTORY = 'REMOVE_USER_IP_HISTORY';

//SERVICE_SUB_TYPE
export const SET_SUB_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_SUB_MULTIPLE_SERVICE_TYPE = 'SET_MULTIPLE_SERVICE_TYPE';
export const RESET_SUB_SERVICE_TYPE = 'RESET_SERVICE_TYPE';

//DONATION
export const SET_DONATION = 'SET_DONATION';
export const SET_MULTIPLE_DONATION = 'SET_MULTIPLE_DONATION';
export const RESET_DONATION = 'RESET_DONATION';


//LANG
export const SET_LANG = 'SET_LANG';
export const SET_MULTIPLE_LANG = 'SET_MULTIPLE_LANG';
export const RESET_LANG = 'RESET_LANG';


//ROLE REGISTRY
export const SET_ROLE_REGISTRY = 'SET_ROLE_REGISTRY';
export const SET_MULTIPLE_ROLE_REGISTRY = 'SET_MULTIPLE_ROLE_REGISTRY';
export const RESET_ROLE_REGISTRY = 'RESET_ROLE_REGISTRY';
