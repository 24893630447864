import React from "react";
import Pagination from "./Pagination";
import { getGrpcClient, pageNumbers } from "../config/Common";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import { getPageText, getValidLabels, getUuidv4 } from '../config/Common';
import GlobalMsgCons from "../config/GlobalMsgCons";
import ReactTooltip from 'react-tooltip';

export default class Datatable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            pageLimit: 10,
            noOfPage: 1,
            pagination: [],
            prev: false,
            next: false,
            pageText: null,
            processing: true,
        }
    }

    componentDidMount() {
        this.getList()
        this.getPageText()
        ReactTooltip.rebuild();
    }

    componentDidUpdate(prevProps) {
        if (this.props.domain !== prevProps.domain || this.props.event !== prevProps.event || this.props.refresh !== prevProps.refresh) {
            this.setState({
                noOfPage: 1
            }, () => {
                this.getList()
            })
        }
    }
    async getPageText() {
        let pageText = await getPageText('manage');
        this.setState({ pageText: pageText })
    }

    sortByKey(obj, orderKey) {
        let sortRes = {}
        Object.keys(obj).map((key, index) => {
            return sortRes[key] = obj[key][orderKey];
        });
        let keysSorted = Object.keys(sortRes).sort(function (a, b) { return sortRes[b] - sortRes[a] })

        return keysSorted;
    }

    getList() {
        let { domain, event, data, postJsonInfo, sortBy } = this.props;
        if (typeof domain !== "undefined" && typeof event !== "undefined") {
            postJsonInfo = postJsonInfo !== undefined ? postJsonInfo : {}
            let { pageLimit, noOfPage } = this.state;
            let postJson = { sessionId: '123', reqId: getUuidv4(), };
            postJson = { ...postJson, ...postJsonInfo }

            const request = new ZiFetchRequest();
            request.setDomain(domain);
            request.setEvent(event);
            request.setMessage(JSON.stringify(postJson));
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let value = [];
                    let result = responseData.response;
                    if (!result) {
                        result = responseData.result
                    }

                    if (this.props.setResponse) {
                        this.props.setResponse(responseData)
                    }

                    let keysSorted = Object.keys(result)
                    if (sortBy !== undefined) {
                        keysSorted = this.sortByKey(result, sortBy);
                    }
                    keysSorted.map((key, index) => {
                        let keyData = result[key];
                        value[index] = [];
                        data.map((column, columnIndex) => {
                            let input = "";
                            if (typeof column.selector !== "undefined") {
                                let value = keyData;
                                if (column.selector.includes(".")) {
                                    column.selector.split('.').forEach(function (item) {
                                        value = value[item]
                                    })
                                } else {
                                    value = keyData[column.selector]
                                }
                                input = value;
                            } else if (typeof column.cell !== "undefined") {
                                input = column.cell(keyData, key)
                            }
                            value[index][columnIndex] = input;
                        })
                    })


                    let totalResult = Object.keys(result).length
                    let pageCount = Math.ceil(totalResult / pageLimit);
                    let pagination = pageNumbers(pageCount, noOfPage, 5);
                    this.setState({
                        value,
                        pagination,
                        processing: false,
                    })
                }
            });
        }
    }

    movePage(page) {
        let pageNo = page
        if (page === "next") {
            pageNo = this.state.noOfPage + 1;
        } else if (page === "prev") {
            pageNo = this.state.noOfPage - 1;
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noOfPage: pageNo,
                processing: true,
            }, () => {
                this.getList()
            })
        }
    }

    render() {
        let { data } = this.props;
        let { value, pagination, next, prev, noOfPage, pageLimit, pageText, processing } = this.state;

        let endIndex = noOfPage * pageLimit;
        let startIndex = endIndex - pageLimit;

        return <div>
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            <div className={`table-responsive ${processing && "table-processing"}`}>
                {processing && <div className="processing"><span>Processing...</span></div>}
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {
                                data.map((row, index) => {
                                    return <th key={index} data-tip={row.toolTip}>{row.column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            value.length > 0 ? value.map((row, index) => {
                                return (index >= startIndex && index < endIndex) && <tr key={index}>
                                    {
                                        data.map((column, columnIndex) => {
                                            return <td key={columnIndex}>{value[index][columnIndex]}</td>
                                        })
                                    }
                                </tr>
                            }) : <tr>
                                <td className="text-center" colSpan={data.length}>{processing ? "Processing" : getValidLabels(pageText, 'noRecordsFoundTxt')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pagination={pagination}
                currentPage={noOfPage}
                prev={noOfPage > 1}
                next={(noOfPage * pageLimit) < value.length}
                movePage={(type) => {
                    this.movePage(type)
                }} />
        </div>;
    }
}