import React, { useState } from "react";
import CardToggle from "../../../components/CardToggle";
import JSONViewer from "../../../components/JSONViewer";

export default function CaptureAITaskBlockView(props) {
    let { viewBlockDetails } = props
    let { aiAgentFlow } = viewBlockDetails
    let { typeOfSource, prompt, http, sqlite, toolCall } = aiAgentFlow
    const [toggle, setToggle] = useState(false);

    return <div className="mb-2">

        <CardToggle
            title={"AI Agent Setup"}
            toggleMenuOpen={toggle} toggleOnchange={() => {
                setToggle(!toggle)
            }} >

            <div className='form-group'>
                <label>Type of Source</label>
                <div>{typeOfSource}</div>
            </div>
            {typeOfSource === 'HTTP' && (<React.Fragment>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='form-group'>
                            <label className="form-label custom">Method</label>
                            <div>
                                {http.method}
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='form-group'>
                            <label className="form-label custom">API URL</label>
                            <div>{http.apiURL}</div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='form-group'>
                            <label className="form-label custom">Authorization</label>
                            <div>{http.authorization}</div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='form-group'>
                            <label className="form-label custom">Def JSON</label>
                            <JSONViewer title={"Def JSON"} json={http.defJson} />
                        </div>
                    </div>
                </div>
            </React.Fragment>)}

            <div className='row'>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label className="form-label custom">Agent Name</label>
                        <div>{toolCall.function.name}</div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label className="form-label custom">Description</label>
                        <div>{toolCall.function.description}</div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label className="form-label custom">Prompt Template</label>
                        <div>{prompt}</div>
                    </div>
                </div>
            </div>
            <h5>Parameters</h5>
            {
                Object.keys(toolCall.function.parameters.properties).map((name, index) => {
                    return <div className="row" key={index}>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="form-label custom">Name</label>
                                <div>{name}</div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="form-label custom">Description</label>
                                <div>{toolCall.function.parameters.properties[name].description}</div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label className="form-label custom">Required</label>
                                <div>{toolCall.function.parameters.required.includes(name) ? "Yes" : "No"}</div>
                            </div>
                        </div>
                    </div>
                })
            }
        </CardToggle>
    </div>
}
