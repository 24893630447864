import React from "react";

export default class Loader extends React.Component {
    render() {
        let { message } = this.props;
        return <div className='loader-comp'>
            <div className="spinner">
                <div className="ball"></div>
                {
                 message ? message : <div className="message">
                        LOADING
                    </div>
                }
            </div>
        </div>;
    }
}
