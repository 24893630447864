import React, { useState } from 'react';
import { toast } from 'react-toastify';

const CopyUrlButton = (props) => {
    let { className } = props;
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            setIsCopied(true);
            toast(`Copied Successfully.`, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
        });
    };

    return <React.Fragment>
        <button
            onClick={handleCopy}
            className={`btn btn-sm copy-button ${isCopied ? 'copied' : ''} ${className}`}
        >
            <i className={`fas fa-${isCopied ? "check" : "copy"}`}></i>
        </button>
    </React.Fragment>
};

export default CopyUrlButton;
