import React from 'react';

const ContextMenu = ({ xPos, yPos, items, onClose }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: yPos,//yPos
                left: xPos,//xPos
                backgroundColor: 'white',
                border: '11px solid transparent',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                padding: '5px',
                borderRadius: "6px",
                boxShadow: "0 0 2px 0 rgba(34,47,62,.2),0 4px 8px 0 rgba(34,47,62,.15)",
                border: "none"
            }}
            onClick={onClose} // Close the menu when any item is clicked
        >
            {items.map((item, index) => (
                <div key={index} className='context-menu-item' onClick={item.action}>
                    {item.label}
                </div>
            ))}
        </div>
    );
};

export default ContextMenu;

