import React, { useEffect, useState } from "react";
import openmrsLogo from '../assets/logo/openmrsLogo.png'

const CustomModal = (props) => {
    let {
        visible,
        heading,
        body,
        footer,
        parentClass,
        size,
        bodyClassRemove,
        resizeEnable,
        closeModal,
        isOpenMrs
    } = props;

    const [resizeWindow, setResizeWindow] = useState(false);

    var isOpenMrsEnabled = false
    if (typeof (isOpenMrs) !== "undefined") {
        isOpenMrsEnabled = true
    }
    resizeEnable = resizeEnable ? resizeEnable : false


    function resizeModal() {
        setResizeWindow(!resizeWindow)
    }
    return (
        <React.Fragment>
            {visible ?
                <React.Fragment>
                    <div className={`custom-modal ${parentClass ? parentClass : ""}`} style={{ display: 'block' }}>
                        {
                            heading && <div className="modal-header theme-bg" style={isOpenMrsEnabled ? { backgroundColor: "#007D79" } : {}}>
                                <h5 className="modal-title">{heading}</h5>
                                {
                                    isOpenMrsEnabled && <img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 100, alignSelf: "center", marginLeft: 10 }} />
                                }
                                <div className="modal-close">
                                    {
                                        resizeEnable && <button type="button" className="resize" onClick={() => resizeModal()}>
                                            <i className={`fas fa-${resizeWindow ? "compress-alt" : "expand-alt"}`}></i>
                                        </button>
                                    }
                                    {
                                        typeof (closeModal) !== "undefined" && <button type="button" onClick={(e) => {
                                            e.stopPropagation()
                                            props.closeModal()
                                        }}>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                        }

                        <div className="modal-body" style={isOpenMrsEnabled ? { backgroundColor: "#F4F4F4" } : {}}>
                            {body}
                            <div className="modal-footer">
                                {footer}
                            </div>
                        </div>
                    </div>
                </React.Fragment> : null}
        </React.Fragment>
    );
};


export default CustomModal;