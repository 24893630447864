import React from "react";

export default function ProviderRegister(props) {
    let { providerInfo, errors, changeText } = props;
    let { providerQualification, providerOrgName, providerOrgCity, providerCountry } = providerInfo

    return <React.Fragment>
        <div className='row mx-0'>
            <div className='col-sm-6'>
                <div className="form-group">
                    <label>{"Qualification"}</label>
                    <input type="text" className={`form-control form-control-solid form-control-lg ${errors['providerQualification'] ? "is-invalid" : ""}`} placeholder={"Enter Qualification"} value={providerQualification} onChange={(e) => {
                        changeText("providerQualification", e.target.value)
                    }}
                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                </div>
            </div>
            <div className='col-sm-6'>
                <div className="form-group">
                    <label>{"Institution name"}</label>
                    <input type="text" className={`form-control form-control-solid form-control-lg ${errors['providerOrgName'] ? "is-invalid" : ""}`} placeholder={"Enter Institution name"} value={providerOrgName} onChange={(e) => {
                        changeText("providerOrgName", e.target.value)
                    }}
                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                </div>
            </div>
            <div className='col-sm-6'>
                <div className="form-group">
                    <label>{"Institution city"}</label>
                    <input type="text" className={`form-control form-control-solid form-control-lg ${errors['providerOrgCity'] ? "is-invalid" : ""}`} placeholder={"Enter Institution city"} value={providerOrgCity} onChange={(e) => {
                        changeText("providerOrgCity", e.target.value)
                    }}
                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                </div>
            </div>
            <div className='col-sm-6'>
                <div className="form-group">
                    <label>{"Institution country"}</label>
                    <input type="text" className={`form-control form-control-solid form-control-lg ${errors['providerCountry'] ? "is-invalid" : ""}`} placeholder={"Enter Institution country"} value={providerCountry} onChange={(e) => {
                        changeText("providerCountry", e.target.value)
                    }}
                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                </div>
            </div>
        </div>
    </React.Fragment>;
}
