import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { checkActiveAdmin, coversionED, getAppInfo, getChatPlatformTxt, getDateWithFormat, getGrpcClient, getOnlyDateWithFormat, getTabToken, getUuidv4, urlify } from "../../config/Common";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from "../../config/GlobalEventCons";
import GlobalMsgCons from "../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import CustomEditor from "../../components/CustomEditor";
import MyConstant from "../../config/MyConstant";

let dateArray = []
let lastMessage;
let color = {
    "A": "#673ab7",
    "B": "#3f51b5",
    "C": "#2196f3",
    "D": "#00bcd4",
    "E": "#009688",
    "F": "#4caf50",
    "G": "#8bc34a",
    "H": "#cddc39",
    "I": "#ffeb3b",
    "J": "#ffc107",
    "K": "#ff9800",
    "L": "#795548",
    "M": "#673ab7",
    "N": "#3f51b5",
    "O": "#2196f3",
    "P": "#00bcd4",
    "Q": "#009688",
    "R": "#4caf50",
    "S": "#8bc34a",
    "T": "#cddc39",
    "U": "#ffeb3b",
    "V": "#ffc107",
    "W": "#ff9800",
    "X": "#795548",
    "Y": "#9e9e9e",
    "Z": "#9e9e9e",
}

export default function GameChat(props) {
    let { pollChannel, channelId } = props;
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, sessionId, firstName, profilePhoto, activeCompanyDetails, hoursSelected, activeUserType, systemLevelRole } = auth;

    let [message, setMessage] = useState("")
    let [messageList, setMessageList] = useState({})
    let [currentResult, setCurrentResult] = useState(0)
    let [noOfPage, setNoOfPage] = useState(1)
    const messagesEndRef = useRef(null)


    useEffect(() => {
        dateArray = []
    }, [])
    useEffect(() => {
        getChannelChats(channelId, noOfPage, {})
        updateChannelUnReadCountIsEmpty()
    }, [channelId])

    useEffect(() => {
        getChannelMessagesFrequent(pollChannel)
    }, [pollChannel])

    function getChannelChats(channelId, noOfPage, data) {
        let postJson = { reqId: getUuidv4(), channelId, pageLimit: 25, noOfPage: noOfPage, memberId: loginId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChannelChatsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = { ...data };
                responseData.result.map((ival, i) => {
                    result[ival.chatMessage.createdAt] = ival;
                });

                setMessageList(result)
                setCurrentResult(responseData.totalResult)
                updateChatReadStatusAPI(result)

                if(noOfPage === 1) {
                    setTimeout(() => {
                        scrollBottom()
                    },100)
                } else {
                    document.getElementById(Object.keys(result)[((noOfPage - 1) * 25)]).scrollIntoView();
                }
            }
        });
    }

    function updateChatReadStatusAPI(messageList) {
        let filterNotReadUsers = Object.values(messageList).filter((e) => {
            return e.readStatus === false
        })
        let filterMsdIds = []
        if (Array.isArray(filterNotReadUsers)) {
            filterMsdIds = filterNotReadUsers.map((e) => { return e.chatMessage.id })
        }
        if (Array.isArray(filterMsdIds) && filterMsdIds.length > 0) {
            let postJson = { reqId: getUuidv4(), messageIdList: filterMsdIds, channelId, memberId: loginId };
            //console.log("postJson", postJson)
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.updateChatReadStatusSetEvent);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {

                }
            });
        }
    }
    function getChannelMessagesFrequent(responseData) {
        let { listOfMessageTypesWaiting } = responseData
        if (listOfMessageTypesWaiting !== undefined) {
            listOfMessageTypesWaiting.map((item) => {
                if (item === "MsgArrived") {
                    getChannelChatsNew()
                    updateChannelUnReadCountIsEmpty()
                }
            })
        }
    }

    function getChannelChatsNew() {
        let postJson = { reqId: getUuidv4(), 
            sessionTabId: getTabToken(),
            sessionId, channelId, userId: loginId, pageLimit: 25, noOfPage: 1 };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelChatViaListenerSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)

                if (responseData.channelId === channelId) {
                    let { newMsgs } = responseData

                    let result = {...messageList};
                    if (newMsgs.length > 0) {
                        for (let i = 0; i < newMsgs.length; i++) {
                            let ival = newMsgs[i]
                            result[ival.chatMessage.createdAt] = ival;
                            if (result[ival.chatMessage.sendAt] && ival.chatMessage.createdAt !== ival.chatMessage.sendAt) {
                                delete result[ival.chatMessage.sendAt]
                            }
                        }
                        setMessageList(result)
                        scrollBottom()
                    }
                }
            }

        });
    }

    function updateChannelUnReadCountIsEmpty() {
        let postJson = { reqId: getUuidv4(), userId: loginId, channelId, orgId: activeCompany };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChannelUnReadCountIsEmptySE);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                // console.log("Updated")
            }
        });
    }


    function addChannelChat() {
        setMessage("")
        let createdAt = new Date();
        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();

        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType: "",
                fileList: [],
                objUrl: "",
                deviceInfo,
                aiAgentType: ""
            },
            name: firstName,
            userPhoto: profilePhoto,
            tempMessage: true,
            tempMessageStatus: "loading"
        }

        let cloneList = { ...messageList }
        cloneList[createdAt.getTime()] = tempMessage
        setMessageList(cloneList)
        scrollBottom()
        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: activeCompany,
            senderId: loginId,
            message,
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType: "game",
            deviceInfo,
            aiAgentType: "",
            userType: GlobalMsgCons.type_user,
        };
        postJson = coversionED("encrypt", postJson).toString()

        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
                cloneList[createdAt.getTime()].tempMessageStatus = "failed"
                setMessageList(cloneList)
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {
                    cloneList[createdAt.getTime()].tempMessageStatus = ""
                    setMessageList(cloneList)
                }
            }
        });
    }

    function scrollBottom() {
        if(messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
        }
    }

    function timeStampToDate(d) {
        return getOnlyDateWithFormat(d, activeCompanyDetails)
    }

    function checkUserDetailsDisplay(chatMessage, index, type) {
        let nameDisplay = false
        if (lastMessage !== undefined && (type !== "live" || index > 0)) {
            let diff = chatMessage.createdAt - lastMessage.sendAt
            if ((lastMessage.userId === chatMessage.userId && diff < 60000 && lastMessage.deviceInfo === chatMessage.deviceInfo)) {
                nameDisplay = false
            } else {
                nameDisplay = true
            }
        } else {
            nameDisplay = true
        }

        lastMessage = chatMessage

        return nameDisplay;
    }

    function humanDateFormat(createdAt) {
        return getDateWithFormat(createdAt, activeCompanyDetails, hoursSelected)
    }

    function loadMore() {
        let pageno = noOfPage + 1
        setNoOfPage(pageno)
        getChannelChats(channelId, pageno, messageList);
    }

    return <div className="game-chat">
        <div className="game-chat-header">
            Chat
        </div>
        <div className="game-chat-body" ref={messagesEndRef}>
            {
                (currentResult > 0 && currentResult > Object.keys(messageList).length) ? <div className="load-more-message" onClick={() => loadMore()}><span>{"Load More Messages"}</span></div> : null
            }
            {
                Object.keys(messageList).sort().map(function (ival, i) {
                    var showLine = false
                    var datetime = timeStampToDate(new Date(parseInt(ival)))
                    if (!dateArray.includes(datetime)) {
                        dateArray.push(datetime)
                        showLine = true
                    }
                    var dateView = datetime
                    var datetime_2 = timeStampToDate(new Date())

                    if (datetime_2 === datetime) {
                        dateView = "Today"
                    }
                    let { chatMessage, tempMessageStatus, name: userName, userPhoto: profilePhoto } = messageList[ival];
                    let userDetailsDisplay = checkUserDetailsDisplay(chatMessage, i, "live")
                    let userProfilePhoto = ""
                    if (profilePhoto) {
                        let split = profilePhoto.split(".")
                        userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                    }

                    let { deviceInfo } = chatMessage;
                    deviceInfo = deviceInfo ? JSON.parse(deviceInfo) : ""

                    let message = <div id={ival} className="">
                        <div dangerouslySetInnerHTML={{
                            __html: urlify(chatMessage.message)
                        }}
                        />
                    </div>
                    return <div key={i} id={ival}>
                        {showLine && <div className="" style={{ padding: 2, marginBottom: 5, marginTop: 5 }}>
                            <div className="date-bg-line" style={{}} >
                                <span className="date-bg-color">{dateView}</span>
                            </div>
                        </div>
                        }
                        <div key={ival} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}>
                            {
                                userDetailsDisplay ? <div className="user-icon">
                                    {
                                        (userProfilePhoto && userProfilePhoto != "") ? <img className="name-bg" src={userProfilePhoto} alt="no img" /> : <div className="name-bg" style={{ backgroundColor: color[userName !== "" ? userName[0].toUpperCase() : "A"] }}>{userName !== "" ? userName[0].toUpperCase() : ""}</div>
                                    }
                                </div> : <>
                                    <div className="empty-user-icon">
                                    </div>
                                </>
                            }
                            <div className="message mx-2">
                                <div className="user-details">
                                    <div className="user">
                                        {
                                            userDetailsDisplay && <React.Fragment>
                                                <b className="mr-2 uppercase">{userName}</b>
                                                <span className="time-info">{humanDateFormat(chatMessage.createdAt)}</span>
                                                {
                                                    (checkActiveAdmin(activeUserType, systemLevelRole) && deviceInfo) && <span className="device-info">{getChatPlatformTxt(deviceInfo.appPlatform, {})}</span>
                                                }
                                                {
                                                    tempMessageStatus === "loading" && <span className="spinner-border spinner-border-sm text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </span>
                                                }
                                                {
                                                    tempMessageStatus === "failed" && <span style={{ color: "#E91E63" }}>
                                                        <i className="fas fa-exclamation-triangle"></i>
                                                    </span>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="message-box">
                                    {
                                        message
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
        <div className="game-chat-footer py-2">
            <div className="chat-input-container-element">
                <CustomEditor
                    title={""}
                    text={message}
                    changeText={message}
                    textOnchange={(e) => {
                        setMessage(e)
                    }}
                    submit={(e) => {
                        if (message.trim()) {
                            addChannelChat()
                        }
                    }}
                />
                <div className="chat-input-extra">
                    <div style={{ display: 'flex', flex: 1 }}>

                    </div>
                    <div className="d-flex">
                        <button className="message-submit-button reset" type="button" onClick={() => {
                            setMessage("")
                        }}><i className="fa fa-times"></i></button>
                        <button className="message-submit-button" type="submit" onClick={(e) => {
                            if (message.trim()) {
                                addChannelChat()
                            }
                        }}><i className="fa fa-paper-plane"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
