import React, { useState } from "react";
import FileView from "../../../components/FileView";
import MyConstant from "../../../config/MyConstant";
import Modal from "../../../components/Modal";

export default function ReportsFileView(props) {
    let { fileList, createdBy} = props;
    let [viewModal, setViewModal] = useState(false);
    return <React.Fragment>
        <button className="btn btn-sm btn-primary" onClick={() => setViewModal(true)}>View Files</button>
        {
            viewModal && <Modal
                visible={viewModal}
                closeModal={() => setViewModal(false)}
                heading={"View Files"}
                body={<>
                    <div className="row" >
                        {
                            fileList.map((fileStr, fileIndex) => {
                                let file = JSON.parse(fileStr)
                                let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.docsId + "." + file.fileType + "&id=" + createdBy
                              

                                return <div className="col-6 my-2 px-2" key={fileIndex} >
                                    <div style={{ border: "1px solid #ddd", textAlign: "center", background: "#9e9e9e" }} >
                                        {
                                            <div>
                                                <FileView bodyClassRemove={false} name="feedback preview" type={file.fileType} src={buildUrl} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            })


                        }
                    </div>
                    {Array.isArray(fileList) && fileList.length === 0 ? <div className="text-center">There are no files </div> : ""}
                </>
                }
            />
        }
    </React.Fragment>;
}
