import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { coversionED, getAppInfo, getGrpcClient, getTabToken, getUuidv4 } from "../../config/Common";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from "../../config/GlobalEventCons";
import GlobalMsgCons from "../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import R3FGame from "./R3FGame";

export default function GameTransfer(props) {
    let { pollChannel, channelId } = props;
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, sessionId, firstName } = auth;

    const [clients, setClients] = useState({})

    useEffect(() => {
        setClients([])
        updateChannelUnReadCountIsEmpty()
        getGameData()
    }, [channelId])

    useEffect(() => {
        getChannelMessagesFrequent(pollChannel)
    }, [pollChannel])

    function getChannelMessagesFrequent(responseData) {
        let { listOfMessageTypesWaiting } = responseData
        if (listOfMessageTypesWaiting !== undefined) {
            listOfMessageTypesWaiting.map((item) => {
                if (item === "MsgArrived") {
                    getChannelChatsNew()
                    updateChannelUnReadCountIsEmpty()
                }
            })
        }
    }

    function getChannelChatsNew() {
        let postJson = { reqId: getUuidv4(), 
            sessionTabId: getTabToken(),
            sessionId, channelId, userId: loginId, pageLimit: 25, noOfPage: 1 };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelChatViaListenerSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)

                if (responseData.channelId === channelId) {
                    let { newMsgs } = responseData

                    if (newMsgs.length > 0) {
                        let cloneClients = { ...clients }

                        for (let i = 0; i < newMsgs.length; i++) {
                            let ival = newMsgs[i]

                            let { chatMessage } = ival;
                            let { userId, gameData } = chatMessage
                            if (gameData) {
                                cloneClients[userId] = JSON.parse(gameData)
                            }
                        }
                        console.log("cloneClients", cloneClients)
                        setClients(cloneClients)
                    }
                }
            }

        });
    }

    function updateChannelUnReadCountIsEmpty() {
        let postJson = { reqId: getUuidv4(), userId: loginId, channelId, orgId: activeCompany };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChannelUnReadCountIsEmptySE);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                // console.log("Updated")
            }
        });
    }

    function getGameData() {
        let postJson = { reqId: getUuidv4(), gameId: channelId, orgId: activeCompany };
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.multiplayerGame);
        request.setEvent(GlobalEventCons.getGameData);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { result } = responseData
                if (Object.keys(result).length > 0) {
                    let cloneClients = []
                    for (let key in result) {
                        cloneClients[key] = JSON.parse(result[key])
                    }

                    if (!cloneClients.hasOwnProperty(loginId)) {
                        emptyGameSetup()
                    }
                    setClients(cloneClients)
                } else {
                    emptyGameSetup()
                }
            }
        });
    }

    function emptyGameSetup() {
        addChannelChat(JSON.stringify({
            "position": [
                0,
                0,
                0
            ],
            "rotation": [
                0,
                0,
                0
            ],
            firstName
        }))
    }

    function addChannelChat(gameData) {
        let createdAt = new Date();
        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();

        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: activeCompany,
            senderId: loginId,
            message: "",
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType: "game",
            deviceInfo,
            aiAgentType: "",
            userType: GlobalMsgCons.type_user,
            gameData
        };
        postJson = coversionED("encrypt", postJson).toString()

        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {

                }
            }
        });
    }

    return <R3FGame addChannelChat={addChannelChat} loginId={loginId} clients={clients} />;
}
