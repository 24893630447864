import React from "react";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { v4 as uuidv4 } from 'uuid';
import MyConstant from "../../../config/MyConstant";
import { toast } from 'react-toastify';
import Picker from 'emoji-picker-react';
import { Link, withRouter } from 'react-router-dom';
import CustomEditor from "../../../components/CustomEditor";
import Modal from "../../../components/Modal";
import { PDFViewer } from 'react-view-pdf';
import Recorder from "../../../components/Recorder";
import GifPicker from "../../../components/GifPicker";
import { getDateWithFormat, getGrpcClient, getPageText, getValidLabels, getUuidv4, urlify, getValidTooltip, checkMobileView, accumulation, commonEventList, getPageEventName, coversionED, equalEducation, getOnlyDateWithFormat } from "../../../config/Common";
import { setNotesData, setMultipleNotesData } from '../../../redux/actions';
import { connect } from 'react-redux';
import GlobalDomainCons from '../../../config/GlobalDomainCons';
import GlobalEventCons from '../../../config/GlobalEventCons';
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import ReactTooltip from 'react-tooltip';
import SharedMessageModal from '../../../components/SharedMessageModal';
import CustomEditorMinimized from "../../../components/CustomEditorMinimized";
import CustomQuillEditor from "../../../components/CustomQuillEditor";
const pageNameAcc = "notesPage"


// let intervalId;
// let intervalChannelId;
// let activeStatusIntervalId;



let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
let color = {
    "A": "#673ab7",
    "B": "#3f51b5",
    "C": "#2196f3",
    "D": "#00bcd4",
    "E": "#009688",
    "F": "#4caf50",
    "G": "#8bc34a",
    "H": "#cddc39",
    "I": "#ffeb3b",
    "J": "#ffc107",
    "K": "#ff9800",
    "L": "#795548",
    "M": "#673ab7",
    "N": "#3f51b5",
    "O": "#2196f3",
    "P": "#00bcd4",
    "Q": "#009688",
    "R": "#4caf50",
    "S": "#8bc34a",
    "T": "#cddc39",
    "U": "#ffeb3b",
    "V": "#ffc107",
    "W": "#ff9800",
    "X": "#795548",
    "Y": "#9e9e9e",
    "Z": "#9e9e9e",
}
let longPressEvent;

class Notes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: null,
            groupedChannelOptions: [
                {
                    label: "Channels",
                    options: []
                },
                {
                    label: "Direct Messages",
                    options: []
                },
                {
                    label: "Task Channels",
                    options: []
                },
            ]
        }
        this.saveAudio = this.saveAudio.bind(this);
        this.messageEnd = React.createRef();

    }

    componentDidMount() {
        let { userId, companyId } = this.props;
        accumulation(pageNameAcc, commonEventList("pageInitiated"), companyId, userId, this.props.grpcClient)
        ReactTooltip.rebuild();
        this.getPageText()
        let { dataFetched } = this.props
        let currentChatText = localStorage.getItem("currentChatText_" + userId);
        let message = currentChatText ? currentChatText : "";
        this.props.setMultipleNotesData({
            channelId: userId,
            message,
            emojiPicker: false,
            fileList: [],
            isRender: true,
        })

        if (!dataFetched) {
            this.getNotesList(1, true, {})
        } else {
            let { userChatHistory } = this.props;
            this.getNotesList(1, true, userChatHistory)
            this.scrollBottom();
        }

        this.getChannelList()

    }

    getChannelList() {
        let { companyType } = this.props
        this.getGroupChannelList()
        this.getDirectChannelList()
        if (equalEducation(companyType)) {
            this.getEducationTaskChannelList()
        } else {
            this.getWorkTaskChannelList()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.notesChannelUpdated !== prevProps.notesChannelUpdated) {
            let { userChatHistory } = this.props;
            this.getNotesList(1, true, userChatHistory)
        }
        if (this.props.languagePreference !== prevProps.languagePreference) {
            this.getPageText()
        }
    }
    async getPageText() {
        let pageText = await getPageText('chat');
        this.setState({ pageText: pageText })
    }

    saveAudio(type, blob) {
        let uid = uuidv4();
        let audioFile = new File([blob], "record_" + uid + ".mp3")
        let fileUrl = URL.createObjectURL(audioFile);
        let file = audioFile;
        let filename = audioFile.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        let fileObj = {
            file: file,
            origFileName: filename,
            fileUrl: fileUrl,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
            fileType: ext,
        };

        let fileList = [];
        let inc = 0;
        fileList[inc] = fileObj;
        fileList[inc].uploadProcess = "pending";

        this.props.setMultipleNotesData({
            fileList,
            recordingModal: false
        })
        inc++;
    }



    async editChannelSubmit(event, gifURL) {
        if(event) {
            event.preventDefault();
        }
        let { channelId, fileList, loginId, editMessageText, editMessageId, userChatHistory, editMessageDetails, setEmptyText, companyId } = this.props;

        if ((editMessageText.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }

            if (gifURL !== "") {
                editMessageText = "";
                fileList = [];
            }
            let postJson = {
                reqId: getUuidv4(),
                notesId: editMessageId,
                channelId,
                userId: loginId,
                orgId: companyId,
                message: editMessageText,
                editType: "chat",
                callData: "",
                fileList: fileList,
                objUrl: gifURL,
            };

            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.editNotes);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        editMessageDetails.message = editMessageText;
                        editMessageDetails.fileList = fileList;
                        userChatHistory[editMessageDetails.sendAt].chatMessage = editMessageDetails
                        this.props.setMultipleNotesData({
                            editMessageId: "",
                            editMessageText: "",
                            editMessageDetails: {},
                            userChatHistory,
                            setEmptyText: !setEmptyText,
                            emojiPicker: false,
                            fileList: [],
                            gifURL: "",
                            changeText: ""
                        })
                    }
                }
            });
        }
    }

    scrollBottom() {
        this.messageEnd.scrollTop = this.messageEnd.scrollHeight;
    }

    componentWillUnmount() {

    }

    setChatMessage(e) {
        let { channelId } = this.props;
        localStorage.setItem("currentChatText_" + channelId, e)
        this.props.setMultipleNotesData({
            message: e
        });
    }

    edit(details) {

        this.props.setMultipleNotesData({
            editMessageId: details.id,
            editMessageDetails: details,
            editMessageText: details.message,
            changeText: details.message,
            fileList: details.fileList,
            editBlink: true
        });

        setTimeout(() => {
            this.props.setMultipleNotesData({
                editBlink: false
            })
        }, 3000)
    }


    async addChannelChat(event, gifURL) {
        if(event) {
            event.preventDefault();
        }
        let { loginId, channelId, message, fileList, setEmptyText, companyId, tempMessageList, firstName, userChatHistory } = this.props;
        let msg = message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
        if ((msg.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            let createdAt = new Date();

            let tempMessage = {
                chatMessage: {
                    id: "",
                    userId: loginId,
                    message,
                    sendAt: createdAt.getTime(),
                    createdAt: createdAt.getTime(),
                    messageType: GlobalMsgCons.messageTypeChat,
                    fileList: fileList,
                    objUrl: gifURL
                },
                "name": firstName
            }
            if (tempMessageList[channelId] === undefined) {
                tempMessageList[channelId] = [tempMessage]
            } else {
                tempMessageList[channelId].push(tempMessage)
            }

            await this.props.setMultipleNotesData({
                tempMessageList,
                message: "",
                fileList: [],
                emojiPicker: false,
                setEmptyText: !setEmptyText,
                scrollBottom: true,
                gifURL: ""
            })
            this.scrollBottom()

            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }


            let postJson = {
                reqId: getUuidv4(),
                channelId,
                orgId: companyId,
                senderId: loginId,
                message,
                fileList: fileList,
                sendAt: createdAt.getTime(),
                objUrl: gifURL,
                messageType: GlobalMsgCons.messageTypeChat
            };

            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.addNotes);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(createdAt.toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        this.getNotesList(1, true, userChatHistory)
                    }
                }
            });
        }
    }

    getNotesList(noOfPage, scrollBottom, userChatHistory) {
        let { loginId, companyId } = this.props;
        let postJson = {
            reqId: getUuidv4(),
            orgId: companyId,
            userId: loginId,
            pageLimit: 10,
            noOfPage
        };
        let createdAt = new Date();
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.getUserNotesList);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let result = userChatHistory;
                responseData.result.map((ival, i) => {
                    result[ival.sendAt] = ival;
                });

                this.props.setMultipleNotesData({
                    userChatHistory: result,
                    currentResult: responseData.totalResult,
                    tempMessageList: {},
                    dataFetched: true
                })

                if (scrollBottom) {
                    this.scrollBottom();
                } else if (Object.keys(result)[((noOfPage - 1) * 10)]) {
                    //focus div
                    document.getElementById(Object.keys(result)[((noOfPage - 1) * 10)]).scrollIntoView();
                }
            }
        });
    }

    async fileChange(event) {
        let { pageText } = this.state
        let files = event.target.files;
        let length = files.length;
        let validation = true;
        let fileList = [];
        if (length > 0) {
            let inc = fileList.length;
            for (var i = 0; i < length; i++) {
                let fileUrl = URL.createObjectURL(files[i]);
                let file = files[i];
                let filename = file.name;
                let valid = this.isValidFileExt(filename);
                if (!valid) {
                    validation = false;
                }
                let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                let uid = uuidv4();
                let fileObj = {
                    file: file,
                    origFileName: filename,
                    fileUrl: fileUrl,
                    fileName: uid + "." + ext,
                    docsId: uid,
                    processType: "chat",
                    fileType: ext
                };
                if (validation) {
                    fileList[inc] = fileObj;
                    fileList[inc].uploadProcess = "pending";

                    this.props.setMultipleNotesData({
                        fileList
                    })
                    inc++;
                } else {
                    toast.error(getValidLabels(pageText, 'fileNotValidErr_msg'), {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }
    }

    fileUpload(i, dataJson) {
        let { loginId } = this.props;
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.file);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId + "/" + loginId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }
    isValidFileExt(name) {
        const ext = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg', '.csv', '.pdf', '.xls', '.xlsx', '.mp3', '.mp4', '.3gp', '.ogg', '.mov','.webm',".json",".doc",".docs"];
        return ext.some(el => name.endsWith(el));
    }

    onEmojiClick(event, emojiObject) {
        let { insertTextToggle } = this.props
        this.props.setMultipleNotesData({
            insertTextToggle: !insertTextToggle,
            insertText: emojiObject.emoji
        })
    }


    humanDateFormat(createdAt) {
        let { activeCompanyDetails } = this.props;
        return getDateWithFormat(createdAt, activeCompanyDetails)
    }


    loadMore() {
        let { noOfPage, userChatHistory } = this.props;
        this.props.setMultipleNotesData({
            noOfPage: noOfPage + 1
        })
        this.getNotesList((noOfPage + 1), false, userChatHistory);
    }


    previewContent(buildUrl) {
        let content;
        if (buildUrl !== undefined) {
            var re = /(?:\.([^.]+))?$/;
            let url = new URL(buildUrl)
            let params = new URLSearchParams(url.search);
            let fileType = re.exec(params.get('key'))[1];
            if (url && url.host === "media.tenor.com" && !fileType) {
                let ext = buildUrl.slice((buildUrl.lastIndexOf(".") - 1 >>> 0) + 2);
                fileType = ext
            }
            if (fileType) {
                let images = ["jpg", "png", "jpeg", "gif"];
                let videos = ["mp4", "3gp", "ogg", "mov","webm"]
                let pdf = ["pdf"]
                let txt = ["txt"]
                let audio = ["mp3", "wav"]
                let docs = ["doc","docs","json"]
                let fileContent = "";
                if (images.includes(fileType.toLowerCase())) {
                    fileContent = <img src={buildUrl} className="img-fluid" />
                } else if (videos.includes(fileType.toLowerCase())) {
                    fileContent = <video width="100%" controls>
                        <source src={buildUrl} />
                    </video>
                } else if (pdf.includes(fileType.toLowerCase())) {
                    fileContent = <PDFViewer url={buildUrl} />
                } else if (txt.includes(fileType.toLowerCase())) {
                    fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={buildUrl} height="75vh" width="100%" />
                } else if (audio.includes(fileType.toLowerCase())) {
                    fileContent = <audio width="100%" controls>
                        <source src={buildUrl} />
                    </audio>
                } else {
                    fileContent = <div>
                        <div className="text-right">
                            <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                                <i className="fa fa-download"></i>
                            </button>
                        </div>
                        Not able to preview.
                    </div>
                }

                content = <React.Fragment>
                    <div style={{ textAlign: "center" }} >
                        {fileContent}
                    </div>
                </React.Fragment>
            }
        }

        return content;
    }

    downloadUrl(buildUrl) {
        var link = document.createElement("a");
        link.download = "feedback.xls";
        link.href = buildUrl;
        link.click();
    }


    fileIcon(buildUrl, fileType) {
        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov","webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let docs = ["doc","docs",]
        let json = ["json"]

        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={buildUrl} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-video" />
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-pdf" />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-alt" />
        } 
        else if (docs.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-word" />
        }
        else if(json.includes(fileType.toLowerCase())){
            fileContent = <i className="far fa-file-alt" />
        }
        else {
            fileContent = <i className="fa fa-file" />
        }

        return fileContent;
    }

    previewBlobContent() {
        let { previewBlob } = this.props;
        let { filePath, fileType } = previewBlob;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov","webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3", "wav"]
        let excel = ["csv", "xls", "xlsx"]
        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={filePath} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <video width="100%" controls>
                <source src={filePath} />
            </video>
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <PDFViewer url={filePath} />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={filePath} height="75vh" width="100%" />
        } else if (audio.includes(fileType.toLowerCase())) {
            fileContent = <audio width="100%" controls>
                <source src={filePath} />
            </audio>
        }

        let content = <div style={{ textAlign: "center" }} >
            {fileContent}
        </div>

        return content;
    }

    fileDelete(e, i) {
        e.stopPropagation();
        let { fileList } = this.props;
        fileList.splice(i, 1);

        this.props.setMultipleNotesData({
            fileList
        })
    }

    toggleInfo() {
        let { infoShow } = this.props;
        this.props.setMultipleNotesData({
            infoShow: !infoShow
        })
    }

    getGroupChannelList() {
        let { groupedChannelOptions } = this.state;
        let { userId, companyId, } = this.props;
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        // console.log("-->postJson getChannelList", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let channelList = [];
                let channelOption = [];
                if (responseData && responseData.result) {
                    let groupChannelData = responseData.result
                    groupChannelData.map((ival, i) => {
                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "channel"
                        });

                    })

                }
                groupedChannelOptions[0].options = channelOption
                this.setState({
                    groupedChannelOptions
                })
            }
        })
    }


    getDirectChannelList() {
        let { groupedChannelOptions } = this.state;
        let { userId, companyId, } = this.props;
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        postJson = coversionED("encrypt", postJson).toString()
        // console.log("-->postJson getDirectChannelList", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.directChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let channelOption = [];
                if (responseData && responseData.result) {
                    let directChannelData = responseData.result
                    directChannelData.map((ival, i) => {
                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "direct"
                        });
                    })
                }
                groupedChannelOptions[1].options = channelOption
                this.setState({
                    groupedChannelOptions
                })
            }
        })
    }

    getWorkTaskChannelList() {
        let { groupedChannelOptions } = this.state;
        let { userId, companyId, } = this.props;
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        // console.log("-->postJson getWorkTaskChannelList", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.workTaskChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let channelOption = [];
                if (responseData && responseData.result) {
                    let taskChannelData = responseData.result
                    taskChannelData.map((ival, i) => {
                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "task"
                        });
                    })

                }
                groupedChannelOptions[2].options = channelOption
                this.setState({
                    groupedChannelOptions
                })
            }
        })
    }

    getEducationTaskChannelList() {
        let { groupedChannelOptions } = this.state;
        let { userId, companyId, } = this.props;
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId, };
        postJson = coversionED("encrypt", postJson).toString()
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.educationTaskChannelListSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                // console.log("responseData getEducationTaskChannelList",responseData)
                let channelOption = [];
                if (responseData && responseData.result) {
                    let taskChannelData = responseData.result
                    taskChannelData.map((ival, i) => {
                        channelOption.push({
                            label: ival.channelName,
                            value: ival.id,
                            msgType: "task"
                        });
                    })

                }
                groupedChannelOptions[2].options = channelOption
                this.setState({
                    groupedChannelOptions
                })
            }
        })
    }



    chatFileView(ival) {
        let { userChatHistory, channelId } = this.props;
        let message = userChatHistory[ival];
        let tempMessage = userChatHistory[ival].tempMessage;
        tempMessage = tempMessage !== undefined ? tempMessage : false;
        let fileList = message.fileList;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov","webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3", "wav"]
        let contentDiv = "";
        let showRowType = false;

        contentDiv = fileList.map((fileMap, fi) => {
            showRowType = false;
            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + fileMap.processType + "&key=" + fileMap.fileName + "&id=" + message.userId;
            if (tempMessage) {
                buildUrl = fileMap.fileUrl;
            }

            let downlodFileName = fileMap.origFileName;
            let downLoadUrl = MyConstant.keyList.apiURL + `download/${downlodFileName}?action=` + fileMap.processType + "&key=" + fileMap.fileName + "&id=" + message.userId;
            let fileType = fileMap.fileType;
            let preview = { filePath: buildUrl, fileType }
            let content = <div className="message-file">
                <div className="file" onClick={() => {
                    this.props.setMultipleNotesData({
                        buildUrl,
                        previewModal: true,
                        previewBlob: preview,
                        previewType: tempMessage ? "blob" : "",
                    })
                }}>
                    <i className="fa fa-file"></i>
                    {fileMap.processType === "chat-record" ? "" : fileMap.origFileName}
                </div>
            </div>;

            if (images.includes(fileType.toLowerCase())) {
                showRowType = true;
                content = <div className=" chat-file-box-wrapper col-3 " style={{ display: "contents" }}>
                    <div className="chat-file-box p-3" >
                        <img src={buildUrl} className="img-fluid" onClick={() => {
                            this.props.setMultipleNotesData({
                                buildUrl,
                                previewModal: true,
                                previewBlob: preview,
                                previewType: tempMessage ? "blob" : "",
                            })
                        }} />
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down" target="_blank">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (videos.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div className="chat-file-box" onClick={() => {
                        this.props.setMultipleNotesData({
                            buildUrl,
                            previewModal: true,
                            previewBlob: preview,
                            previewType: tempMessage ? "blob" : "",
                        })
                    }}>
                        <video width="100%" controls style={{ width: "80%" }}>
                            <source src={buildUrl} />
                        </video>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down" target="_blank">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (audio.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div>
                        <audio width="100%" controls onClick={() => {
                            this.props.setMultipleNotesData({
                                buildUrl,
                                previewModal: true,
                                previewBlob: preview,
                                previewType: tempMessage ? "blob" : "",
                            })
                        }}>
                            <source src={buildUrl} />
                        </audio>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down" target="_blank">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (pdf.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div className="chat-file-box" >
                        <div className="file-info d-flex" onClick={() => {
                            this.props.setMultipleNotesData({
                                buildUrl,
                                previewModal: true,
                                previewBlob: preview,
                                previewType: tempMessage ? "blob" : "",
                            })
                        }} >
                            <div className="icon" >
                                <i className="fa fa-file-pdf"></i>
                            </div>
                            <div className="text">
                                {fileMap.origFileName}
                            </div>
                        </div>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down" target="_blank">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            }
            return content
        })
        let returnDiv = contentDiv
        if (showRowType) {
            returnDiv = <div className="row">
                {contentDiv}
            </div>
        }


        return returnDiv;
    }

    tempChatFileView(ival) {
        let message = ival.chatMessage;
        let fileType = message.fileList[0].fileType;
        let buildUrl = message.fileList[0].fileUrl;

        let content = <div className="message-file">
            <div className="file" onClick={() => {
                this.props.setMultipleNotesData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <i className="fa fa-file"></i>
                {message.fileList[0].processType === "chat-record" ? "" : message.fileList[0].origFileName}
            </div>
        </div>;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov","webm"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3"]
        if (images.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleNotesData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <img src={buildUrl} className="img-fluid" />
            </div>
        } else if (videos.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleNotesData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <video width="100%" controls style={{ width: "80%" }}>
                    <source src={buildUrl} />
                </video>
            </div>
        } else if (audio.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleNotesData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <audio width="100%" controls>
                    <source src={buildUrl} />
                </audio>
            </div>
        } else if (pdf.includes(fileType.toLowerCase())) {
            content = <div className="chat-file-box" onClick={() => {
                this.props.setMultipleNotesData({
                    buildUrl,
                    previewModal: true
                })
            }}>
                <div className="file-info d-flex">
                    <div className="icon">
                        <i className="fa fa-file-pdf"></i>
                    </div>
                    <div className="text">
                        {message.fileList[0].origFileName}
                    </div>
                </div>
            </div>
        }
        return content;
    }

    chatEditFileView(message) {
        let { loginId } = this.props;
        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + message.fileList[0].processType + "&key=" + message.fileList[0].fileName + "&id=" + loginId;
        let fileType = message.fileList[0].fileType;
        return this.fileIcon(buildUrl, fileType);
    }

    deleteChatMessage(messageId, key) {
        let { loginId, companyId, userChatHistory, currentResult } = this.props;
        delete userChatHistory[key];

        this.props.setMultipleNotesData({
            userChatHistory,
            currentResult: currentResult - 1
        })

        let postJson = { reqId: getUuidv4(), notesId: messageId, userId: loginId, orgId: companyId }
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.deleteNotes);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response === GlobalMsgCons.success) {
                    console.log("deleted!")
                }
            }
        });

    }

    callStatus(status, data) {
        let { loginId } = this.props;
        let str = "";
        switch (status) {
            case "call-started":
                str = "Call Started."
                break;
            case "line-busy":
                if (data.userId === loginId) {
                    let name = "Receiver";
                    // Todo name display
                    str = name + " is on another call."
                } else {
                    str = "Missed call."
                }
                break;
            case "call-ended":
                str = "Call Ended."
                break;
            case "call-declined":
                str = "Call Declined."
                break;
            case "missed-call":
                str = "Missed call."
                break;
            default:
                str = "Calling.";
                break;
        }

        return str;
    }

    timeStampToDate = (d) => {
        let { activeCompanyDetails } = this.props;
        return getOnlyDateWithFormat(d, activeCompanyDetails)
    }

    handleLongPress(selectedMessage) {
        let _this = this;
        longPressEvent = setTimeout(() => {
            _this.props.setMultipleNotesData({
                selectedMessage: selectedMessage
            })
        }, 1000);
    }

    handleLongRelease() {
        clearTimeout(longPressEvent);
    }

    textOnchange = (value) => {
        let { editMessageId } = this.props;
        if (editMessageId) {
            this.props.setMultipleNotesData({
                editMessageText: value
            })
        } else {
            this.setChatMessage(value)
        }
    }
    
    submit = (e) => {
        let { editMessageId, gifURL } = this.props
        if (editMessageId) {
            this.editChannelSubmit(e, gifURL)
        } else {
            this.addChannelChat(e, gifURL)
        }
    }

    render() {
        let { controlFeature, fromPinned, path, message, channelId, editBlink, editMessageDetails, gifURL, loginId, userChatHistory, fileList, emojiPicker, gifPicker, currentResult, previewModal, buildUrl, insertText, insertTextToggle, recording, previewType, recordingModal, selectedMessage, editMessageId, editMessageText, setEmptyText, isRender, changeText, firstName, deactivatedMembersIds, tempMessageList, profilePhoto, companyId, userId, shareMessage, } = this.props;
        let { pageText, groupedChannelOptions } = this.state;
        let _this = this;
        var dateArray = []

        let currentTempMessageList = tempMessageList[channelId] !== undefined ? tempMessageList[channelId] : []

        return <div className="main-content">
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            <form className="chat-form" onSubmit={(e) => {
                if (editMessageId) {
                    this.editChannelSubmit(e, gifURL)
                } else {
                    this.addChannelChat(e, gifURL)
                }
            }} >
                <div className="chat-window">
                    <div className="chat-header d-flex junstify-content-center align-items-center">
                        {
                            checkMobileView() && <div className="chat-header-back">
                                <Link to={path + "/home/more"} >
                                    <i className="fa fa-arrow-left" />
                                </Link>
                            </div>
                        }
                        <div className="chat-header-title" data-tip={getValidTooltip(pageText, 'notesHeaderTxt')}>
                            # {getValidLabels(pageText, 'notesHeaderTxt')}
                        </div>
                        <React.Fragment>
                            {
                                !checkMobileView() && <button className={`btn btn-sm btn-${this.props.pinTabInfo.id === channelId && this.props.pinTabInfo.type === "notes" ? "success" : "secondary"} mr-2`} onClick={() => {
                                    if (this.props.pinTabInfo.id === channelId && this.props.pinTabInfo.type === "notes") {
                                        this.props.setPinTabInfo({
                                            id: "",
                                            type: ""
                                        })
                                    } else {
                                        this.props.setPinTabInfo({
                                            id: channelId,
                                            type: "notes"
                                        })
                                    }
                                }} data-tip={"Pin"}><i className="fas fa-thumbtack" /></button>
                            }
                            {
                                this.props.setContextHelp &&
                                <button className='btn btn-primary btn-sm mr-3' onClick={() => {
                                    accumulation(pageNameAcc, getPageEventName("notesPage", "notesHelpContextBtnClicked"), companyId, userId, this.props.grpcClient)
                                    this.props.setContextHelp("notes")
                                }} data-tip={"Help Context text"}>
                                    <i className="fas fa-question"></i>
                                </button>
                            }
                        </React.Fragment>
                    </div>
                    {channelId && channelId !== "nill" && <div className="chat-body">
                        <div className="chat-body-message" ref={(el) => { this.messageEnd = el; }}>
                            {
                                (currentResult > 0 && currentResult !== Object.keys(userChatHistory).length) ? <div className="load-more-message" onClick={() => this.loadMore()}><span>{getValidLabels(pageText, 'loadMoreMessagesBtnTxt')}</span></div> : null
                            }
                            {
                                userChatHistory && Object.keys(userChatHistory).sort().map(function (ival, i) {
                                    var showLine = false
                                    var datetime = _this.timeStampToDate(new Date(parseInt(ival)))
                                    if (!dateArray.includes(datetime)) {
                                        dateArray.push(datetime)
                                        showLine = true
                                    }
                                    var dateView = datetime
                                    var datetime_2 = _this.timeStampToDate(new Date())

                                    if (datetime_2 === datetime) {
                                        dateView = "Today"
                                    }

                                    let message;

                                    let chatMessage = userChatHistory[ival];
                                    let profilePhoto = userChatHistory[ival].userPhoto
                                    let userProfilePhoto = ""

                                    if (profilePhoto) {
                                        let split = profilePhoto.split(".")
                                        userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                                    }

                                    let messageType = chatMessage.messageType;

                                    if (typeof chatMessage.fileList != "undefined" && chatMessage.fileList != null && chatMessage.fileList.length > 0) {
                                        message = <React.Fragment>
                                            <div id={ival} dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                            {_this.chatFileView(ival)}
                                        </React.Fragment>;
                                    } else if (chatMessage.objUrl !== undefined && chatMessage.objUrl) {
                                        message = <div id={ival}>
                                            <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                            <div style={{ cursor: "pointer" }} onClick={() => {
                                                _this.props.setMultipleNotesData({
                                                    buildUrl: chatMessage.objUrl,
                                                    previewModal: true
                                                })
                                            }}>
                                                <img src={chatMessage.objUrl} />
                                            </div>
                                        </div>
                                    } else {
                                        message = <div id={ival} dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                    }


                                    return <div id={ival} key={i}>
                                        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                                        {showLine && <div className="" style={{ padding: 2, marginBottom: 5, marginTop: 5 }}>
                                            <div className="date-bg-line" style={{}} >
                                                <span className="date-bg-color">{dateView}</span>
                                            </div>
                                        </div>}
                                        <div key={ival} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}
                                            onMouseEnter={() => {
                                                if (chatMessage.userId === loginId && (typeof messageType === "undefined" || (messageType !== "call" && messageType !== "calender" && messageType !== "meeting" && messageType !== "meeting-notification"))) {
                                                    _this.props.setMultipleNotesData({
                                                        selectedMessage: chatMessage.id
                                                    })
                                                }

                                            }
                                            }
                                            onMouseLeave={() => {
                                                if (chatMessage.userId === loginId) {
                                                    _this.props.setMultipleNotesData({
                                                        selectedMessage: ""
                                                    })
                                                }
                                            }}
                                            onTouchStart={() => {
                                                _this.handleLongPress(chatMessage.id)
                                            }}
                                            onTouchEnd={() => {
                                                _this.handleLongRelease()
                                            }}
                                        >
                                            <div className="user-icon">
                                                {userProfilePhoto && userProfilePhoto != "" ? <>
                                                    <img className="name-bg" src={userProfilePhoto} alt="no img" />
                                                </> : <>
                                                    <div className="name-bg" style={{ backgroundColor: color[firstName !== "" ? firstName[0].toUpperCase() : "A"] }}>{firstName !== "" ? firstName[0].toUpperCase() : ""}</div>
                                                </>}
                                            </div>
                                            <div className="message mx-2">
                                                <div className="user-details">
                                                    <div className="user">
                                                        <b className="mr-2 uppercase">{firstName}</b>
                                                        <span className="time-info">{_this.humanDateFormat(chatMessage.createdAt)}</span>
                                                    </div>
                                                    <div className="options">

                                                        {
                                                            (editMessageId !== chatMessage.id && selectedMessage === chatMessage.id) && <div className="options-container">

                                                                <div className="option" onClick={() => {
                                                                    let shareMessage = {
                                                                        chatMessage: chatMessage
                                                                    }
                                                                    console.log("shareMessage", shareMessage)
                                                                    _this.props.setMultipleNotesData({
                                                                        shareMessage
                                                                    })

                                                                }} data-tip={"Share"}>
                                                                    <i className="fas fa-share"></i>
                                                                </div>
                                                                {
                                                                    chatMessage.message && <div className="option" onClick={() => {
                                                                        let textMsg = chatMessage.message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
                                                                        navigator.clipboard.writeText(textMsg);

                                                                        toast(`Copied Successfully.`, {
                                                                            position: "bottom-center",
                                                                            autoClose: 1000,
                                                                            hideProgressBar: true,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                            theme: "dark",
                                                                        });
                                                                    }} data-tip={"Copy"}>
                                                                        <i className="fas fa-copy"></i>
                                                                    </div>
                                                                }
                                                                <div className="option" onClick={() => {
                                                                    accumulation(pageNameAcc, getPageEventName("notesPage", "editNotesBtnClicked"), companyId, userId, _this.props.grpcClient)
                                                                    _this.edit(chatMessage)
                                                                }} data-tip={getValidTooltip(pageText, 'editBtnTxt')}>{getValidLabels(pageText, 'editBtnTxt')}</div>
                                                                <div className="option" onClick={() => {
                                                                    accumulation(pageNameAcc, getPageEventName("notesPage", "deleteNotesBtnClicked"), companyId, userId, _this.props.grpcClient)
                                                                    let responseChoose = window.confirm(getValidLabels(pageText, 'deletethisAlert_Msg'))
                                                                    if (responseChoose) {
                                                                        _this.deleteChatMessage(chatMessage.id, ival)
                                                                    }
                                                                }} data-tip={getValidTooltip(pageText, 'deleteBtnTxt')}>{getValidLabels(pageText, 'deleteBtnTxt')}</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="message-box">
                                                    {
                                                        message
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {
                                currentTempMessageList.map(function (ival, i) {
                                    let chatMessage = ival.chatMessage;

                                    var showLine = false
                                    var datetime = _this.timeStampToDate(new Date(parseInt(chatMessage.sendAt)))
                                    if (!dateArray.includes(datetime)) {
                                        dateArray.push(datetime)
                                        showLine = true
                                    }
                                    var dateView = datetime
                                    var datetime_2 = _this.timeStampToDate(new Date())

                                    if (datetime_2 === datetime) {
                                        dateView = "Today"
                                    }

                                    let message;

                                    let userName = ival.name
                                    let userProfilePhoto = ""

                                    if (profilePhoto) {
                                        let split = profilePhoto.split(".")
                                        userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                                    }

                                    if (typeof chatMessage.fileList != "undefined" && chatMessage.fileList != null && chatMessage.fileList.length > 0) {
                                        message = <React.Fragment>
                                            <div id={ival} dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                            {_this.tempChatFileView(ival)}
                                        </React.Fragment>;
                                    } else if (chatMessage.objUrl !== undefined && chatMessage.objUrl) {
                                        message = <div id={ival} >
                                            <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                            <div style={{ cursor: "pointer" }} onClick={() => {
                                                _this.props.setMultipleNotesData({
                                                    buildUrl: chatMessage.objUrl,
                                                    previewModal: true
                                                })
                                            }}>
                                                <img src={chatMessage.objUrl} />
                                            </div>
                                        </div>
                                    } else {
                                        message = <div id={ival} dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                                    }


                                    return <div id={ival} key={i}>
                                        {showLine && <div className="" style={{ padding: 2, marginBottom: 5, marginTop: 5 }}>
                                            <div className="date-bg-line" style={{}} >
                                                <span className="date-bg-color">{dateView}</span>
                                            </div>
                                        </div>}
                                        <div key={i} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}>
                                            <div className="user-icon">
                                                {userProfilePhoto && userProfilePhoto != "" ? <>
                                                    <img className="name-bg" src={userProfilePhoto} alt="no img" />
                                                </> : <>
                                                    <div className="name-bg" style={{ backgroundColor: color[userName !== "" ? userName[0].toUpperCase() : "A"] }}>{userName !== "" ? userName[0].toUpperCase() : ""}</div>
                                                </>}
                                            </div>
                                            <div className="message mx-2">
                                                <div className="user-details">
                                                    <div className="user">
                                                        <b className="mr-2 uppercase">{userName}</b>
                                                        <span className="time-info">{_this.humanDateFormat(chatMessage.createdAt)}</span>
                                                    </div>
                                                </div>
                                                <div className="message-box">
                                                    {
                                                        message
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>}

                    {channelId && channelId !== "nill" && !deactivatedMembersIds.includes(this.props.userId) && <div className="chat-input-container">
                        <div className="chat-input-container-element">

                            {
                                emojiPicker && <div className="chat-smiley">
                                    <Picker
                                        pickerStyle={{ width: '100%', height: 300 }}
                                        disableSkinTonePicker={true}
                                        onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject)}
                                    />
                                </div>
                            }
                            {
                                gifPicker && <div className="chat-gif">
                                    <GifPicker select={(e, url) => {
                                        this.props.setMultipleNotesData({
                                            gifPicker: false,
                                            gifURL: url
                                        })
                                        if (editMessageId) {
                                            this.editChannelSubmit(e, url)
                                        } else {
                                            this.addChannelChat(e, url)
                                        }
                                    }} />
                                </div>
                            }
                            <div className="">
                                {
                                    isRender && <React.Fragment>
                                        {
                                            controlFeature.experimentalEnable ? <CustomQuillEditor
                                                editorClassName={editBlink ? "editor-blink" : ""}
                                                pagename={"chat"}
                                                insertText={insertText}
                                                insertTextToggle={insertTextToggle}
                                                setEmptyText={setEmptyText}
                                                text={editMessageId ? editMessageText : message}
                                                changeText={changeText}
                                                textOnchange={this.textOnchange}
                                                submit={this.submit}
                                                channelId={channelId}
                                                parentClassName={fromPinned ? "chat-minimized" : "chat-page"}
                                            /> : <React.Fragment>
                                                {
                                                    fromPinned ? <CustomEditorMinimized
                                                        editorClassName={editBlink ? "editor-blink" : ""}
                                                        pagename={"chat"}
                                                        insertText={insertText}
                                                        insertTextToggle={insertTextToggle}
                                                        setEmptyText={setEmptyText}
                                                        text={editMessageId ? editMessageText : message}
                                                        changeText={changeText}
                                                        textOnchange={this.textOnchange}
                                                        submit={this.submit}
                                                        channelId={channelId}
                                                    /> : <CustomEditor
                                                        editorClassName={editBlink ? "editor-blink" : ""}
                                                        pagename={"chat"}
                                                        insertText={insertText}
                                                        insertTextToggle={insertTextToggle}
                                                        setEmptyText={setEmptyText}
                                                        text={editMessageId ? editMessageText : message}
                                                        changeText={changeText}
                                                        textOnchange={this.textOnchange}
                                                        submit={this.submit}
                                                        channelId={channelId}
                                                    />
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>

                                }
                            </div>
                            {
                                fileList.length > 0 && <div className="chat-files">
                                    <div className="row mx-0">
                                        {
                                            fileList.map((file, i) => {
                                                return file.file instanceof File ? <div className="col-2" key={i}>
                                                    <div className="chat-file-holder" title={file.origFileName} onClick={() => {
                                                        let preview = { filePath: file.fileUrl, fileType: file.fileType }
                                                        _this.props.setMultipleNotesData({
                                                            previewBlob: preview,
                                                            previewType: "blob",
                                                            previewModal: true
                                                        })
                                                    }}>
                                                        {file.uploadProcess === "pending" && <div className="spin-delete" onClick={(e) => {
                                                            this.fileDelete(e, i)
                                                        }}><i className="fa fa-times" /></div>}
                                                        {file.uploadProcess === "processing" && <div className="spin-loader"></div>}
                                                        {
                                                            this.fileIcon(file.fileUrl, file.fileType)
                                                        }
                                                        {
                                                            <span>{file.processType === "chat-record" ? "Record" : file.origFileName}</span>
                                                        }
                                                    </div>
                                                </div> : <div className="col-2" key={i}>
                                                    <div className="chat-file-holder">
                                                        <div className="spin-delete" onClick={(e) => {
                                                            this.fileDelete(e, i)
                                                        }}><i className="fa fa-times" /></div>
                                                        {_this.chatEditFileView(editMessageDetails)}
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            <div className="chat-input-extra">
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <div className="icon" data-tip={getValidTooltip(pageText, 'attchmentIconTxt')}>
                                        <input className="file-input-hidden" type="file" accept="image/*,video/*,.csv,application/pdf,application/vnd.ms-excel,.doc,.docx,.json" onChange={(e) => {
                                            accumulation(pageNameAcc, getPageEventName("notesPage", "attachFileIconClicked"), companyId, userId, this.props.grpcClient)
                                            this.fileChange(e)
                                        }} />
                                        <i className="fa fa-plus"></i>
                                    </div>
                                    <div className={`icon ${recording ? "record" : ""}`} onClick={() => {
                                        accumulation(pageNameAcc, getPageEventName("notesPage", "recordAudioIconClicked"), companyId, userId, this.props.grpcClient)
                                        this.props.setMultipleNotesData({
                                            recordingModal: true
                                        })
                                    }} data-tip={getValidTooltip(pageText, 'audioRecIconTxt')}>
                                        <i className="fa fa-microphone"></i>
                                    </div>
                                    <div className={`icon ${emojiPicker ? "active" : ""}`} onClick={() => {
                                        accumulation(pageNameAcc, getPageEventName("notesPage", "emojiIconClicked"), companyId, userId, this.props.grpcClient)
                                        this.props.setMultipleNotesData({
                                            gifPicker: false,
                                            emojiPicker: !emojiPicker
                                        })
                                    }} data-tip={getValidTooltip(pageText, 'smileIconTxt')}>
                                        <i className="fa fa-smile"></i>
                                    </div>
                                    <div className={`icon ${gifPicker ? "active" : ""}`} style={{ fontWeight: 'bold' }} onClick={() => {
                                        this.props.setMultipleNotesData({
                                            emojiPicker: false,
                                            gifPicker: !gifPicker
                                        })
                                    }} data-tip={getValidTooltip(pageText, 'gIFLabelTxt')}>
                                        {getValidLabels(pageText, 'gIFLabelTxt')}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="message-submit-button reset" type="button" onClick={() => {
                                        this.props.setMultipleNotesData({
                                            editMessageId: "",
                                            editMessageText: "",
                                            editMessageDetails: {},
                                            userChatHistory,
                                            setEmptyText: !setEmptyText,
                                            emojiPicker: false,
                                            fileList: [],
                                            gifURL: ""
                                        })
                                    }} data-tip={getValidTooltip(pageText, 'resetTypeIconTxt')}><i className="fa fa-times"></i></button>
                                    <button className="message-submit-button" type="submit" data-tip={getValidTooltip(pageText, 'sendIconTxt')}><i className="fa fa-paper-plane"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="typing"></div>
                </div>
            </form>

            {
                previewModal && <Modal
                    visible={previewModal}
                    size={"modal-xl"}
                    closeModal={() => this.props.setMultipleNotesData({
                        previewModal: false,
                        viewModal: true,
                        previewBlob: {},
                        previewType: "",
                    })}
                    heading={getValidLabels(pageText, 'previewHeaderTxt')}
                    body={<div style={{ minHeight: "75vh" }}>
                        <div className="row">
                            <div className="col-1">
                            </div>
                            <div className="col-10">
                                {
                                    previewType === "blob" ? this.previewBlobContent() : this.previewContent(buildUrl)
                                }
                            </div>
                            <div className="col-1">
                            </div>
                        </div>

                    </div>
                    }
                />
            }

            {
                recordingModal && <Modal
                    visible={recordingModal}
                    closeModal={() => this.props.setMultipleNotesData({
                        recordingModal: false
                    })}
                    heading={getValidLabels(pageText, 'recordHeaderTxt')}
                    body={
                        <Recorder
                            saveAudio={this.saveAudio} />
                    }
                />
            }

            {shareMessage && <SharedMessageModal
                shareMessage={shareMessage}
                closeModal={() => {
                    this.props.setMultipleNotesData({ shareMessage: "" })
                    this.props.setShareMessage("")
                }}
                heading={getValidLabels(pageText, 'shareNotesHeaderTxt')}
                groupedChannelOptions={groupedChannelOptions}
                grpcClient={this.props.grpcClient}
                path={this.props.path}
                channelId={this.props.channelId}
                pageText={pageText}
                languagePreference={this.props.languagePreference}
            />}
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        loginId: state.authReducer.loginId,
        profilePhoto: state.authReducer.profilePhoto,
        sessionId: state.authReducer.sessionId,
        companyId: state.authReducer.activeCompany,
        activeCompanyDetails: state.authReducer.activeCompanyDetails,
        firstName: state.authReducer.firstName,
        controlFeature: state.authReducer.controlFeature,
        currentChannelName: state.notesReducer.currentChannelName,
        channelType: state.notesReducer.channelType,
        channelId: state.notesReducer.channelId,
        channelMembers: state.notesReducer.channelMembers,
        userChatHistory: state.notesReducer.userChatHistory,
        currentResult: state.notesReducer.currentResult,
        message: state.notesReducer.message,
        fileList: state.notesReducer.fileList,
        noOfPage: state.notesReducer.noOfPage,
        emojiPicker: state.notesReducer.emojiPicker,
        gifPicker: state.notesReducer.gifPicker,
        buildUrl: state.notesReducer.buildUrl,
        insertTextToggle: state.notesReducer.insertTextToggle,
        setEmptyText: state.notesReducer.setEmptyText,
        insertText: state.notesReducer.insertText,
        recording: state.notesReducer.recording,
        recordingModal: state.notesReducer.recordingModal,
        previewBlob: state.notesReducer.previewBlob,
        previewType: state.notesReducer.previewType,
        selectedMessage: state.notesReducer.selectedMessage,
        editMessageId: state.notesReducer.editMessageId,
        editMessageDetails: state.notesReducer.editMessageDetails,
        editMessageText: state.notesReducer.editMessageText,
        infoShow: state.notesReducer.infoShow,
        isRender: state.notesReducer.isRender,
        scrollBottom: state.notesReducer.scrollBottom,
        gifURL: state.notesReducer.gifURL,
        deleteMessageId: state.notesReducer.deleteMessageId,
        channelMembersDetails: state.notesReducer.channelMembersDetails,
        isFeedPage: state.notesReducer.isFeedPage,
        pageText: state.notesReducer.pageText,
        addPeopleModal: state.notesReducer.addPeopleModal,
        channelUser: state.notesReducer.channelUser,
        userListOption: state.notesReducer.userListOption,
        errors: state.notesReducer.errors,
        excludeMembers: state.notesReducer.excludeMembers,
        channelMuteStatus: state.notesReducer.channelMuteStatus,
        messageSound: state.notesReducer.messageSound,
        selectedMessageSound: state.notesReducer.selectedMessageSound,
        deactivatedMembersIds: state.notesReducer.deactivatedMembersIds,
        previewModal: state.notesReducer.previewModal,
        changeText: state.notesReducer.changeText,
        editBlink: state.notesReducer.editBlink,
        showMorePeopleModal: state.notesReducer.showMorePeopleModal,
        existChannelMemberIds: state.notesReducer.existChannelMemberIds,
        paginateData: state.notesReducer.paginateData,
        tempMessageList: state.notesReducer.tempMessageList,
        dataFetched: state.notesReducer.dataFetched,
        shareMessage: state.notesReducer.shareMessage,
        state,
    };
};

const mapDispatchToProps = {
    setNotesData, setMultipleNotesData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notes));

