import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { analyticsUserTrack } from "../config/AnalyticsTrack";
// import GoesOnLightBackground from "../assets/logo/LightBg2.gif";

let teqbahnUrl = "https://teqbahn.com/"
const Menu = () => {
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

    const handleMenuClick = (menuName) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };

    const handleResize = () => {
        if (window.innerWidth <= 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
            setActiveMenu(null);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // initialize the mobile check
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => {
                        setToggleMobileMenu(!toggleMobileMenu)
                    }}
                >
                    <i className={`fas fa-${toggleMobileMenu ? "times" : "bars"}`}></i>
                </button>
                <div className={`collapse navbar-collapse ${isMobile ? "mobile" : ""} ${toggleMobileMenu ? "show" : ""}`} id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        {/* <div className="teqbahn-logo">
                            <a href={"https://teqbahn.com/mid-year-update-2024/"}>
                                <img className='img-fluid' src={GoesOnLightBackground} />
                            </a>
                        </div> */}
                        <li className="nav-item">
                            <a className="nav-link" href={teqbahnUrl} onClick={() => {
                                analyticsUserTrack("Home", "Teqbahn Home Click")
                            }}>
                                Home
                            </a>
                        </li>
                        <li
                            className={`nav-item dropdown ${activeMenu === 'aboutUs' ? 'show' : ''}`}
                            onMouseEnter={() => !isMobile && setActiveMenu('aboutUs')}
                            onMouseLeave={() => !isMobile && setActiveMenu(null)}
                        >
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    isMobile && handleMenuClick('aboutUs')
                                }}
                            >
                                About Us
                            </a>
                            <ul
                                className={`dropdown-menu ${activeMenu === 'aboutUs' ? 'show' : ''}`}
                            >
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "our-team"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - Our Team Click")
                                    }}>
                                        Our Team
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "online-library"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - Online Library Click")
                                    }}>
                                        Online Library
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "TeqEd"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - TeqEd Click")
                                    }}>
                                        TeqEd
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "blog"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - Blog Click")
                                    }}>
                                        Blog
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={`nav-item dropdown ${activeMenu === 'lob' ? 'show' : ''}`}
                            onMouseEnter={() => !isMobile && setActiveMenu('lob')}
                            onMouseLeave={() => !isMobile && setActiveMenu(null)}
                        >
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    isMobile && handleMenuClick('lob')
                                }}
                            >
                                LOB
                            </a>
                            <ul
                                className={`dropdown-menu ${activeMenu === 'lob' ? 'show' : ''}`}
                            >
                                <li>
                                    <span className='dropdown-head'>Lines Of Business</span>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "ventures"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - Ventures Click")
                                    }}>
                                        Teqbahn Ventures
                                    </a>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to={"/"} onClick={() => {
                                        setToggleMobileMenu(false)
                                    }}>
                                        Teqit
                                    </Link>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={"https://umed.me/"} onClick={() => {
                                        analyticsUserTrack("Home", "Umedme Click")
                                    }}>
                                        Umed.Me
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "social-impact"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - Social Impact Click")
                                    }}>
                                        Social Impact
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={`nav-item dropdown ${activeMenu === 'teq' ? 'show' : ''}`}
                            onMouseEnter={() => !isMobile && setActiveMenu('teq')}
                            onMouseLeave={() => !isMobile && setActiveMenu(null)}
                        >
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    isMobile && handleMenuClick('teq')
                                }}
                            >
                                Teq
                            </a>
                            <ul
                                className={`dropdown-menu ${activeMenu === 'teq' ? 'show' : ''}`}
                            >
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "tprhome"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - TPR Click")
                                    }}>
                                        TPR
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href={teqbahnUrl + "csit"} onClick={() => {
                                        analyticsUserTrack("Home", "Teqbahn - CSIT Click")
                                    }}>
                                        CSIT
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Menu;