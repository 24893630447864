import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

let start = false
const Typewriter = ({ text, delay, infinite, scrollBottom }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [stopped, setStopped] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        let timeout;

        // Replace \n with <br/>
        text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        text = text.replace(/\n/g, '<br/>');

        // Replace \t with &emsp;
        text = text.replace(/\t/g, '&emsp;');
        
        const regex = /```(.*?)<br\/>/g;
        const matches = [...text.matchAll(regex)];
        const results = matches.map(match => match[1]);

        for(let i = 0;i<results.length;i++) {
            let program = results[i]
            if(program) {
                text = text.replace("```"+program, '<div class="d-flex align-items-center copy-code-container"><div>'+program+'</div><button class="btn btn-sm btn-primary copy-code">Copy</button></div>```')
            }
        }

        if (currentIndex <= text.length) {
            timeout = setTimeout(() => {
                let nextChar = text[currentIndex];
                if(nextChar) {
                    let inc = 1
                    if (text.substring(currentIndex, currentIndex + 3) === '```') {
                        if(start === false) {
                            nextChar = '<pre><code>';
                            start = true
                        } else {
                            nextChar = '</code></pre>';
                            start = false
                        }
                        inc = 3
                    } 
                    setCurrentText(prevText => prevText + nextChar);
                    setCurrentIndex((prev) => prev + inc);
                    scrollBottom()
                } else {
                    setStopped(true)
                }
            }, delay);

        } else if (infinite) { // ADD THIS CHECK
            setCurrentIndex(0);
            setCurrentText('');
            
        }

        return () => clearTimeout(timeout);
    }, [currentIndex, delay, infinite, text]);

    useEffect(() => {
        if(stopped) {
            // Make sure the ref is attached to the DOM element
            const parentElement = contentRef.current;
            if (parentElement) {
                // Add click event listener to parent element
                const handleClick = (event) => {
                    if (event.target.classList.contains('copy-code')) {
                        event.preventDefault();
                        const clickedDiv = event.target.parentNode;
                        const nextElement = clickedDiv.nextElementSibling;
                        navigator.clipboard.writeText(nextElement.innerText);
                        toast(`Copied Successfully.`, {
                            position: "bottom-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        
                    }
                };
    
                parentElement.addEventListener('click', handleClick);
    
                // Cleanup: Remove event listener when component unmounts
                return () => {
                    parentElement.removeEventListener('click', handleClick);
                };
            }
        }
    }, [stopped]);

    return <span ref={contentRef} dangerouslySetInnerHTML={{ __html: currentText }}></span>;
};

export default Typewriter;
