import React, { useEffect, useState } from "react";

const CompTabs = (props) => {
    let { optionsList, activeTab, onChange, tabBody } = props;

    useEffect(() => {
        return () => {

        }
    }, []);

    return (
        <React.Fragment>
            <div className="tabs-container">
                <div className={`tabs`}>
                    {optionsList.map((ev, ei) => {
                        return <React.Fragment key={ei + "_tab"}>
                            <div className={`tab ${activeTab === ev.value ? "active" : ""}`} onClick={() => {
                                onChange(ev)
                            }}>
                                {ev.label}
                            </div>
                        </React.Fragment>
                    })}
                </div>
                <div className="tabs-body">
                    {tabBody}
                </div>
            </div>
        </React.Fragment>
    );
};


export default CompTabs;