module.exports = {
  keyList: {
    // projectName: 'Teqit',
    // projectUrl: '/teqit',
    // webReleaseVersion: "128",
    // jitsiDomain: "meet.teqbahn.com:9443",
    // apiTvURL: 'https://tv.teqbahn.com/teqitTv-api/',
    // ipURL: 'https://apis.teqbahn.com/teqe-api/',
    // apiURL: 'http://192.168.1.5:8091/teqverse-api/',
    // grpcURL: 'http://192.168.1.5:8000',
    // reportApiURL: 'http://192.168.1.5:8093/teqversereporting-api/',
    // reportGrpcURL: 'http://192.168.1.5:8000',
    // searchApiURL: 'http://192.168.1.5:8096/teqverse-search-api/',
    // searchGrpcURL: 'http://192.168.1.5:8000',
    // docsApiURL: 'http://192.168.1.5:8074/teqverse-api/',
    // docsGrpcURL: 'http://192.168.1.5:8000',
    // aiUrl: 'http://192.168.1.5:50152/t3/',
    // analyticsApiURL: 'http://192.168.1.5:8096/teqbahn-analytics-api/',
    // analyticsGrpcURL: 'http://192.168.1.5:8000',
    // simulationApiURL: 'http://192.168.1.5:8096/teqit-simulation-api/',
    // simulationGrpcURL: 'http://192.168.1.5:8000',
    // appBuilderApiURL: 'http://192.168.1.5:8096/appbuilder-api/',
    // appBuilderGrpcURL: 'http://192.168.1.5:8000',
    // defaultEmailConfigId: ""

    // projectName: 'Teqit',
    // projectUrl: '/teqit',
    // webReleaseVersion: "128",
    // jitsiDomain: "meet.teqbahn.com:9443",
    // apiURL: 'https://api.teqbahn.com/teqverse-api/',
    // grpcURL: 'https://api.teqbahn.com',
    // reportApiURL: 'https://api.teqbahn.com/teqversereporting-api/',
    // reportGrpcURL: 'https://api.teqbahn.com',
    // searchApiURL: 'https://api.teqbahn.com/teqverse-search-api/',
    // searchGrpcURL: 'https://api.teqbahn.com',
    // docsApiURL: 'https://api.teqbahn.com/teqdocs-api/',
    // docsGrpcURL: 'https://api.teqbahn.com',
    // apiTvURL: 'https://tv.teqbahn.com/teqitTv-api/',
    // aiUrl: 'https://api.teqbahn.com/t3/',
    // ipURL: 'https://apis.teqbahn.com/teqe-api/',
    // apiURL: 'http://192.168.55.154:8091/teqverse-api/',
    // grpcURL: 'http://192.168.55.154:8000',
    // reportApiURL: 'http://192.168.55.154:8093/teqversereporting-api/',
    // reportGrpcURL: 'http://192.168.55.154:8000',
    // searchApiURL: 'http://192.168.55.154:8096/teqverse-search-api/',
    // searchGrpcURL: 'http://192.168.55.154:8000',
    // docsApiURL: 'http://192.168.55.154:8074/teqverse-api/',
    // docsGrpcURL: 'http://192.168.55.154:8000',
    // aiUrl: 'http://192.168.55.154:50152/t3/',
    //defaultEmailConfigId:""

    projectName: "Teqit",
    projectUrl: "/teqit",
    webReleaseVersion: "331",
    jitsiDomain: "meet.teqbahn.com:9443",
    apiURL: "https://api.teqbahn.com/teqverse-api/",
    grpcURL: 'https://api.teqbahn.com',
    reportApiURL: "https://api.teqbahn.com/teqversereporting-api/",
    reportGrpcURL: "https://api.teqbahn.com",
    searchApiURL: "https://api.teqbahn.com/teqverse-search-api/",
    searchGrpcURL: "https://api.teqbahn.com",
    docsApiURL: "https://api.teqbahn.com/teqdocs-api/",
    docsGrpcURL: "https://api.teqbahn.com",
    apiTvURL: "https://tv.teqbahn.com/teqitTv-api/",
    aiUrl: "https://api.teqbahn.com/t3/",
    ipURL: "https://apis.teqbahn.com/teqe-api/",
    analyticsApiURL: "https://api.teqbahn.com/teqbahn-analytics-api/",
    analyticsGrpcURL: "https://api.teqbahn.com",
    simulationApiURL: "https://sim.teqbahn.com/teqit-simulation-api/",
    simulationGrpcURL: "https://api.teqbahn.com",
    appBuilderApiURL: "https://api.teqbahn.com/appbuilder-api/",
    appBuilderGrpcURL: "https://api.teqbahn.com",
    tsquareApiURL: "https://sim.teqbahn.com/tsquare-api/",
    tsquareGrpcURL: "https://api.teqbahn.com",
    umedmeApiURL: "https://api.teqbahn.com/umedme-api/",
    umedmeGrpcURL: "https://api.teqbahn.com",
    defaultEmailConfigId: "support@teqit.me",
    translateUrl: "https://api.teqbahn.com/t2/text-to-text/"

//    projectName: window._env_.REACT_APP_projectName,
//    projectUrl: "/teqit",
//    webReleaseVersion: window._env_.REACT_APP_webReleaseVersion,
//    jitsiDomain: window._env_.REACT_APP_jitsiDomain,
//    apiURL: window._env_.REACT_APP_apiURL,
//    grpcURL: window._env_.REACT_APP_grpcURL,
//    reportApiURL: window._env_.REACT_APP_reportApiURL,
//    reportGrpcURL: window._env_.REACT_APP_reportGrpcURL,
//    searchApiURL: window._env_.REACT_APP_searchApiURL,
//    searchGrpcURL: window._env_.REACT_APP_searchGrpcURL,
//    docsApiURL: window._env_.REACT_APP_docsApiURL,
//    docsGrpcURL: window._env_.REACT_APP_docsGrpcURL,
//    apiTvURL: window._env_.REACT_APP_apiTvURL,
//    aiUrl: window._env_.REACT_APP_aiUrl,
//    ipURL: window._env_.REACT_APP_ipURL,
//    analyticsApiURL: window._env_.REACT_APP_analyticsApiURL,
//    analyticsGrpcURL: window._env_.REACT_APP_analyticsGrpcURL,
//    simulationApiURL: window._env_.REACT_APP_simulationApiURL,
//    simulationGrpcURL: window._env_.REACT_APP_simulationGrpcURL,
//    appBuilderApiURL: window._env_.REACT_APP_appBuilderApiURL,
//    appBuilderGrpcURL: window._env_.REACT_APP_appBuilderGrpcURL,
//    tsquareApiURL: window._env_.REACT_APP_tsquareApiURL,
//    tsquareGrpcURL: window._env_.REACT_APP_tsquareGrpcURL,
//    umedmeApiURL: window._env_.REACT_APP_umedmeApiURL,
//    umedmeGrpcURL: window._env_.REACT_APP_umedmeGrpcURL,
//    defaultEmailConfigId: window._env_.REACT_APP_defaultEmailConfigId,
//    translateUrl: window._env_.REACT_APP_translateUrl
  },
};
