import React from 'react';
import { Link, withRouter, } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getGrpcClient, getValidLabels, equalEducation, getUuidv4 } from '../config/Common';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
 import { connect } from 'react-redux';
import GlobalDomainCons from '../config/GlobalDomainCons';
import GlobalEventCons from '../config/GlobalEventCons';
import GlobalMsgCons from '../config/GlobalMsgCons';

class BoardMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleMenu: [],
            userId: "",
            companyId: "",
            boardId: "",
            userListOption: [],
            menuToggleFirst: true,
            boardList: [],
            boardName: "",
            memberIds: [],
            errors: {
                boardName: "",
                memberIds: ""
            },
            boardDescription: "",
            pageText: null
        }
    }
    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let { pathname } = this.props.location;

            this.setState({
                boardId: this.getBoardId(pathname)
            })
        }
        if (this.props.newBoardArrived !== prevProps.newBoardArrived || this.props.teamUpdated !== prevProps.teamUpdated || this.props.taskUpdated !== prevProps.taskUpdated) {
            this.getBoardList();
        }

        if (this.props.activeCompanyId !== prevProps.activeCompanyId) {
            this.init();
        }
    }

    init() {
        let { userId, companyId } = this.props;
        let { pathname } = this.props.location;

        this.setState({
            userId,
            companyId,
            boardId: this.getBoardId(pathname)
        }, () => {
            this.getBoardList();
        });
    }

    getBoardList() {
        let { userId, boardId, toggleMenu, companyId } = this.state;
        let postJson = { userId, orgId: companyId, reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.boardsSetDomain);
        request.setEvent(GlobalEventCons.listSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let boardList = [];
                let { menuToggleFirst } = this.state;
                let result = responseData.result;
                Object.keys(result).map((id) => {
                    if (boardId === id) {
                        let menuType = "board";
                        if (!toggleMenu.includes(menuType) && menuToggleFirst) {
                            toggleMenu.push(menuType);
                            menuToggleFirst = false
                        }
                    }
                    boardList.push({
                        name: result[id].boardName,
                        id: id
                    });
                })

                this.setState({
                    boardList,
                    menuToggleFirst,
                    toggleMenu
                }, () => {
                    ReactTooltip.rebuild();
                });
            }
        });
    }

    getBoardId(pathname) {
        let boardId = "";

        if (pathname.includes("/board/")) {
            let pathArr = pathname.split("/");
            boardId = pathArr.slice(-1)[0]
        }

        return boardId;
    }

    menuToggle(type) {
        let { toggleMenu } = this.state;
        if (toggleMenu.includes(type)) {
            const index = toggleMenu.indexOf(type);
            toggleMenu.splice(index, 1);
        } else {
            toggleMenu.push(type);
        }

        this.setState({
            toggleMenu
        })
    }

    render() {
        let { boardId, toggleMenu, boardList, } = this.state;
        let { appsLayout, pageText, companyType, path } = this.props;

        return <div className="chat-menu">
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            {
                (typeof appsLayout !== undefined && appsLayout) ? <div className='container'>
                    <div className="row my-5">

                        {
                            boardList.map((row, index) => {
                                let labelName = row.name
                                if (equalEducation(companyType) && row.name === "Company Board") {
                                    labelName = "Education Board";
                                }
                                return <div className="col-sm-2" key={index}>
                                    <div className="launchpad">
                                        <Link to={path + "/board/" + row.id}>
                                            <div className="link-icon"><i className={`fa fa-hashtag`}></i></div>
                                            <div className="link-text">{labelName}</div>
                                        </Link>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div> : <React.Fragment>
                    <li className="sub-menu-header" onClick={() => this.menuToggle("board")}>
                        <span className="link-icon"><i className="fa fa-chalkboard"></i></span>
                        <span className="link-text">{getValidLabels(pageText, 'boardsTxt')}</span>
                        <span className="float-right">
                            <span className="arrow">
                                <i className={`fa ${toggleMenu.includes("board") ? "fa-chevron-down" : "fa-chevron-right"}`}></i>
                            </span>
                        </span>
                    </li>
                    {
                        (toggleMenu.includes("board") && boardList.length > 0) && <ul className="sub-menu">
                            {
                                boardList.map((row, index) => {
                                    let labelName = row.name
                                    if (equalEducation(companyType) && row.name === "Company Board") {
                                        labelName = "Education Board";
                                    }
                                    return <li key={index} className={`nav-item ${boardId === row.id ? "active" : ""}`}>
                                        <Link
                                            // data-tip={row.name} 
                                            to={path + "/board/" + row.id}>
                                            <span className="pr-2"><i className="fa fa-hashtag"></i></span>
                                            <span className="channel-name board-name">{labelName}</span>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    }
                </React.Fragment>
            }
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        firstName: state.authReducer.firstName,
    }
}
export default withRouter(connect(mapStateToProps)(BoardMenu));