import React from "react";

export default class CardToggle extends React.Component {
    render() {
        let { title, options, bodyClassName, toggleMenuOpen, } = this.props;
        return <div className="card">
            <div className="card-header theme-bg">
                <div style={{ display: "flex", cursor: "pointer" }} onClick={() => {
                    this.props.toggleOnchange()
                }}>
                    <div style={{ flex: 1 }}>
                        {title}
                    </div>
                    {
                        options && <div>
                            {options}
                        </div>
                    }
                    <div style={{ color: "#fff", width: 50, textAlign: "center", }}>
                        <i className={`fa fa-chevron-${toggleMenuOpen ? "up" : "down"}`} />
                    </div>
                </div>
            </div>
            <div className={`card-body ${bodyClassName}`} style={(toggleMenuOpen) ? { display: "none" } : {}}>
                {this.props.children}
            </div>
        </div >
            ;
    }
}
