import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import MyConfig from '../config/MyConfig';

export default (props) => {
  let [options, setOptions] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  let [value, setValue] = useState([]);
  let [newValueList, setNewValueList] = useState([]);

  useEffect(() => {
    setValue(props.value ? props.value : props.selectedOption)
  }, [])

  useEffect(() => {
    setOptions(props.options)
    let optionsList = props.options.map(item => item.value);
    let list = []
    for (let i = 0; i < newValueList.length; i++) {
      let inputValue = newValueList[i].value;
      if (optionsList.includes(inputValue)) {
        list.push(newValueList[i])
      } else {
        if (value.value === inputValue) {
          setValue("");
          props.onChange("")
        }
      }
    }

    setNewValueList(list)
  }, [props.options])

  function getThemeBg() {
    let returnTheme = "#fff";
    return returnTheme;
  }

  function getThemeText() {
    let returnTheme = "#000";

    return returnTheme;
  }

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError && { borderColor: 'red' }),
      background: getThemeBg(),
      color: getThemeText(),
    }),
    menu: (base) => ({
      ...base,
      background: getThemeBg(),
      color: getThemeText()
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#2196F3" : isFocused ? "#8ec5f2" : undefined
      };
    }
  });


  async function handleCreate(inputValue) {
    let reg = MyConfig.emaildIdValidation;
    if (reg.test(inputValue)) {
      setIsLoading(true);
      let newOption = await props.handleCreate(inputValue)

      setNewValueList(newValueList.concat(newOption))
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      props.onChange(newOption)
    } else {
      alert("Please select valid email.")
    }
  }


  return <React.Fragment>
    <CreatableSelect
      {...props}
      onCreateOption={handleCreate}
      styles={customStyles(props.error ? props.error : false)}
      value={value}
      onChange={(e) => {
        setValue(e)
        props.onChange(e)
      }}
      options={options}
      isDisabled={props.isDisabled ? true : false}
      isMulti={props.isMulti ? props.isMulti : false}
      placeholder={props.placeholder}
      menuPortalTarget={document.body}
      menuPlacement={"auto"}
      isLoading={isLoading}
      formatCreateLabel={(typedlabel) => {
        return props.createLabel ? `${props.createLabel} "${typedlabel}"` : `Create "${typedlabel}"`
      }}
    />

  </React.Fragment>
}
