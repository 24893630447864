import { SET_AUTH, RESET_AUTH, SET_MULTIPLE_AUTH } from '../constants/actionTypes';

const initialState = {
  isLoggedIn: '0',
  loginId: "",
  firstName: "",
  lastName: "",
  profilePhoto: "",
  sessionId: "",
  loggedEmail: "",
  userThemeColor: "",
  languagePreference: "",
  deviceToken: "",
  activeCompany: "",
  activeCompanyName: "",
  activeCompanyColor: "",
  activeCompanyDetails: "",
  activeCompanyLogo: "",
  activeMenuLayout: "",
  activeUserType: "",
  feedId: "",
  steppedAwayStatus: "available",
  systemLevelRole: "member",
  activeStatus: "",
  hoursSelected: 12,
  fromMobileAccess: false,
  controlFeature: {
    audioEnable: true,
    videoEnable: true,
    qatEnable: true,
    expensesEnable: true,
    orgFeedbackEnable: true,
    vsCodeEnable: true,
    teqreqEnable: true,
    dailyReportEnable: true,
    openMrsEnable: false
  },
  parentOrgInfo: {},
  activeDocsOrgId: "",
  activeSheetOrgId: "",
  activeTeqDrawOrgId: "",
  openMrsChannelId: "",
  openMrsExternalAppUserId: "",
  phone: "",
  organizationType: "",
  theme: "default",
  requestFrom: "",
  mailNotificationForChat: false,
  workspaceSubType: "",
  roleTypePrivilegesPageAccess: [],
  roleRegistryEnable: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_AUTH: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_AUTH: {
      return {
        ...state,
        isLoggedIn: '0',
        loginId: "",
        firstName: "",
        lastName: "",
        profilePhoto: "",
        sessionId: "",
        loggedEmail: "",
        userThemeColor: "",
        languagePreference: "",
        deviceToken: "",
        activeCompany: "",
        activeCompanyName: "",
        activeCompanyColor: "",
        activeCompanyDetails: "",
        activeCompanyLogo: "",
        activeMenuLayout: "",
        activeUserType: "",
        feedId: "",
        steppedAwayStatus: "available",
        systemLevelRole: "member",
        activeStatus: "",
        hoursSelected: 12,
        fromMobileAccess: false,
        controlFeature: {
          audioEnable: true,
          videoEnable: true,
          qatEnable: true,
          expensesEnable: true,
          orgFeedbackEnable: true,
          vsCodeEnable: true,
          teqreqEnable: true,
          dailyReportEnable: true,
          openMrsEnable: false
        },
        parentOrgInfo: {},
        activeDocsOrgId: "",
        activeSheetOrgId: "",
        activeTeqDrawOrgId: "",
        openMrsChannelId: "",
        openMrsExternalAppUserId: "",
        phone: "",
        organizationType: "",
        theme: "default",
        requestFrom: "",
        mailNotificationForChat: false,
        workspaceSubType: "",
        roleTypePrivilegesPageAccess: [],
        roleRegistryEnable: false
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
