import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import MyConfig from '../config/MyConfig';
import { getGrpcClient, getUuidv4 } from '../config/Common';
import { useSelector } from 'react-redux';
import { ZiFetchRequest } from '../protobuf/search/ZiFetchService_grpc_web_pb';
import GlobalMsgCons from '../config/GlobalMsgCons';

export default (props) => {
    const auth = useSelector(state => state.authReducer);

    let [options, setOptions] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    let [value, setValue] = useState([]);

    useEffect(() => {
        setValue(props.value ? props.value : props.selectedOption)
    }, [])

    useEffect(() => {
        let { cacheDataType, parentId, parentOrgIdSwitch } = props;
        if (parentId) {
            let postJson = { reqId: getUuidv4(), cacheDataType, searchText: "", orgId: auth.activeCompany, parentId, dataLimit: 5 };
            if (parentOrgIdSwitch) {
                postJson.orgId = parentOrgIdSwitch
            }
            const request = new ZiFetchRequest();
            request.setDomain("search");
            request.setEvent("preSearch");
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.searchGrpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let option = props.buildOptions(responseData.idList);
                    setOptions(option)
                }
            })
        }
    }, [props.parentId, props.parentOrgIdSwitch])


    function getThemeBg() {
        let returnTheme = "#fff";
        return returnTheme;
    }

    function getThemeText() {
        let returnTheme = "#000";

        return returnTheme;
    }

    const customStyles = (hasError) => ({
        control: (styles) => ({
            ...styles,
            ...(hasError && { borderColor: 'red' }),
            background: getThemeBg(),
            color: getThemeText(),
        }),
        menu: (base) => ({
            ...base,
            background: getThemeBg(),
            color: getThemeText()
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#2196F3" : isFocused ? "#8ec5f2" : undefined
            };
        }
    });


    function handleCreate(inputValue) {
        setIsLoading(true);
        props.handleCreate(inputValue)
        setIsLoading(false);
    }


    return <React.Fragment>
        <CreatableSelect
            {...props}
            onCreateOption={handleCreate}
            styles={customStyles(props.error ? props.error : false)}
            value={value}
            onChange={(e) => {
                setValue(e)
                props.onChange(e)
            }}
            options={options}
            isDisabled={props.isDisabled ? true : false}
            isMulti={props.isMulti ? props.isMulti : false}
            placeholder={props.placeholder}
            menuPortalTarget={document.body}
            menuPlacement={"auto"}
            isLoading={isLoading}
            formatCreateLabel={(typedlabel) => {
                return props.createLabel ? `${props.createLabel} "${typedlabel}"` : `Create "${typedlabel}"`
            }}
        />

    </React.Fragment>
}
