// DropdownButton.js
import React, { useState, useRef, useEffect } from 'react';
import './DropdownButton.css'; // Include your custom CSS

const DropdownButton = ({ label, options, onSelect, selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown menu visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle option click
  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  function getLabel() {
     let str = label
    
     if(selected) {
        let selectedOpt = options.filter(option => option.value === selected)
        if(selectedOpt.length > 0) {
            str += " - "+selectedOpt[0].label
        }
     }
     return str
  }
  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button className="custom-dropdown-button" onClick={toggleDropdown}>
        {getLabel()}
      </button>
      {isOpen && (
        <ul className="custom-dropdown-menu">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
